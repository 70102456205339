import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4" }
const _hoisted_2 = { class: "w-full py-4 dark:text-gray-200" }
const _hoisted_3 = { class: "lg:flex lg:space-x-2" }
const _hoisted_4 = { class: "text-2xl" }
const _hoisted_5 = { class: "flex w-full justify-between dark:text-gray-400" }
const _hoisted_6 = { class: "hidden lg:block text-gray-500" }
const _hoisted_7 = { class: "hidden lg:block text-gray-500" }
const _hoisted_8 = { class: "block lg:hidden" }
const _hoisted_9 = { class: "block lg:hidden" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "w-full border-t border-gray-100 dark:border-gray-500 mt-3" }

import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { QuestionListPage } from '@/components';
import SiteData from '../../../data';
import { LinkItem, BoardRepository, Member, BoardContent } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { useReadCheckStore,useMemberStore } from '@/store';
import { AuthService } from '@/services';

interface containerParameter {
    class?:string|null,
    contentChangeEvent?:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionViewPage',
  props: {
    class: {},
    contentChangeEvent: { type: [Function, null] }
  },
  setup(__props: any) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

;

const property = __props;
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const auth = new AuthService();
const readCheck = useReadCheckStore();
const memberStore = useMemberStore();

var contentRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    isEdit:false,
    tag:'' as string,
    content: new BoardContent(),
    item:{} as LinkItem,
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new BoardRepository(config.apis.board, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }

    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        componentInfo.value.content = rst.data;
        if (componentInfo.value.content.ownerId === componentInfo.value.member?.id) {
            componentInfo.value.isEdit = true;
        }
    }

    componentInfo.value.isLoaded = true;
});


const setContent = async (content:BoardContent) => {
    let curpage = (route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1;
    router.push(`/customer/qna/${content.id}?curpage=${curpage}`);
    componentInfo.value.content = content;

    if (property.contentChangeEvent !== null && property.contentChangeEvent !== undefined && typeof property.contentChangeEvent === "function") {
        property.contentChangeEvent(content);
    }

    componentInfo.value.key += 1;
}

const sanitizeHtml = (htmlString:string):string => {
    htmlString = htmlString.replace(/<script[\s\S]*?<\/script>/gi, "");
    htmlString = htmlString.replace(/style\s*=\s*(['"])[\s\S]*?\1/gi, "");
    return htmlString;
}


return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(componentInfo).key}`,
        class: _normalizeClass([_unref(componentInfo).class, "w-full px-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(componentInfo).content.title), 1)
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "my-3 border-t dark:border-gray-800" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, "조회: " + _toDisplayString(_unref(componentInfo).content.readCount), 1),
              _createElementVNode("div", _hoisted_7, "작성일시:" + _toDisplayString(_unref(dayjs).utc(_unref(componentInfo).content.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(componentInfo).content.readCount), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(dayjs).utc(_unref(componentInfo).content.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "my-3 border-t dark:border-gray-800" }, null, -1)),
            _createElementVNode("div", {
              class: "p-0 lg:p-3 text-gray-600 dark:text-gray-200",
              innerHTML: sanitizeHtml(_unref(componentInfo).content.content)
            }, null, 8, _hoisted_10)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_unref(QuestionListPage), {
            class: "",
            "selected-content": setContent,
            "is-edit": _unref(componentInfo).isEdit
          }, null, 8, ["is-edit"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})