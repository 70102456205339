<template>
<div :class="`border border-gray-200 dark:border-slate-600 rounded-lg shadow-sm overflow-hidden ${componentInfo.class}`">
    <button
    @click="toggle"
    class="w-full px-4 py-2 text-left bg-gray-300 dark:bg-slate-600 dark:hover:bg-slate-500 transition flex items-center justify-between"
    >
    <span class="font-medium dark:text-gray-100">{{ componentInfo.title }}</span>
    <svg
        :class="{ 'rotate-180': componentInfo.isOpen }"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-5 h-5 transition-transform dark:text-gray-100"
    >
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
    </button>

    <!-- 본문 영역 -->
    <div
    ref="content"
    :style="{ maxHeight: componentInfo.isOpen ? `${contentHeight}px` : '0px' }"
    class="overflow-hidden transition-all duration-300 ease-in-out px-4 dark:bg-gray-200"
    >
    <div ref="contentBody" class="py-2">
        <slot>{{ componentInfo.body }}</slot>
    </div>
    </div>
</div>
</template>

<script setup lang="ts">
    import { onMounted, ref, nextTick } from "vue";
    import { useGlobalStore } from '@/store';

    interface ContainerParameter {
        class?: string | null | undefined;
        title: string;
        body:string;
    }

    const property = defineProps<ContainerParameter>();
    const site = useGlobalStore();

    const componentInfo = ref({
        key: 0,
        class: "",
        isOpen: false,
        title:'',
        body:''
    });

    const contentHeight = ref(0);
    const contentBody = ref<HTMLElement | null>(null);

    onMounted(() => {
        componentInfo.value.title = property.title;
        componentInfo.value.body = property.body;
        componentInfo.value.class = property.class ?? "";
        updateContentHeight();

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const toggle = async () => {
    componentInfo.value.isOpen = !componentInfo.value.isOpen;

    // 다음 틱에서 콘텐츠 높이를 다시 계산
    await nextTick();
        updateContentHeight();
    };

    const updateContentHeight = () => {
        if (contentBody.value) {
            contentHeight.value = componentInfo.value.isOpen
            ? contentBody.value.scrollHeight
            : 0;
        }
    };
</script>
