import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full lg:w-1/3 flex justify-start text-left" }
const _hoisted_2 = { class: "w-full lg:w-1/3 lg:flex justify-center text-center" }
const _hoisted_3 = { class: "w-full lg:w-1/3 lg:flex justify-end text-right" }

import { onMounted, ref } from 'vue';

interface thirdSideParameter {
    class?:string|null,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GThirdSide',
  props: {
    class: {}
  },
  setup(__props: any) {

const property = __props;

var componentInfo = ref({
   class:'' 
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full flex justify-between", _unref(componentInfo).class])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "left")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "center")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ], 2))
}
}

})