import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from 'vue';
import { GDropdown } from '../atoms';
import { CookieHelper, LinkItem } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSelector',
  setup(__props) {

const { locale } = useI18n();

var componentInfo = ref({
    language: {} as LinkItem,
    dropdownlist: [
    { url: 'ko', type: 'default', title: '한국어', active: true, image: '/locale/ko.png' },
    { url: 'en', type: 'default', title: 'English', active: false, image: '/locale/en.png' }
    ] as LinkItem[]
});

onMounted(() => {
    // 쿠키에서 저장된 언어 가져오기
    const savedLanguage = CookieHelper.getCookie('i18n_redirected');
    const defaultLanguage = 'ko';

    // locale 및 dropdownlist 상태 초기화
    locale.value = savedLanguage && ['ko', 'en'].includes(savedLanguage) ? savedLanguage : defaultLanguage;

    const selected = componentInfo.value.dropdownlist.find((x) => x.url === locale.value);
    componentInfo.value.language = selected ?? {} as LinkItem;

    // 기본 언어를 쿠키에 저장
    if (!savedLanguage) {
        CookieHelper.setCookie('i18n_redirected', defaultLanguage, 365);
    }
});

const fnChangeLanguage = (e: any) => {
    componentInfo.value.language = e.item;
    locale.value = e.item.url;
    CookieHelper.setCookie('i18n_redirected', e.item.url, 365);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(GDropdown), {
    items: _unref(componentInfo).dropdownlist,
    modelValue: _unref(componentInfo).language,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).language) = $event)),
    onChange: fnChangeLanguage,
    size: "xs",
    width: "80px",
    class: "mt-1 mb-1 mr-2 py-1 pl-2 text-gray-600 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md",
    itemClass: "py-1 px-2 text-gray-500 dark:text-gray-400 bg-gray-200 dark:bg-slate-800"
  }, null, 8, ["items", "modelValue"]))
}
}

})