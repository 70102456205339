<template>
<main :key="`content_list_${pageinfo.key}`" v-if="pageinfo.isLoaded" :class="pageinfo.class">
    <GContainer>
    <div class="w-full flex justify-between mt-5 p-2 lg:p-0">
        <LeftSection class="hidden lg:block" />
        <section class="w-full lg:w-[840px]" id="contentTop">
        <WideBanner adid="2064279846" :key="`content_banner_${pageinfo.key}`" ></WideBanner>
        <ContentViewPage class="" :contentChangeEvent="setHead" />
        </section>
        <RightSection class="hidden lg:block" />
    </div>
    </GContainer>
</main>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GContainer,LeftSection,RightSection,ContentViewPage,WideBanner } from '@/components';
import SiteData from '../../../data';
import { Content, ContentRepository, LinkItem, StringHelper } from '@gn2studio/gn2.library.common';
import { HeadSet } from '@/utility';
import { useHead } from '@vueuse/head';
import config from '@/config';
import { useGlobalStore } from '@/store';

interface containerParameter {
    class?:string|null
};

const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const contentRep = new ContentRepository(config.apis.article, '');
const site = useGlobalStore();
const head = new HeadSet();

var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem
});

onMounted(async () => {
    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        setHead(rst.data);
    } else {
        router.push(`/error/404`);
    }

    pageinfo.value.class = property.class ?? '';
    pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

const setHead = (content:Content) => {
    head.title = `${content.title} - MerryTokTok`;
    head.description = content.title;
    head.url = `${location.origin}/content/view/${content.id}`;
    head.image = StringHelper.extractImageUrls(content.body);
    if (head.image === null || head.image === undefined || String(head.image).trim() === '') {
        head.image = `${location.origin}/logo/Logo_New_600x400.png`;
    }
    head.keywords = content.title;
    useHead(head.CreateJson());
    scrollToSection('contentTop');
}

const scrollToSection = (id:string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

</script>
