import { defineStore } from 'pinia';

interface Content {
  id: string;
  data: any; // 콘텐츠의 데이터 구조에 맞게 타입 정의
}

export const useContentStore = defineStore('contentStore', {
  state: () => ({
    cache: new Map<string, Content>(), // 메모리 캐시
  }),
  actions: {
    // 콘텐츠를 저장 (캐시와 localStorage 동시 저장)
    setContent(content: Content) {
      this.cache.set(content.id, content); // 캐시에 저장
      this.saveToLocalStorage(content); // localStorage에 저장
    },

    // 콘텐츠를 캐시나 localStorage에서 가져오기
    getContent(id: string): Content | null {
      // 캐시에서 데이터 확인
      const cachedContent = this.cache.get(id);
      if (cachedContent) {
        return cachedContent;
      }

      // localStorage에서 데이터 확인
      const storedContent = localStorage.getItem(`content_${id}`);
      if (storedContent) {
        const parsedContent: Content = JSON.parse(storedContent);
        this.cache.set(id, parsedContent); // 캐시에 로드
        return parsedContent;
      }

      // 데이터가 없으면 null 반환
      return null;
    },

    // 특정 콘텐츠 제거
    clearContent(id: string) {
      this.cache.delete(id); // 캐시에서 제거
      localStorage.removeItem(`content_${id}`); // localStorage에서 제거
    },

    // localStorage에 콘텐츠 저장
    saveToLocalStorage(content: Content) {
      localStorage.setItem(`content_${content.id}`, JSON.stringify(content));
    },

    // localStorage에서 모든 콘텐츠를 캐시로 로드
    loadFromLocalStorage() {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('content_')) {
          const id = key.replace('content_', '');
          const storedContent = localStorage.getItem(key);
          if (storedContent) {
            const parsedContent: Content = JSON.parse(storedContent);
            this.cache.set(id, parsedContent);
          }
        }
      });
    },

    // 전체 캐시 및 localStorage 초기화
    clearAllContent() {
      this.cache.clear(); // 캐시 초기화
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('content_')) {
          localStorage.removeItem(key); // 관련 localStorage 제거
        }
      });
    },
  },
});
