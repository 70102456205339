import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full border-b border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900 sky" }
const _hoisted_2 = { class: "flex space-x-2 pr-1" }

import { ref } from 'vue';
    import { GContainer,GBetweenSide } from '../atoms';
    import { GTab,DarkMode,LanguageSelector } from '../molecules';
    import { LinkItem } from '@gn2studio/gn2.library.common';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'SkyMenu',
  setup(__props) {

    var componentInfo = ref({
        tabitems:[
            { url:'https://www.gn2studio.com', type:'blank', title:'GN2Studio',active:false },
            { url:'', type:'default', title:'Merrytoktok',active:true }
        ] as LinkItem[]
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(GContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(GBetweenSide), { isAbsolute: true }, {
          left: _withCtx(() => [
            _createVNode(_unref(GTab), {
              items: _unref(componentInfo).tabitems,
              class: "pl-2"
            }, null, 8, ["items"])
          ]),
          right: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(DarkMode)),
              _createVNode(_unref(LanguageSelector))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})