import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3" }
const _hoisted_2 = { class: "hidden lg:block w-1/6" }
const _hoisted_3 = { class: "w-full lg:w-5/6" }
const _hoisted_4 = { class: "w-full mt-4" }

import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { GContainer,CustomerMenu,TitleBox,WideBanner,BugReportPage } from '@/components';
    import SiteData from '../../../data';
    import { LinkItem } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { useI18n } from 'vue-i18n';
        
    interface containerParameter {
        class?:string|null
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'BugReport',
  props: {
    class: {}
  },
  setup(__props: any) {

    ;
    
    const property = __props;
    const route = useRoute();
    const site = useGlobalStore();
    const { t, locale } = useI18n();
    
    var pageinfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        tag:'' as string,
        item:{} as LinkItem
    });
    
    onMounted(async () => {
        pageinfo.value.class = property.class ?? '';
        pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
        pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
        pageinfo.value.isLoaded = true;
    
        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });
    
    const goContent = () => {
  
    }
    
return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        key: `qna_list_${_unref(pageinfo).key}`,
        class: _normalizeClass(_unref(pageinfo).class)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(CustomerMenu), { position: 5 })
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_unref(WideBanner), {
                adid: "2064279846",
                key: `content_banner_${_unref(pageinfo).key}`
              })),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(TitleBox), {
                  title: _unref(t)(`site.Bug`),
                  icon: "fa-solid fa-bell"
                }, null, 8, ["title"])
              ]),
              _createVNode(_unref(BugReportPage), { class: "" })
            ])
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}
}

})