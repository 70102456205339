<template>
<GImage class="imageCircle" :class="property.class" :src="property.src" :width="property.width" :height="property.height" :border="property.border" v-if="componentInfo.isLoaded">
<slot></slot>
</GImage>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { StringHelper } from '@gn2studio/gn2.library.common';
    import { GImage } from '../atoms';
    
    interface imgParameter {
        src:string,
        width:string,
        height:string,
        border:number,
        link?:string|null,
        class?:string|null
    }

    const property = defineProps<imgParameter>();
    const router = useRouter();

    var componentInfo = ref({
        src:"",
        width:"",
        height:"",
        border:0,
        link:"",
        class:"",
        isLoaded:false,
        key:0
    });

    onMounted(() => {
        componentInfo.value.width = property.width;
        componentInfo.value.height = property.height;
        componentInfo.value.src = property.src;
        if (componentInfo.value.src === null || componentInfo.value.src === undefined || componentInfo.value.src.trim() === "") {
            componentInfo.value.src = `https://via.placeholder.com/${StringHelper.extractNumbersFromString(componentInfo.value.width)}x${StringHelper.extractNumbersFromString(componentInfo.value.height)}`
        }

        componentInfo.value.border = property.border;
        componentInfo.value.link = property.link ?? "";
        componentInfo.value.class = property.class ?? "";

        componentInfo.value.isLoaded = true;
    });

</script>

<style scoped>
.imageCircle {
    border-radius: 50%;
}
</style>