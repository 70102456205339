<template>
<div class="w-full" v-if="componentInfo.isLoaded" :key="`Reply_${componentInfo.key}`">
    <h2 class="text-xl font-bold text-gray-700 dark:text-gray-300 my-4">Comments</h2>

    <!-- 댓글 입력 폼 -->
    <form 
    @submit.prevent="addComment" 
    class="flex justify-between space-x-1 mb-6"
    :class="{ 'opacity-50 pointer-events-none': !componentInfo.isLoggedIn }"
    >
    <textarea
        v-model="componentInfo.currentReply"
        :disabled="!componentInfo.isLoggedIn"
        class="replyForm p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        placeholder="Write a comment..."
        rows="3"
        required
    ></textarea>
    <button
        type="submit"
        :disabled="!componentInfo.isLoggedIn"
        class="replyBtn py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
    >
        Reply
    </button>
    </form>

    <!-- 댓글 목록 -->
    <ul class="space-y-4">
    <li
        v-for="comment in sortedComments"
        :key="comment.id"
        class="p-4 border-b border-dashed border-gray-200 border-last-none"
    >
        <div class="flex justify-start space-x-2 pl-2">
            <div>
                <CircleImage :src="comment.member?.photo" width="48px" height="48px" :border="0" :click-event="() => {}" />
            </div>
            <div class="mt-2">
                <p class="text-xs font-semibold">{{ comment.member?.nickName }}</p>
                <p class="text-xs">{{ dayjs.utc(comment.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</p>
            </div>
        </div>
        <div class="flex justify-between space-x-5 items-center mt-3 bg-gray-100 dark:bg-gray-400 p-3 rounded-lg">
            <p class="text-gray-800" v-html="StringHelper.convertNewlinesToBr(comment.comment)"></p>
            <button v-if="componentInfo.member?.id === comment.member?.id"
            @click="deleteComment(comment)"
            class="text-red-800 hover:text-red-600 font-medium"
            >
            <i class="fa-solid fa-trash"></i>
            </button>
        </div>
    </li>
    </ul>
</div>
</template>

<script setup lang="ts">
import { ref,onMounted,computed } from 'vue';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';
import { ContentReply, Member, ContentRepository,ContentReplyDTO, Content, StringHelper } from '@gn2studio/gn2.library.common';
import { MessageBox } from '@/utility';
import config from '@/config';
import { GImage } from '../atoms';
import { CircleImage } from '../molecules';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface containerParameter {
    id:string,
    comments:null|undefined|ContentReply[],
    class?:string|null|undefined
}

const property = defineProps<containerParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();
var contentRep = new ContentRepository(config.apis.article, '');

const sortedComments = computed((): ContentReply[] => {
  return [...componentInfo.value.comments].sort((a, b) => {
    const dateA = new Date(a.condition.registDate);
    const dateB = new Date(b.condition.registDate);
    return dateB.getTime() - dateA.getTime();
  });
});

var componentInfo = ref({
    key:0,
    isLoaded:false,
    itsMy:false,
    member:null as null|Member,
    id:'',
    accessToken:'',
    class:'',
    isLoggedIn:false,
    currentReply:'',
    comments:[] as ContentReply[]
});

onMounted(async () => {
    componentInfo.value.id = property.id;
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.comments = property.comments ?? [];

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }

    componentInfo.value.isLoaded = true;
});

const addComment = async () => {
    if (componentInfo.value.currentReply.trim() === '') {
        MessageBox.Alert("내용을 작성해 주세요.");
        return;
    }

    let rst = await contentRep.ContentReplyRegist({ contentId : componentInfo.value.id, comment: componentInfo.value.currentReply });
    if (rst.check) {
        componentInfo.value.currentReply = '';
        rst.data.member = componentInfo.value.member;
        componentInfo.value.comments.push(rst.data);
        componentInfo.value.key += 1;
    } else {
        MessageBox.Alert(rst.message);
    }
};

const deleteComment = (comment:ContentReply) => {
    if (comment !== null && comment !== undefined) {
        if (componentInfo.value.isLoggedIn && componentInfo.value.member?.id === comment.member.id) {
            MessageBox.Confirm("정말로 삭제하시겠습니까?", async () => {
                let rst = await contentRep.ContentReplyRemove(comment.id);
                if (rst.check) {
                    componentInfo.value.comments = componentInfo.value.comments.filter(reply => reply.id !== comment.id);
                    componentInfo.value.key += 1;
                } else {
                    MessageBox.Alert(rst.message);
                }
            });
        } else {
            MessageBox.Alert("로그인 정보가 일치하지 않습니다.");
        }
    }
};
</script>

<style scoped>
.replyBtn { width:80px; }
.replyForm { width:calc(100% - 80px); }
</style>
