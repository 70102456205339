import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import { ref, toRef, watch } from 'vue';

interface popupParameter {
    class?: string | null;
    mode?: 'default' | 'full' | null | undefined;
    modelValue: boolean; // v-model의 기본 prop 이름
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GPopup',
  props: {
    class: {},
    mode: {},
    modelValue: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const property = __props;
const emit = __emit; // v-model 이벤트 이름

const isShowRef = toRef(property, 'modelValue');

const componentInfo = ref({
    key: 0,
    class: '',
    mode: 'default' as 'default' | 'full',
    isShow: false
});

// 반응형 데이터 초기화
componentInfo.value.class = property.class ?? '';
componentInfo.value.mode = property.mode ?? 'default';
componentInfo.value.isShow = property.modelValue;

// 부모로부터 전달된 값 감지
watch(isShowRef, (newValue) => {
    componentInfo.value.isShow = newValue;
});

// 값 변경 시 부모에게 업데이트 알림
watch(() => componentInfo.value.isShow, (newValue) => {
    emit('update:modelValue', newValue); // 부모 컴포넌트로 데이터 전달
});

// 팝업 닫기
const closePopup = () => {
    componentInfo.value.isShow = false;
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    key: `popup_${componentInfo.value.key}`,
    class: "popup flex justify-center items-center"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([componentInfo.value.class, "relative bg-gray-100 dark:bg-gray-100 p-5 rounded-xl shadow-xl shadow-black border border-gray-500"])
    }, [
      _createElementVNode("button", {
        type: "button",
        class: "absolute popupBtn",
        onClick: closePopup
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: "/images/close-button.png",
          alt: "close"
        }, null, -1)
      ])),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ])), [
    [_vShow, componentInfo.value.isShow]
  ])
}
}

})