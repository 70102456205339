import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fa-solid fa-moon text-white mt-1"
}
const _hoisted_2 = {
  key: 1,
  class: "fa-solid fa-sun mt-1"
}

import { ref, onMounted } from 'vue';

// 다크 모드 상태를 관리하는 변수

export default /*@__PURE__*/_defineComponent({
  __name: 'DarkMode',
  setup(__props) {

const isDarkMode = ref<boolean>(false);

// 다크 모드 상태를 로컬 스토리지와 동기화하는 함수
const updateBodyClass = () => {
if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
} else {
    document.documentElement.classList.remove('dark');
}
};

// 다크 모드 토글 함수
const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value;
    localStorage.setItem('darkMode', String(isDarkMode.value));
    updateBodyClass();
};

// 컴포넌트가 마운트되었을 때 로컬 스토리지에서 상태를 불러옴
onMounted(() => {
    isDarkMode.value = localStorage.getItem('darkMode') === 'true';
    updateBodyClass();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    onClick: toggleDarkMode,
    class: "dark:bg-slate-800 bg-slate-200 h-6 w-6 mt-1 rounded-md"
  }, [
    (isDarkMode.value)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1))
      : (_openBlock(), _createElementBlock("i", _hoisted_2))
  ]))
}
}

})