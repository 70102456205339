<template>
<img :src="StringHelper.extractYoutubeThumbnailsFromHtml(item.body)" class="w-full h-auto object-cover" />
<div class="p-4">
    <h2 class="text-lg font-semibold text-gray-800">{{ item.title }}</h2>
    <p class="text-sm text-gray-600 mt-2">{{ StringHelper.truncateText(StringHelper.extractTextFromHtml(item.body), 50) }}</p>
</div>
</template>

<script setup lang="ts">
import { Content,StringHelper } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    item:Content
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    class:'',
    item:new Content()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.item = property.item;
});
</script>