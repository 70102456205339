import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "placeholder", "value", "id"]

import { ref, onMounted } from 'vue';
  
  // Props 정의 (modelValue를 선택적으로 설정)
  interface containerParameter {
    class?: string | null | undefined,
    type?: string | null | undefined,
    placeholder?: string | null | undefined,
    modelValue?: string, 
    size?: string | null | undefined,
    id?:string | null | undefined
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'GTextbox',
  props: {
    class: {},
    type: {},
    placeholder: {},
    modelValue: {},
    size: {},
    id: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  
  // componentInfo 초기화
  var componentInfo = ref({
    class: '',
    placeholder: '',
    type: '',
    size: '',
    id:''
  });
  
  onMounted(() => {
    componentInfo.value.class = props.class ?? '';
    componentInfo.value.placeholder = props.placeholder ?? '';
    componentInfo.value.type = props.type ?? '';
    componentInfo.value.size = props.size ?? 'md';
    componentInfo.value.id = (props.id !== null && props.id !== undefined) ? props.id : '';

    switch (componentInfo.value.size) {
        case 'xs':
            componentInfo.value.class += ` text-xs py-1 px-2`;
            break;
        case 'sm':
            componentInfo.value.class += ` text-sm py-1 px-2`;
            break;
        case 'md':
            componentInfo.value.class += ` text-md py-2 px-3`;
            break;
        case 'lg':
            componentInfo.value.class += ` text-lg py-2 px-3`;
            break;
        case 'xl':
            componentInfo.value.class += ` text-xl py-3 px-4`;
            break;
        case '2xl':
            componentInfo.value.class += ` text-2xl py-4 px-5`;
            break;
    }
  });
  
  // v-model 값을 업데이트하는 함수
  function updateValue(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input) {
      emit('update:modelValue', input.value); // modelValue가 없는 경우에도 안전
    }
  }
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("textarea", {
    type: _unref(componentInfo).type,
    placeholder: _unref(componentInfo).placeholder,
    class: _normalizeClass([_unref(componentInfo).class, "dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:border-teal-600 outline-none"]),
    value: _ctx.modelValue,
    id: _unref(componentInfo).id,
    onInput: updateValue,
    style: {"resize":"none","overflow-x":"hidden","overflow-y":"scroll"}
  }, null, 42, _hoisted_1))
}
}

})