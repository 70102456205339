<template>
    <div v-if="componentInfo.isLoaded" class="relative bg-cover bg-center h-80" :class="componentInfo.class" :key="componentInfo.key" :style="`background-image: url('${fnReplace(componentInfo.member.background, 'https://via.placeholder.com/1200x400')}');background-size:cover;`">
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      <button type="button" @click="fnBackground" class="border border-gray-100 w-6 h-6 absolute cursor-pointer z-50" style="top:10px;right:10px;" v-if="componentInfo.isLoaded"><i class="fa-solid fa-arrow-up-from-bracket"></i></button>
      <div class="relative flex flex-col items-center pt-12 z-10">
        <img
          :src="fnReplace(componentInfo.member.photo, 'https://via.placeholder.com/120')"
          alt="Profile"
          class="w-24 h-24 rounded-full border-4 border-white shadow-lg"
          :class="(componentInfo.isLoggedIn) ? 'cursor-pointer' : ''"
          @click="fnProfilePhoto"
        />
        <h1 class="text-2xl font-semibold mt-4">{{ componentInfo.member.nickName }}</h1>
        <p class="py-5">{{ componentInfo.member.bio }}</p>
        <slot></slot>
        <input
          id="profile_photo_upload"
          type="file"
          accept="image/*"
          style="display: none"
          @change="fnUploadProfilePhoto"
        />
        <input
          id="background_photo_upload"
          type="file"
          accept="image/*"
          style="display: none"
          @change="fnUploadBackground"
        />
      </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import config from '@/config';
import { MemberRepository,Member,ValidateHelper, FileRepository } from '@gn2studio/gn2.library.common';
import { useMemberStore,useGlobalStore } from '@/store';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';

interface containerParameter {
    class?:string|null,
    member:Member
};

const property = defineProps<containerParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();
const validate = new ValidateHelper();
const site = useGlobalStore();

var memberRep = new MemberRepository(config.apis.member, '');
var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    isLoggedIn:false,
    accessToken:'',
    member: new Member(),
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.member = property.member ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        componentInfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, token);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
    }

    componentInfo.value.isLoaded = true;
});

watch(() => memberStore.member,
  (newValue) => {
    componentInfo.value.member = memberStore.member;
    componentInfo.value.key += 1;
  }
);

const fnReplace = (str:string, replace:string):string => {
    return (validate.IsNullOrWhiteSpace(str)) ? replace : str.trim();
}

const fnProfilePhoto = () => {
  document.getElementById('profile_photo_upload')!.click();
}

const fnBackground = () => {
  document.getElementById('background_photo_upload')!.click();
}

const fnUploadProfilePhoto = async (event: Event) => {
  if (componentInfo.value.isLoggedIn) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      site.setValue('loading', true);
      let rst = await fileRep.FileUpload(file);
      if (rst.check) {
        if (rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
          let tempValue = componentInfo.value.member.photo;
          componentInfo.value.member.photo = rst.data[0].webURL;
          let rtn = await memberRep.UpdateMemberInfo(componentInfo.value.member);
          if (rtn.check) {
            memberStore.setMember(componentInfo.value.member);
          } else {
            componentInfo.value.member.photo = tempValue;
            MessageBox.Alert('File upload failed.');
          }
        } else {
          MessageBox.Alert('Not found upload file url');
        }
      } else {
        MessageBox.Alert(rst.message);
      }

      site.setValue('loading', false);
    }
  }
};

const fnUploadBackground = async (event: Event) => {
  if (componentInfo.value.isLoggedIn) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      site.setValue('loading', true);
      let rst = await fileRep.FileUpload(file);
      if (rst.check) {
        if (rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
          let tempValue = componentInfo.value.member.background;
          componentInfo.value.member.background = rst.data[0].webURL;
          let rtn = await memberRep.UpdateMemberInfo(componentInfo.value.member);
          if (rtn.check) {
            memberStore.setMember(componentInfo.value.member);
          } else {
            componentInfo.value.member.background = tempValue;
            MessageBox.Alert('File upload failed.');
          }
        } else {
          MessageBox.Alert('Not found upload file url');
        }
      } else {
        MessageBox.Alert(rst.message);
      }

      site.setValue('loading', false);
    }
  }
};

</script>