import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between mt-5 p-2 lg:p-0" }
const _hoisted_2 = { class: "w-full lg:w-[840px]" }

import { HomePage } from '@/components';
import { GContainer,LeftSection,RightSection } from '@/components';
import { onMounted, ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const auth = new AuthService();
const site = useGlobalStore();

var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(async () => {
  componentInfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        key: `homepage_${_unref(componentInfo).key}`,
        class: _normalizeClass(_unref(componentInfo).class)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(LeftSection), { class: "hidden lg:block" }),
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_unref(HomePage))
            ]),
            _createVNode(_unref(RightSection), { class: "hidden lg:block" })
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}
}

})