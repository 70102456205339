<template>
    <div class="w-full text-gray-500 dark:text-gray-200 text-sm font-thin" v-if="componentInfo.isLoaded" :key="`contentStatus_${componentInfo.key}`" :class="componentInfo.class">
        <ul class="flex justify-end space-x-4">
            <li><i class="fa-solid fa-eye text-sky-800"></i><span class="ml-1">{{ componentInfo.content.readCount }}</span></li>
            <li><i class="fa-solid fa-share-nodes text-orange-600"></i><span class="ml-1">{{ componentInfo.content.shareCount }}</span></li>
            <li><i class="fa-solid fa-heart text-pink-700"></i><span class="ml-1">{{ componentInfo.content.likeCount }}</span></li>
            <li><i class="fa-solid fa-comments text-green-800"></i><span class="ml-1">{{ componentInfo.content.replyCount }}</span></li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { Content } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    item:Content
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    content:new Content()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.content = property.item;
    componentInfo.value.isLoaded = true;
});
</script>