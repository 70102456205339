import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import config from '@/config';
import axios from 'axios';
import { ReturnValues,CookieHelper } from '@gn2studio/gn2.library.common';

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const settings: any = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: config.identity.baseUrl,
      client_id: config.identity.clientId,
      client_secret: config.identity.clientSecret,
      redirect_uri: `${config.baseUrl}/signin-oidc`,
      response_type: 'code',
      scope: config.identity.scope,
      post_logout_redirect_uri: `${config.baseUrl}/signout-callback-oidc`,
      filterProtocolClaims: true,
      silent_redirect_uri: `${config.baseUrl}/silent-renew-oidc`,
      automaticSilentRenew: true,
      loadUserInfo: true,
    };

    this.userManager = new UserManager(settings);
  }

  public async getUser(): Promise<User | null> {
    const userinfo = await this.userManager.getUser();
    if (userinfo && userinfo.expires_at && this.isTokenExpiring(userinfo.expires_at)) {
      return this.refreshSilent();
    }
    return userinfo;
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public async apiLogin(
    email: string,
    password: string,
    isSave: boolean
  ): Promise<ReturnValues> {
    const result = new ReturnValues();

    try {
      const response = await axios.post(
        `${config.identity.baseUrl}/connect/token`,
        new URLSearchParams({
          grant_type: 'password',
          client_id: config.identity.clientId,
          client_secret: config.identity.clientSecret,
          username: email,
          password: password,
          scope: config.identity.scope,
        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      if (response.status === 200 || response.status === 201) {
        const { access_token, refresh_token, expires_in } = response.data;

        CookieHelper.setCookie('access_token', access_token, isSave ? 90 : undefined);
        CookieHelper.setCookie('refresh_token', refresh_token, isSave ? 90 : undefined);

        result.Success(1);
        result.value = response.data;
      } else {
        result.Error(response.data);
      }
    } catch (err: any) {
      result.Error(err.message);
    }

    return result;
  }

  public async refreshSilent(): Promise<User | null> {
    const refreshToken = CookieHelper.getCookie('refresh_token');
    if (!refreshToken) {
      this.logout();
      return null;
    }

    try {
      const response = await axios.post(
        `${config.identity.baseUrl}/connect/token`,
        new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: config.identity.clientId,
          client_secret: config.identity.clientSecret,
        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      if (response.status === 200) {
        const { access_token, refresh_token, expires_in } = response.data;
        CookieHelper.setCookie('access_token', access_token);
        CookieHelper.setCookie('refresh_token', refresh_token);

        return await this.userManager.getUser();
      }
    } catch (error) {
      console.error('Error refreshing token', error);
      this.logout();
    }

    return null;
  }

  public async logout(): Promise<void> {
    CookieHelper.removeCookie('access_token');
    CookieHelper.removeCookie('refresh_token');
    await this.userManager.removeUser();
    window.location.href = '/';
  }

  private isTokenExpiring(expiresAt: number): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    return expiresAt - currentTime < 60; // Refresh if less than 1 minute remaining
  }

  public getAccessToken(): string {
    return CookieHelper.getCookie('access_token');
  }

  public async apiRegist(email:string, password:string, confirmpassword:string):Promise<ReturnValues> {
    let result = new ReturnValues();

    try {
        const response = await axios.post(`${config.identity.api}/api/Account/Regist`, {
            email: email,
            password: password,
            confirmPassword: confirmpassword
        });
        
        if (response.status === 200 || response.status === 201) {
            result.Success(1);
        } else {
            result.Error(response.data);
        }
    } catch (err:any) {
        result.Error(err.message);
    }

    return result;
}
}
