import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end space-x-4" }
const _hoisted_2 = { class: "ml-1" }
const _hoisted_3 = { class: "ml-1" }
const _hoisted_4 = { class: "ml-1" }
const _hoisted_5 = { class: "ml-1" }

import { Content } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    item:Content
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleStatus',
  props: {
    class: {},
    item: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    content:new Content()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.content = property.item;
    componentInfo.value.isLoaded = true;
});

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full text-gray-500 dark:text-gray-200 text-sm font-thin", _unref(componentInfo).class]),
        key: `contentStatus_${_unref(componentInfo).key}`
      }, [
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", null, [
            _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-solid fa-eye text-sky-800" }, null, -1)),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(componentInfo).content.readCount), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-share-nodes text-orange-600" }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(componentInfo).content.shareCount), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-solid fa-heart text-pink-700" }, null, -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(componentInfo).content.likeCount), 1)
          ]),
          _createElementVNode("li", null, [
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-solid fa-comments text-green-800" }, null, -1)),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(componentInfo).content.replyCount), 1)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})