<template>
    <DefaultButton :class="componentInfo.class" type="default" :size="componentInfo.size" icon="fa-solid fa-right-to-bracket" :clickEvent="fnClick"><span class="hidden lg:block">SIGNIN</span></DefaultButton>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { DefaultButton } from '../molecules';
    import { useRouter } from 'vue-router';

    interface buttonParameter {
        class?:string|null,
        size?:string|null,
        clickEvent?:Function|null|undefined
    };

    const property = defineProps<buttonParameter>();
    const router = useRouter();

    var componentInfo = ref({
        key:0,
        class:'',
        size:'sm',
        isLoaded:false
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.size = property.size ?? 'sm';

        componentInfo.value.isLoaded = true;
    });

    const fnClick = () => {
        if (property.clickEvent !== null && property.clickEvent !== undefined && String(property.clickEvent).trim() !== '') {
            property.clickEvent();
        }
    };
</script>