<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4 h-[40px] overflow-hidden">
        <div class="w-50 float-left">
            <div class="flex space-x-0 justify-start input-group">
                <GInput type="text" placeholder="Search" size="sm" class="p-2 border w-[120px] outline-none" v-model="componentInfo.paging.keyword" @keyup.enter="fnSubmit" />
                <GButton @click="fnSubmit" class="py-1 px-3 ml-1 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400" size="sm"><i class="fa-solid fa-magnifying-glass"></i></GButton>
            </div>
        </div>
        <div class="w-50 float-right">
            <div class="flex justify-between space-x-2 w-full">
                <DefaultButton icon="fa-solid fa-pen-to-square" class="w-[50%]" type="default" size="sm" v-if="componentInfo.isLogin" :click-event="fnWrite">글쓰기</DefaultButton>
                <div v-else>&nbsp;</div>
                <select v-model="componentInfo.tag" @change="fnChangeIssue" class="mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md w-[50%]">
                    <option :value="tag.title" v-for="(tag,index) in componentInfo.categories" :key="`option_${index}`" :selected="(componentInfo.tag === tag.title)" class="py-1 px-2 text-gray-600 dark:text-gray-400 bg-gray-200 dark:bg-slate-800">{{ tag.title }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <ul class="w-full articleList" :key="`content_ul_${componentInfo.key}`">
            <li class="w-full border-b border-dashed px-2 pb-4 border-slate-600 flex justify-between space-x-1" :class="(item.id === componentInfo.currentId) ? 'bg-teal-100 dark:bg-gray-800' : ''" v-for="(item, index) in componentInfo.items" :key="`item_${index}`">
                <div class="w-full lg:w-2/3 pt-4">
                    <ArticleItem :content="item" :callback="fnContentView" :key="`content_item_${item.id}`" />
                </div>
                <div class="w-1/3 hidden lg:block pt-8 pr-4">
                    <ArticleStatus :item="item" />
                </div>
            </li>
        </ul>
    </div>
    <div class="w-full mt-4 pb-8 flex justify-center border-t border-slate-600 pt-4">
        <Pagination :totalCount="componentInfo.totalCount" :curPage="componentInfo.paging.curPage" size="md" :callback="fnPaging" />
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GInput,ArticleItem,Pagination,GButton,ArticleStatus,DefaultButton } from '@/components';
import SiteData from '../../../data';
import { LinkItem, PagingParameter,Content, ContentRepository,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';

interface containerParameter {
    class?:string|null,
    selectedContent?:Function|null|undefined,
    tag?:string|null|undefined
};

const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();
var repository = null as ContentRepository|null;

var componentInfo = ref({
    key:0,
    class:'',
    accessToken:'',
    member:null as Member|null,
    isLogin:false,
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem,
    categories:[] as LinkItem[],
    totalCount:0,
    items:[] as Content[],
    paging: new PagingParameter(),
    currentId:''
});

onMounted(async () => {
    componentInfo.value.paging = { curPage : ((route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1), pageSize : 10, keyword : "" };
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : (property.tag ?? '이슈');
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
    componentInfo.value.categories = SiteData.tags;
    componentInfo.value.currentId = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id).trim() : '';

    componentInfo.value.accessToken = await auth.getAccessToken();
    componentInfo.value.member = memberStore.member;
    if (componentInfo.value.accessToken !== null 
        && componentInfo.value.accessToken !== undefined 
        && String(componentInfo.value.accessToken).trim() !== ''
        && componentInfo.value.member !== null 
        && componentInfo.value.member !== undefined) {
        componentInfo.value.isLogin = true;

        if (componentInfo.value.member.type === 'Admin') {
            componentInfo.value.categories = SiteData.admintag;
        }
    }

    repository = new ContentRepository(config.apis.article, componentInfo.value.accessToken);

    await getTagContent(componentInfo.value.tag);

    componentInfo.value.isLoaded = true;
});

watch(
  () => [route.params.id, route.query.curpage],
  ([newId, newPage]) => {
    componentInfo.value.currentId =
      newId !== null && newId !== undefined ? String(newId).trim() : '';

    componentInfo.value.paging.curPage =
      newPage !== null && newPage !== undefined ? Number(newPage) : 1;
  },
  { immediate: true }
);

const fnChangeIssue = () => {
    router.push(`/content/list/${componentInfo.value.tag}`);
    componentInfo.value.paging.curPage = 1;
    getTagContent(componentInfo.value.tag);
    componentInfo.value.key += 1;
};

const fnContentView = (content:Content) => {
    if (property.selectedContent !== null && property.selectedContent !== undefined && typeof property.selectedContent === "function") {
        property.selectedContent(content);
    }
}

const getTagContent = async (tag:string) => {
    let rst = await repository?.GetTagContents(tag, componentInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        componentInfo.value.totalCount = rst.code;
        componentInfo.value.items = rst.data;
    }
};

const fnPaging = (curpage:number) => {
    router.push(`${location.pathname}?curpage=${curpage}`)
    componentInfo.value.paging.curPage = curpage;
    getTagContent(componentInfo.value.item.title);
    componentInfo.value.key += 1;
};

const fnSubmit = () => {
    getTagContent(componentInfo.value.item.title);
    componentInfo.value.key += 1;
};

const fnWrite = () => {
    router.push(`/content/edit`);
}
</script>

<style scoped>
ul.articleList li:last-child { border-bottom:none; }
</style>