import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ml-2 text-gray-700 dark:text-gray-300 cursor-pointer" }
const _hoisted_2 = ["checked"]

import { ref, onMounted } from 'vue';

    interface containerParameter {
        class?: string | null | undefined,
        placeholder?: string | null | undefined,
        modelValue?: boolean,
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GCheckBox',
  props: {
    class: {},
    placeholder: {},
    modelValue: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

    const props = __props;
    const emit = __emit;

    var componentInfo = ref({
        class: '',
        placeholder: '',
        type: ''
    });

    onMounted(() => {
        componentInfo.value.class = props.class ?? '';
        componentInfo.value.placeholder = props.placeholder ?? '';
    });

    function updateValue(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input) {
            emit('update:modelValue', input.value);
        }
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: _normalizeClass(["text-blue-500 focus:ring-2 focus:ring-blue-500 outline-none cursor-pointer", _unref(componentInfo).class]),
      checked: _ctx.modelValue,
      onChange: updateValue
    }, null, 42, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_unref(componentInfo).placeholder), 1)
  ]))
}
}

})