import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between mt-5 p-2 lg:p-0" }
const _hoisted_2 = { class: "w-full lg:w-[840px]" }

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { GContainer,LeftSection,RightSection,ContentEditPage } from '@/components';
import SiteData from '../../../data';
import { LinkItem } from '@gn2studio/gn2.library.common';
import { useGlobalStore } from '@/store';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;
const route = useRoute();
const site = useGlobalStore();


var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem
});

onMounted(async () => {
    pageinfo.value.class = property.class ?? '';
    pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});


return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(pageinfo).key}`,
        class: _normalizeClass(_unref(pageinfo).class)
      }, [
        _createVNode(_unref(GContainer), null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(LeftSection), { class: "hidden lg:block" }),
              _createElementVNode("section", _hoisted_2, [
                _createVNode(_unref(ContentEditPage), { class: "" })
              ]),
              _createVNode(_unref(RightSection), { class: "hidden lg:block" })
            ])
          ]),
          _: 1
        })
      ], 2))
    : _createCommentVNode("", true)
}
}

})