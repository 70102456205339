import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "topWriters px-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-sm text-gray-500 dark:text-gray-100" }
const _hoisted_4 = { class: "text-gray-500 dark:text-gray-400 text-sm mt-2" }

import { onMounted, ref } from 'vue';
import { BookCover, CircleImage } from '../molecules';
import { useRouter } from 'vue-router';
import { Member, ContentRepository,StringHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';

interface bestBookParameter {
    class?:string|null,
    clickEvent?:Function|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TopWriters',
  props: {
    class: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

const property = __props;
const router = useRouter();
const ContentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    class:"",
    items:[] as Member[],
    isLoaded:false,
    key:0
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    await GetBestContent();

    componentInfo.value.isLoaded = true;
});

const GetBestContent = async () => {
    let rst = await ContentRep.GetBestUsers({ curPage : 1, pageSize : 3, keyword : ''});
    if (rst.check) {
        componentInfo.value.items = rst.data;
    }
};

const MoveUser = (id:string) => {
    router.push(`/profile/${id}`);
}


return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full border-b border-t border-gray-400 pb-2", _unref(componentInfo).class]),
        key: `topWriters_${_unref(componentInfo).key}`
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full bg-slate-600 p-2 mb-2 text-white" }, "Best Uploader", -1)),
        _createElementVNode("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `writer_${index}`,
              class: "mb-2 flex justify-between space-x-2"
            }, [
              _createVNode(_unref(CircleImage), {
                src: item.photo,
                width: "40px",
                height: "40px",
                class: "cursor-pointer",
                border: 1,
                onClick: ($event: any) => (MoveUser(item.id))
              }, null, 8, ["src", "onClick"]),
              _createElementVNode("div", {
                class: "topWriter cursor-pointer",
                onClick: ($event: any) => (MoveUser(item.id))
              }, [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(item.nickName), 1),
                _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "border-t border-gray-600 border-dashed" }, null, -1)),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(StringHelper).truncateText(item.bio, 15)), 1)
              ], 8, _hoisted_2)
            ]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})