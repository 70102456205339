<template>
<GContainer :key="`qna_list_${pageinfo.key}`" v-if="pageinfo.isLoaded" :class="pageinfo.class">
  <div class="w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3">
      <div class="hidden lg:block w-1/6">
          <CustomerMenu :position="4"></CustomerMenu>
      </div>
      <div class="w-full lg:w-5/6">
          <WideBanner adid="2064279846" :key="`content_banner_${pageinfo.key}`"></WideBanner>
          <div class="w-full mt-4">
              <TitleBox :title="t(`common.qna`)" icon="fa-solid fa-bell"></TitleBox>
          </div>
          <QuestionViewPage class="" :contentChangeEvent="setHead" />
      </div>
  </div>
</GContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GContainer,TitleBox,QuestionViewPage,WideBanner,CustomerMenu } from '@/components';
import SiteData from '../../../data';
import { Content, ContentRepository, LinkItem, StringHelper } from '@gn2studio/gn2.library.common';
import { HeadSet } from '@/utility';
import { useHead } from '@vueuse/head';
import config from '@/config';
import { useGlobalStore } from '@/store';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
};

const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const contentRep = new ContentRepository(config.apis.article, '');
const site = useGlobalStore();
const head = new HeadSet();
const { t, locale } = useI18n();

var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem
});

onMounted(async () => {
    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        setHead(rst.data);
    }

    pageinfo.value.class = property.class ?? '';
    pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

const setHead = (content:Content) => {
    head.title = `${content.title} - MerryTokTok`;
    head.description = content.title;
    head.url = `${location.origin}/customer/view/${content.id}`;
    useHead(head.CreateJson());
    scrollToSection('contentTop');
}

const scrollToSection = (id:string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
</script>
