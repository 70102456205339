import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { onMounted, ref,watch } from 'vue';
import GButton from '../atoms/GButton.vue';

interface buttonParameter {
    class?:string|null|undefined,
    icon?:string|null|undefined,
    size?:string|null|undefined,
    clickEvent?:Function|null|undefined,
    type:'default'|'accept'|'warning'|'danger'|'info'|'cancel'
}
export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultButton',
  props: {
    class: {},
    icon: {},
    size: {},
    clickEvent: { type: [Function, null] },
    type: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'',
    icon:'',
    typeClass:'',
    slotClass:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'md';
    componentInfo.value.icon = property.icon ?? '';
    setClass();
    componentInfo.value.isLoaded = true;
});

watch(() => property,
    (newValue) => {
        componentInfo.value.size = (newValue.size !== null && newValue.size !== undefined) ? newValue.size : '';
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.icon = (newValue.icon !== null && newValue.icon !== undefined) ? newValue.icon : '';
        setClass();
        componentInfo.value.key += 1;
    }
);

const setClass = () => {
    switch (property.type) {
        case 'default':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-slate-200 dark:bg-slate-700 border-slate-400 dark:border-slate-600 shadow-md text-gray-600 dark:text-gray-300 hover:bg-slate-900 hover:text-gray-100 dark:hover:bg-slate-900 dark:hover:text-gray-100`;
            break;
        case 'accept':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-green-800 border-green-700 shadow-md text-gray-200 hover:bg-green-500 hover:text-gray-100`;
            break;
        case 'warning':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-amber-700 border-amber-700 shadow-md text-gray-200 hover:bg-amber-500 hover:text-gray-100`;
            break;
        case 'danger':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-red-700 border-red-700 shadow-md text-gray-200 hover:bg-red-500 hover:text-gray-100`;
            break;
        case 'info':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-cyan-700 border-cyan-700 shadow-md text-gray-200 hover:bg-cyan-500 hover:text-gray-100`;
            break;
        case 'cancel':
            componentInfo.value.typeClass += `${componentInfo.value.class} bg-sky-400 border-sky-300 shadow-md text-gray-200 hover:bg-sky-300 hover:text-gray-100`;
            break;
    }

    switch (componentInfo.value.size) {
        case "xs":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[35px] h-[30px] px-2`;
            componentInfo.value.slotClass += `mt-0.5`;
            break;
        case "sm":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[40px] h-[32px] px-2`;
            componentInfo.value.slotClass += `mt-0.5`;
            break;
        case "md":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[45px] h-[34px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "lg":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[50px] h-[36px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "xl":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[55px] h-[38px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
        case "2xl":
            componentInfo.value.typeClass += `${componentInfo.value.typeClass} min-w-[60px] h-[40px] px-3`;
            componentInfo.value.slotClass += ``;
            break;
    }
};

const fnButtonClick = () => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === 'function') {
        property.clickEvent();
    }
};

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(GButton, {
        class: _normalizeClass(["border rounded-md flex items-center justify-center", _unref(componentInfo).typeClass]),
        size: _unref(componentInfo).size,
        onClick: fnButtonClick,
        key: `defaultbutton_${_unref(componentInfo).key}`
      }, {
        default: _withCtx(() => [
          (_unref(componentInfo).icon !== null && _unref(componentInfo).icon !== undefined && String(_unref(componentInfo).icon).trim() !== '')
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass(["mr-1", _unref(componentInfo).icon])
              }, null, 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_unref(componentInfo).slotClass)
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ]),
        _: 3
      }, 8, ["size", "class"]))
    : _createCommentVNode("", true)
}
}

})