import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start text-left" }
const _hoisted_2 = { class: "flex justify-end text-right" }
const _hoisted_3 = { class: "flex justify-start text-left" }
const _hoisted_4 = { class: "lg:flex justify-end text-right" }

import { onMounted, ref, watch } from 'vue';

interface betweenSideParameter {
    class?:string|null,
    isAbsolute?:boolean|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GBetweenSide',
  props: {
    class: {},
    isAbsolute: { type: [Boolean, null] }
  },
  setup(__props: any) {

const property = __props;

var componentInfo = ref({
    key:0,
    class:'',
    isAbsolute:false
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    if (property.isAbsolute !== null && property.isAbsolute !== undefined) {
        componentInfo.value.isAbsolute = property.isAbsolute;
    }
});

watch(() => property,
    (newValue) => {
        componentInfo.value.isAbsolute = (newValue.isAbsolute !== null && newValue.isAbsolute !== undefined) ? newValue.isAbsolute : false;
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.key += 1;
    }
);

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isAbsolute)
    ? (_openBlock(), _createElementBlock("div", {
        key: `abs_${_unref(componentInfo).key}`,
        class: _normalizeClass(["w-full flex justify-between", _unref(componentInfo).class])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "left")
        ]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "right")
        ])
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: `res_${_unref(componentInfo).key}`,
        class: _normalizeClass(["w-full lg:flex lg:justify-between", _unref(componentInfo).class])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "left")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "right")
        ])
      ], 2))
}
}

})