<template>
  <GContainer>
          <div class="w-full">
            <h2 class="text-2xl text-center my-8 dark:text-white font-semibold">개인정보 취급방침</h2>
  <hr class="border-t border-gray-200 dark:border-gray-600 my-4" />
  <div class="text-md p-4 dark:text-gray-200">
메리톡톡(이하 '회사' )은 '정보통신망 이용촉진 및 정보보호 등에 관한 법률'상의 개인정보보호규정 및 '개인정보보호지침'을 준수하여 이용자가 안전하게 서비스를 이용할 수 있도록 언제나 노력하고 있습니다.
<br />
<br />
<br />
<h3>1. 개인정보의 수집</h3>
<br />
'회사' 는 회원 가입 시 서비스 제공을 위해 최소한의 정보만을 수집합니다.
              <br />
이용자가 '회사' 의 서비스를 이용하기 위해서는 회원 가입 시 아이디, 닉네임, 비밀번호, 이름, 이메일을 필수적으로 입력하셔야 합니다. 직업, 취미, 관심 분야와 같은 선택항목은 입력하지 않으셔도 대부분의 서비스를 이용하실 수 있습니다.
<br />
<br />
              <br />
<h3>2. 쿠키에 의한 개인정보 수집</h3>
<br />
              '회사' 는 회원의 정보를 저장하고 수시로 찾아내는 '쿠키'를 운용합니다. 쿠키는 회원의 컴퓨터 브라우저로 전송하는 소량의 정보입니다. <br />
회원이 웹사이트에 로그인하면 '회사' 의 서버는 회원의 브라우저에 있는 쿠키의 내용을 읽고, 회원의 추가정보를 회원의 컴퓨터에서 찾아 서비스를 제공하게 됩니다. 쿠키의 경우 회원의 컴퓨터는 식별하지만 회원 개개인을 식별하지는 않습니다. 또한 회원은 쿠키에 대한 선택권을 가지고 있습니다.
              <br />
웹브라우저에서 [도구] > [인터넷 옵션] > [보안] > [사용자 정의 수준]을 선택함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
              <br />
다만, 모든 쿠키의 저장을 거부하겠다고 선택하면, 쿠키를 통한 '회사' 에서 제공하는 서비스를 이용하실 수 없게 됩니다. 이러한 쿠키를 통해 '회사' 는 회원의 접속 빈도나 방문시간을 분석하고 이용자의 취향과 관심 분야를 파악하여 타겟 마케팅 및 서비스 개편 등의 척도로 활용합니다.
              <br />
              <br />
              <br />
<h3>3. 개인정보 수집에 대한 동의</h3>
              <br />
'회사' 는 회원들의 개인정보 수집을 위해 동의를 받고 있습니다. 회원의 가입 시 이용약관에 개인정보수집에 대해 동의를 구하는 내용을 포함하여, [동의합니다]라는 버튼을 클릭하면 개인정보 수집에 대해서 동의한 것으로 간주합니다.
<br />
<br />
              <br />
<h3>4. 개인정보의 수집 및 이용 목적</h3>
<br />
'회사' 는 회원들의 개인정보를 이용자의 구분, 통계/분석을 통해 회원에게 최상의 서비스를 제공하거나, 이벤트 당첨 시 경품 배송 등의 목적으로 개인정보를 수집 및 이용합니다.
<br />
'회사' 는 회원의 사상, 과거병력, 신념 등과 같이 사생활 침해 및 권리를 현저히 침해할 우려가 있는 개인 정보는 요구하지 아니합니다.
<br />
<br />
              <br />
<h3>5. 개인정보의 제공</h3>
<br />
'회사' 는 회원에게서 제공 받은 개인정보를 제삼자에게 제공하지 않는 것을 원칙으로 합니다.
              <br />
다만, 다음의 경우는 예외로 합니다.
<br />
-회원의 동의가 있는 경우
<br />
-통계작성, 학술연구 또는 시장조사를 위해 필요한 경우로서 특정 개인이 인지할 수 없을 정도로 가공하여 정보를 제공하는 경우
<br />
-금융실명거래 및 비밀보장에 관한 법률, 전자상거래 등에서의 소비자에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신사업법, 지방세법, 한국은행법, 형사소송법, 기타 관계법령에서 특별한 규정이 있는 경우
<br />
<br />
              <br />
<h3>6. 개인정보 취급자의 제한</h3>
<br />
'회사' 는 회원의 개인정보를 보호하기 위해서 개인정보를 취급할 수 있는 자를 최소화 하고 있습니다.
<br />
-개인정보관리 업무를 수행하는 자
<br />
-회원을 상대로 하여 마케팅 업무를 수행하는 자
<br />
<br />
              <br />
<h3>7. 개인정보의 열람 및 정정</h3>
<br />
회원은 언제든지 등록된 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보의 열람 또는 정정을 하고자 할 경우에는 [마이짱->내정보관리] 메뉴를 클릭하여 본인 확인 절차를 거치신 후 직접 열람 또는 정정하시거나, 개인정보관리책임자에게 서면, 전화, 이메일로 연락하시면 지체 없이 조치하겠습니다.
<br />
<br />
<br />
<h3>8. 회원탈퇴(동의철회)</h3>
<br />
①회원은 가입 시 개인정보 수집, 이용, 제공에 대해 동의하신 내용을 언제든지 철회할 수 있습니다. 회원탈퇴(동의철회)는 본인 확인 절차를 거치신 후 직접 탈퇴하시거나, 개인정보관리책임자에게 서면, 전화, 이메일로 연락하시면 개인정보를 파기하는 등 필요한 조치를 하겠습니다.
<br />
②회원 탈퇴가 이루어진 경우 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.
              <br />
              <br />
              <br />
<h3>9. 개인정보의 보유기간 및 이용기간</h3>
<br />
'회사' 는 원칙적으로 개인정보 수집 및 목적이 달성된 후에는 개인정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 '회사' 는 관계법령이 정한 일정한 기간 회원정보를 보관합니다.
<br />
<br />
              <br />
- 상거래 관련 보존 개인정보
<br />
①보존항목: 상거래이력
<br />
②보존근거: 상법, 전자상거래 등에서의 소비자보호에 관한 법률
<br />
③보존기간: 계약 또는 청약철회 등에 관한 기록 : 5년
<br />
대금결제 및 재화 등의 공급에 관한 기록 : 5년
<br />
소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
<br />
-불건전한 서비스 이용으로 서비스에 물의를 일으켜 이용정치 처리된 회원 및 사법기관 수사의뢰 및 다른 회원을 보호할 목적으로 한시적으로 1년간 해당 개인정보를 보유할 수 있습니다.
<br />
-개인정보 파기 : 회원님께서 제공해주신 개인정보를 파기 시에는 전자적 파일 형태로 저장된 정보는 재생할 수 없는 기술적 방법에 의하여 삭제합니다.
<br />
<br />
              <br />
<h3>10. 개인정보 보호를 위한 기술적 보호 조치</h3>
<br />
'회사' 는 회원의 개인정보를 취급하는 경우 개인정보가 분실, 도난, 누출, 변조, 훼손되지 아니하도록 안정성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
<br />
-허용되지 않은 IP에 대해 접근 권한을 두어 내부 네트웍이 아닌 경우 개인정보가 담겨 있는 서버로 접근이 불가능하게 되어 있습니다.
<br />
-로그인 시 암호알고리즘을 이용 네트워크상의 개인정보를 안전하게 전송할 수 있는 보안장치를 채택하고 있습니다. 보안접속 여부는 회원이 홈페이지상에서 보안/일반접속을 선택해서 사용할 수 있도록 되어 있습니다.
<br />
<br />
              <br />
<h3>11. 개인정보 보호를 위한 관리적 조치</h3>
<br />
회원의 개인정보 보호를 위해 다음과 같은 관리적 조치를 취하고 있습니다.
<br />
-개인정보관리책임자와 최소한의 인원으로 구성된 부서를 마련하여 개인정보에 대한 접근 권한을 제한하고 있습니다.
<br />
-개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 위탁교육을 실시하고 있습니다.
<br />
-개인정보 관련 취급자의 업무인수인계는 보안이 유지된 상태에서 철저히 이뤄지고 있으며, 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확하게 하고 있습니다.
<br />
'회사' 는 내부 관리자의 과실이나 기술 관리상의 사고로 인해 개인정보가 유출, 변조, 훼손된 경우에는 즉각 회원에게 그 사실을 알리고 적절한 대책을 강구할 것입니다. 그러나 회원의 실수로 개인정보가 유출되는 경우 '회사' 는 책임을 지지 아니하므로 아이디나 비밀번호가 타인에게 알려지지 않도록 관리하여야 합니다.
<br />
<br />
<br />
<h3>12. 개인정보관리책임자</h3>
              <br />
회원의 개인정보를 보호하고 개인정보와 관련하여 회원의 불만을 처리하기 위하여 '회사' 는 개인정보관리책임자를 두고 있습니다. 개인정보와 관련하여 문의 사항이 있으시면 아래의 연락처로 연락 주시면 신속하게 처리해 드리겠습니다.
<br />
<br />
<br />
<br />
<br />
개인정보관리책임자
<br />
※성 명: 박희성
<br />
※직 책: 대표
<br />
※이메일: webmaster@merrytoktok.com
<br />
<br />
              <br />
<h3>13. 만 14세 미만 아동의 보호</h3>
<br />
'회사' 는 만 14세 미만 아동의 경우 원칙적으로 회원가입을 받지 않습니다.
<br />
              <br />
              <br />
<h3>14. 개인정보관련 신고 및 분쟁 조정</h3>
<br />
'회사' 에서 개인정보와 관련된 불만이 있으신 분은 고객센터나 개인정보 관리책임자에게 의견을 주시면 접수 즉시 조치하여 처리 결과를 통보해 주겠습니다.
<br />
※'회사'  고객센터: http://www.merrytoktok.com/Customer
              <br />
※개인정보분쟁조정위원회: 1336 (http://www.kopico.or.kr/)
              <br />
<br />
<br />
<h3>15. 정책변경에 따른 공지의무</h3>
<br />
법령 정책 또는 보안기술에 변경에 따라 개인보호정책이 변경되는 경우 변경된 정책을 시행하기 7일 전에 '회사'  홈페이지에 최소 7일 전에 그 이유 및 내용을 공지하겠습니다.
              <br />
<br />
<br />
<h3>16.개인정보 수집 및 이용 목적</h3>
<br />
- 이메일, 비밀번호 : 고지사항의 전달, 본인의 의사확인, 정정처리 등의 의사소통 경로, 본인 확인, 비밀번호 전송, 신규 서비스 및 이벤트 당첨 경품발송 시 정확한 배송지의 확인 등을 위하여 사용합니다.
              <br />또한 서비스 이용에 따른 민원사항 처리를 위한 본인 식별, 중복가입확인, 고객센터의 운영, 불건전한 서비스 이용을 방지하기 위하여 사용됩니다.
<br />
- 이용자의 IP주소, 방문 일시 : 불량회원의 부정 이용방지와 사법기관의 수사의뢰 및 타 회원의 보호 목적으로 사용됩니다.
<br />
- 그 외 선택 항목 : 개인 맞춤 서비스를 제공하기 위한 자료로 사용됩니다.
<br />
<br />
<br />
<br />
<br />
부칙
<br />
(시행일)
<br />
1. 본 개인정보취급방침 변경공고일자 2022년 11월 30일
<br />
2. 본 개인정보취급방침 시행일자 2022년 11월 30일부터 시행합니다.
  </div>
          </div>
  </GContainer>
</template>

<script setup lang="ts">
  import GContainer from '@/components/atoms/GContainer.vue';
  import { useGlobalStore } from '@/store';
  import { onMounted } from 'vue';

  const site = useGlobalStore();

  onMounted(() => {
      setTimeout(() => {
          site.setValue('loading', false);
      }, 100);
  });
</script>