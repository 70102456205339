import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrap-gray"
}

import { ref, watch, onMounted } from 'vue';
import { useGlobalStore } from '@/store';

// 글로벌 스토어 인스턴스 가져오기

export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  setup(__props) {

const globalStore = useGlobalStore();

// 로딩 상태 관리
const isLoading = ref(true); // 기본값을 true로 설정

// 초기 로드 시 loading 키 확인 및 기본값 설정
onMounted(() => {
  const initialLoading = globalStore.getValue('loading');
  if (initialLoading === null || initialLoading === undefined) {
    globalStore.setValue('loading', true); // 기본값 설정
  } else {
    isLoading.value = initialLoading;
  }
});

// loading 키 값이 변경될 때 반응하도록 watch 설정
watch(
  () => globalStore.getValue('loading'),
  (newValue) => {
    isLoading.value = newValue ?? true; // 기본값을 true로 유지
  }
);

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "loading" }, [
          _createElementVNode("img", {
            src: "/images/loading.gif",
            alt: "loading"
          })
        ], -1)
      ])))
    : _createCommentVNode("", true)
}
}

})