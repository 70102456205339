<template>
    <button @click="toggleDarkMode" class="dark:bg-slate-800 bg-slate-200 h-6 w-6 mt-1 rounded-md">
        <i class="fa-solid fa-moon text-white mt-1" v-if="isDarkMode"></i>
        <i class="fa-solid fa-sun mt-1" v-else></i>
    </button>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

// 다크 모드 상태를 관리하는 변수
const isDarkMode = ref<boolean>(false);

// 다크 모드 상태를 로컬 스토리지와 동기화하는 함수
const updateBodyClass = () => {
if (isDarkMode.value) {
    document.documentElement.classList.add('dark');
} else {
    document.documentElement.classList.remove('dark');
}
};

// 다크 모드 토글 함수
const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value;
    localStorage.setItem('darkMode', String(isDarkMode.value));
    updateBodyClass();
};

// 컴포넌트가 마운트되었을 때 로컬 스토리지에서 상태를 불러옴
onMounted(() => {
    isDarkMode.value = localStorage.getItem('darkMode') === 'true';
    updateBodyClass();
});
</script>
