import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from 'vue';
    import { DefaultButton } from '../molecules';
    import { useRouter } from 'vue-router';

    interface buttonParameter {
        class?:string|null,
        size?:string|null,
        clickEvent?:Function|null|undefined
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'LoginButton',
  props: {
    class: {},
    size: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

    ;

    const property = __props;
    const router = useRouter();

    var componentInfo = ref({
        key:0,
        class:'',
        size:'sm',
        isLoaded:false
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.size = property.size ?? 'sm';

        componentInfo.value.isLoaded = true;
    });

    const fnClick = () => {
        if (property.clickEvent !== null && property.clickEvent !== undefined && String(property.clickEvent).trim() !== '') {
            property.clickEvent();
        }
    };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DefaultButton), {
    class: _normalizeClass(_unref(componentInfo).class),
    type: "default",
    size: _unref(componentInfo).size,
    icon: "fa-solid fa-right-to-bracket",
    clickEvent: fnClick
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("span", { class: "hidden lg:block" }, "SIGNIN", -1)
    ])),
    _: 1
  }, 8, ["class", "size"]))
}
}

})