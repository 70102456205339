<template>
<DefaultButton :class="componentInfo.class" type="default" :size="componentInfo.size"  icon="fa-solid fa-right-from-bracket" :clickEvent="fnClick"><span class="hidden lg:block">SIGN OUT</span></DefaultButton>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { DefaultButton } from '../molecules';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    clickEvent?:Function|null|undefined
};

const property = defineProps<containerParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();

var componentInfo = ref({
    class:'',
    size:'sm'
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
});

const fnClick = () => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === 'function') {
        property.clickEvent();
    }
};
</script>