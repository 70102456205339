import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { HomeView } from '../views';
import { AuthCheck,AuthClear,AuthRefresh } from '../views';
import { ListView,ContentView,EditView } from '../views';
import { NoticeList,CustomerHome,NoticeView,FAQ,QuestList,QuestView,QuestEdit,BugReport,PartnerShip } from '../views';
import { AlbumList } from '../views';
import { Privacy,UserTerm } from '../views';
import { ProfileView,ProfileEditView } from '../views';
import { Login,Regist,Error } from '../views';

const routes: Array<RouteRecordRaw> = [
  { path: '/signin-oidc',name: 'signin',component: AuthCheck },
  { path: '/signout-oidc',name: 'signout1',component: AuthClear },
  { path: '/signout-callback-oidc',name: 'signout2',component: AuthClear },
  { path: '/silent-renew-oidc',name: 'signrefresh',component: AuthRefresh },
  { path: '/', name: 'home',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/', component:HomeView },
  ] },
  { path: '/content', name: 'contents',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/content/list', component:ListView },
    { path:'/content/list/:tag', component:ListView },
    { path:'/content/view/:id', component:ContentView },
    { path:'/view/:id', component:ContentView },
    { path:'/content/edit', component:EditView },
    { path:'/content/edit/:id', component:EditView },
  ] },
  { path: '/customer', name: 'customer',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/customer', component:CustomerHome },
    { path:'/customer/bug', component:BugReport },
    { path:'/customer/partner', component:PartnerShip },
    { path:'/customer/notice', component:NoticeList },
    { path:'/customer/faq', component:FAQ },
    { path:'/customer/notice/:id', component:NoticeView },
    { path:'/customer/qna', component:QuestList },
    { path:'/customer/qna/:id', component:QuestView },
    { path:'/customer/qna/edit', component:QuestEdit },
    { path:'/customer/qna/edit/:id', component:QuestEdit },
    { path:'/customer/edit', component:EditView },
    { path:'/customer/edit/:id', component:EditView },
  ] },
  { path: '/album', name: 'album',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/album/list', component:AlbumList },
  ] },
  { path: '/profile', name: 'profile',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/profile', component:ProfileView },
    { path:'/profile/edit', component:ProfileEditView },
    { path:'/profile/:id', component:ProfileView },
  ] },
  { path: '/login', name: 'member',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/login', component:Login },
    { path:'/regist', component:Regist },
  ] },
  { path: '/error', name: 'error',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/error/:code', component:Error },
  ] },
  { path: '/document', name: 'document',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/document/term', component:UserTerm },
    { path:'/document/privacy', component:Privacy },
  ] },
]

const router = createRouter({
  history: createWebHistory(""),
  routes
})

export default router
