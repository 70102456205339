import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "text-lg font-semibold text-gray-800" }
const _hoisted_4 = { class: "text-sm text-gray-600 mt-2" }

import { Content,StringHelper } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    item:Content
}
export default /*@__PURE__*/_defineComponent({
  __name: 'CardImage',
  props: {
    class: {},
    item: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    class:'',
    item:new Content()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.item = property.item;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      src: _unref(StringHelper).extractYoutubeThumbnailsFromHtml(_ctx.item.body),
      class: "w-full h-auto object-cover"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.item.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(StringHelper).truncateText(_unref(StringHelper).extractTextFromHtml(_ctx.item.body), 50)), 1)
    ])
  ], 64))
}
}

})