export class HeadSet {
    public title:string;
    public keywords:string;
    public url:string;
    public image:string;
    public description:string;
    public author:string;

    constructor() {
        this.title = '즐거운대화 - 메리톡톡';
        this.keywords = '메리톡톡,MerryTokTok,인플루언서,셀럽,Celeb,뉴스,News,리뷰,Review,웹소설,영화,유머,엽기,장난,유튜브,동영상,Youtube,Picture,Photo,Gallery';
        this.url = "https://www.merrytoktok.com";
        this.image = "/logo/Logo_New_600x400.png";
        this.description = '재미난 이야기, 웹소설 리뷰, 영화 리뷰, 인플루언서, 셀럽, 뉴스 등 다양한 이야기를 공유합니다.';
        this.author = "GN2Studio";
    }

    CreateJson() {
        return {
            title: this.title,
            meta: [
                { name: 'keywords', content: this.keywords },
                { name: 'description', content: this.description },
                { name: 'author', content: this.url },
                { property: 'og:type', content: 'website' },
                { property: 'og:title', content: this.title },
                { property: 'og:description', content: this.description },
                { property: 'og:image', content: this.image },
                { property: 'og:url', content: this.url },
                { name: 'twitter:card', content: 'summary' },
                { name: 'twitter:title', content: this.title },
                { name: 'twitter:description', content: this.description },
                { name: 'twitter:image', content: this.image },
                { name: 'twitter:domain', content: this.url },
            ]
        }
    }
}