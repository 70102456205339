<template>
<div class="w-full flex justify-between" :class="componentInfo.class">
    <div class="w-full lg:w-1/3 flex justify-start text-left">
        <slot name="left"></slot>
    </div>
    <div class="w-full lg:w-1/3 lg:flex justify-center text-center">
        <slot name="center"></slot>
    </div>
    <div class="w-full lg:w-1/3 lg:flex justify-end text-right">
        <slot name="right"></slot>
    </div>
</div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface thirdSideParameter {
    class?:string|null,
}

const property = defineProps<thirdSideParameter>();

var componentInfo = ref({
   class:'' 
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});
</script>