<template>
<DefaultButton :class="componentInfo.class" type="default" :size="componentInfo.size" :clickEvent="fnClick">
    <div class="flex justify-start space-x-1">
        <CircleImage :src="componentInfo.member?.photo" width="20px" height="20px" :border="1" :click-event="fnClick" />
        <span class="hidden lg:block font-normal mt-0.5">{{ componentInfo.member?.nickName }}</span>
    </div>
</DefaultButton>
</template>

<script setup lang="ts">
import { Member } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { CircleImage,DefaultButton } from '../molecules';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    member?:Member|null|undefined
    clickEvent?:Function|null|undefined
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    class:'',
    size:'sm',
    member:new Member()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
    componentInfo.value.member = property.member ?? new Member();
});

const fnClick = () => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === 'function') {
        property.clickEvent();
    }
};
</script>