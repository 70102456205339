<template>
    <table class="w-full gtable" :class="componentInfo.class" :key="`table_${componentInfo.key}`">
        <thead>
            <tr class="text-gray-500 dark:text-gray-200 border-b border-gray-200 dark:border-gray-600">
                <slot name="columns"></slot>
            </tr>
        </thead>
        <tbody :key="`customer_table_${componentInfo.key}`">
            <tr v-for="(item, index) in componentInfo.items" :key="`item_${index}`" @click="fnLink(item)" class="text-gray-500 dark:text-gray-200 border-b border-gray-200 dark:border-gray-600 hover:bg-green-300 hover:text-gray-700 dark:hover:bg-slate-800">
                <slot name="items" :item="item" :index="index"></slot>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { BoardContent } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface tableParameter {
    items:BoardContent[],
    class?:string|null|undefined,
    clickEvent?:Function|null|undefined
};

const property = defineProps<tableParameter>();

var componentInfo = ref({
    key:0,
    class:'',
    items:[] as BoardContent[]
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.items = property.items ?? [];
});

const fnLink = (item:BoardContent) => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === "function") {
        property.clickEvent(item);
    }
};
</script>
