import { defineStore } from 'pinia';
import { Member } from '@gn2studio/gn2.library.common';

export const useMemberStore = defineStore('memberStore', {
  state: () => ({
    member: new Member(),
  }),
  actions: {
    setMember(data: Member) {
      this.member = data;
      this.saveToLocalStorage();
    },
    clearMember() {
      this.member = new Member();
      localStorage.removeItem('member');
    },
    saveToLocalStorage() {
      localStorage.setItem('member', JSON.stringify(this.member));
    },
    loadFromLocalStorage() {
      const storedMember = localStorage.getItem('member');
      if (storedMember) {
        this.member = Object.assign(new Member(), JSON.parse(storedMember));
      }
    },
  },
});
