import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "space-y-4" }
const _hoisted_4 = { class: "flex justify-start space-x-2 pl-2" }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { class: "text-xs font-semibold" }
const _hoisted_7 = { class: "text-xs" }
const _hoisted_8 = { class: "flex justify-between space-x-5 items-center mt-3 bg-gray-100 dark:bg-gray-400 p-3 rounded-lg" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["onClick"]

import { ref,onMounted,computed } from 'vue';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';
import { ContentReply, Member, ContentRepository,ContentReplyDTO, Content, StringHelper } from '@gn2studio/gn2.library.common';
import { MessageBox } from '@/utility';
import config from '@/config';
import { GImage } from '../atoms';
import { CircleImage } from '../molecules';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

interface containerParameter {
    id:string,
    comments:null|undefined|ContentReply[],
    class?:string|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Reply',
  props: {
    id: {},
    comments: {},
    class: {}
  },
  setup(__props: any) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();
var contentRep = new ContentRepository(config.apis.article, '');

const sortedComments = computed((): ContentReply[] => {
  return [...componentInfo.value.comments].sort((a, b) => {
    const dateA = new Date(a.condition.registDate);
    const dateB = new Date(b.condition.registDate);
    return dateB.getTime() - dateA.getTime();
  });
});

var componentInfo = ref({
    key:0,
    isLoaded:false,
    itsMy:false,
    member:null as null|Member,
    id:'',
    accessToken:'',
    class:'',
    isLoggedIn:false,
    currentReply:'',
    comments:[] as ContentReply[]
});

onMounted(async () => {
    componentInfo.value.id = property.id;
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.comments = property.comments ?? [];

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        componentInfo.value.isLoggedIn = true;
    }

    componentInfo.value.isLoaded = true;
});

const addComment = async () => {
    if (componentInfo.value.currentReply.trim() === '') {
        MessageBox.Alert("내용을 작성해 주세요.");
        return;
    }

    let rst = await contentRep.ContentReplyRegist({ contentId : componentInfo.value.id, comment: componentInfo.value.currentReply });
    if (rst.check) {
        componentInfo.value.currentReply = '';
        rst.data.member = componentInfo.value.member;
        componentInfo.value.comments.push(rst.data);
        componentInfo.value.key += 1;
    } else {
        MessageBox.Alert(rst.message);
    }
};

const deleteComment = (comment:ContentReply) => {
    if (comment !== null && comment !== undefined) {
        if (componentInfo.value.isLoggedIn && componentInfo.value.member?.id === comment.member.id) {
            MessageBox.Confirm("정말로 삭제하시겠습니까?", async () => {
                let rst = await contentRep.ContentReplyRemove(comment.id);
                if (rst.check) {
                    componentInfo.value.comments = componentInfo.value.comments.filter(reply => reply.id !== comment.id);
                    componentInfo.value.key += 1;
                } else {
                    MessageBox.Alert(rst.message);
                }
            });
        } else {
            MessageBox.Alert("로그인 정보가 일치하지 않습니다.");
        }
    }
};

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: "w-full",
        key: `Reply_${_unref(componentInfo).key}`
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl font-bold text-gray-700 dark:text-gray-300 my-4" }, "Comments", -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(addComment, ["prevent"]),
          class: _normalizeClass(["flex justify-between space-x-1 mb-6", { 'opacity-50 pointer-events-none': !_unref(componentInfo).isLoggedIn }])
        }, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).currentReply) = $event)),
            disabled: !_unref(componentInfo).isLoggedIn,
            class: "replyForm p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed",
            placeholder: "Write a comment...",
            rows: "3",
            required: ""
          }, null, 8, _hoisted_1), [
            [_vModelText, _unref(componentInfo).currentReply]
          ]),
          _createElementVNode("button", {
            type: "submit",
            disabled: !_unref(componentInfo).isLoggedIn,
            class: "replyBtn py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          }, " Reply ", 8, _hoisted_2)
        ], 34),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedComments.value, (comment) => {
            return (_openBlock(), _createElementBlock("li", {
              key: comment.id,
              class: "p-4 border-b border-dashed border-gray-200 border-last-none"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(CircleImage), {
                    src: comment.member?.photo,
                    width: "48px",
                    height: "48px",
                    border: 0,
                    "click-event": () => {}
                  }, null, 8, ["src"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(comment.member?.nickName), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(dayjs).utc(comment.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", {
                  class: "text-gray-800",
                  innerHTML: _unref(StringHelper).convertNewlinesToBr(comment.comment)
                }, null, 8, _hoisted_9),
                (_unref(componentInfo).member?.id === comment.member?.id)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: ($event: any) => (deleteComment(comment)),
                      class: "text-red-800 hover:text-red-600 font-medium"
                    }, _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "fa-solid fa-trash" }, null, -1)
                    ]), 8, _hoisted_10))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})