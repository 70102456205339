<template>
    <div class="w-full bannerLayer rounded-lg bg-slate-100 dark:bg-slate-500" :class="componentInfo.class" :key="componentInfo.key">
      <div class="banner-box-home-top" v-if="config.mode !== 'development'">
        <ins :class="`adsbygoogle ${property.adid}`"
            style="display:block"
            data-ad-client="ca-pub-9127406210246186"
            :data-ad-slot="property.adid"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
      </div>
      <div class="banner-box-home-top" v-else></div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import config from '@/config';

interface BannerParameter {
  adid:string,
  class?:string|null,
}

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

const property = defineProps<BannerParameter>();

var componentInfo = ref({
    key:0,
    class:'' 
});

const initAds = () => {
  if (config.mode !== "development") {
    (window.adsbygoogle = window.adsbygoogle || []).push({ params: { google_ad_slot: `${property.adid}` }});
  }
};

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    try {
      initAds();
    } catch (e:any) {
      console.log(e.message);
    }
});
</script>

<style scoped>
.bannerLayer { position:relative;width:100%;max-height: 120px;height:120px;overflow:hidden; }
.bannerLayer .banner-box-home-top { width:100%;height:120px;position:relative;max-width:100vw;overflow:hidden; }
</style>
