import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-4xl mx-auto mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg" }

import { Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper } from '@gn2studio/gn2.library.common';
    import { onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useMemberStore,useGlobalStore } from '@/store';
    import { AuthService } from '@/services';
    import { DefaultButton,PinterView,ProfileSection } from '@/components';
    import config from '@/config';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);

    const route = useRoute();
    const router = useRouter();
    const auth = new AuthService();
    const memberStore = useMemberStore();
    const site = useGlobalStore();
    const validate = new ValidateHelper();

    var memberRep = new MemberRepository(config.apis.member, '');
    var contentRep = new ContentRepository(config.apis.content, '');
    var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
    var pageinfo = ref({
        key:0,
        isLoggedIn:false,
        isLoaded:false,
        accessToken:'',
        id:'',
        member: new Member(),
        user: new Member(),
        contents:[] as Content[],
        files:[] as Resource[]
    });


    onMounted(async () => {
        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            pageinfo.value.accessToken = token;
            pageinfo.value.user = memberStore.member;
            pageinfo.value.isLoggedIn = true;
            memberRep = new MemberRepository(config.apis.member, token);
            contentRep = new ContentRepository(config.apis.article, token);
            fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        }

        if (route.params?.id !== null && route.params?.id !== undefined && String(route.params.id).trim() !== '') {
            pageinfo.value.id = String(route.params.id).trim();
            let rst = await memberRep.GetUserInfo(pageinfo.value.id);
            if (rst.check) {
                pageinfo.value.member = rst.data;
            } else {
                router.push(`/error/404`);
            }
        } else {
            if (pageinfo.value.isLoggedIn) {
                pageinfo.value.member = pageinfo.value.user;
            } else {
                router.push(`/error/404`);
            }
        }

        await getContent();

        setTimeout(() => {
          pageinfo.value.isLoaded = true;
          site.setValue('loading', false);
        }, 100);
    });

    const getContent = async () => {
      let rst = await contentRep.GetUserContents(pageinfo.value.member.id, { curPage : 1, pageSize : 30, keyword : '' });
      if (rst.check) {
        pageinfo.value.contents = rst.data;
      }
    };

    const editProfile = () => {
        router.push(`/profile/edit`);
    }

    const fnMove = (item:Content) => {
        router.push(`/content/view/${item.id}`);
    }

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: "min-h-screen h-full text-gray-800 dark:text-gray-200",
        key: _unref(pageinfo).key
      }, [
        _createVNode(_unref(ProfileSection), {
          member: _unref(pageinfo).member
        }, {
          default: _withCtx(() => [
            (_unref(pageinfo).isLoggedIn && _unref(pageinfo).member.id === _unref(pageinfo).user.id)
              ? (_openBlock(), _createBlock(_unref(DefaultButton), {
                  key: 0,
                  onClick: editProfile,
                  class: "mt-2 px-4 py-1",
                  size: "lg",
                  type: "default"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Edit Profile ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["member"]),
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl font-semibold mb-4" }, "Recent Posts", -1)),
          _createVNode(_unref(PinterView), {
            modelValue: _unref(pageinfo).contents,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pageinfo).contents) = $event)),
            clickitem: fnMove
          }, null, 8, ["modelValue"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})