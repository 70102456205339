import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "w-8 pt-1 bg-green-300 text-gray-600 dark:text-gray-300 font-semibold text-center border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative cursor-pointer dark:bg-slate-800 hover:bg-green-500 hover:text-white" }

import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface PagingParameter {
    totalCount:number,
    curPage:number,
    class?:string|null|undefined,
    callback?:Function|null|undefined,
    size?:string|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Pagination',
  props: {
    totalCount: {},
    curPage: {},
    class: {},
    callback: { type: [Function, null] },
    size: {}
  },
  setup(__props: any) {

const route = useRoute();
const property = __props;
const currentPath = ref(route.query?.curpage ?? 1);

var componentInfo = ref({
    key:0,
    size:'md',
    class:'',
    ulClass:'',
    itemClass:'',
    TotalCount:0,
    PageSize:10,
    CurPage:1,
    EndPage:1,
    StartPage:1,
    FirstPage:1,
    LastPage:1,
    Callback:null as Function|null|undefined,
    items: [] as { page: number }[],
});

onMounted(() => {
    componentInfo.value.CurPage = (route.query?.curpage !== null && route.query?.curpage !== undefined) ? Number(route.query.curpage) : property.curPage;
    fnInit();
    fnSet();
});

watch([currentPath],
    ([newPath], [oldPath]) => {
      if (newPath !== oldPath) {
        fnInit();
        fnSet();
        componentInfo.value.key += 1;
      }
    }
  );

const fnInit = () => {
    componentInfo.value.TotalCount = property.totalCount;
    componentInfo.value.CurPage = property.curPage;
    componentInfo.value.Callback = property.callback;

    if (property.class !== null && property.class !== undefined && String(property.class).trim() !== '') {
        componentInfo.value.class = property.class;
    }

    if (property.size !== null && property.size !== undefined && String(property.size).trim() !== '') {
        componentInfo.value.size = property.size;
    }

    switch (componentInfo.value.size) {
        case 'xs':
            componentInfo.value.class += ` text-xs`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-5 h-5 pt-0.5`;
            break;
        case 'sm':
            componentInfo.value.class += ` text-sm`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-6 h-6 pt-0.5`;
            break;
        case 'md':
            componentInfo.value.class += ` text-md`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-7 h-7 pt-1`;
            break;
        case 'lg':
            componentInfo.value.class += ` text-lg`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-8 h-8 pt-1`;
            break;
        case 'xl':
            componentInfo.value.class += ` text-xl`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-9 h-9 pt-1`;
            break;
        case '2xl':
            componentInfo.value.class += ` text-2xl`;
            componentInfo.value.ulClass += ` space-x-0 dark:text-gray-200`;
            componentInfo.value.itemClass += ` w-10 h-10 pt-1`;
            break;
    }
};

const fnSet = () => {
    if (componentInfo.value.TotalCount > componentInfo.value.PageSize) {
        componentInfo.value.EndPage = Math.floor(componentInfo.value.TotalCount / componentInfo.value.PageSize);
        if ((componentInfo.value.TotalCount % componentInfo.value.PageSize) > 0) {
            componentInfo.value.EndPage += 1;
        }
        componentInfo.value.FirstPage = (componentInfo.value.CurPage > componentInfo.value.PageSize && (componentInfo.value.CurPage % componentInfo.value.PageSize) > 0) ? (Math.floor(componentInfo.value.CurPage / componentInfo.value.PageSize) * componentInfo.value.PageSize) + 1 : (Math.floor((componentInfo.value.CurPage - 1) / componentInfo.value.PageSize) * componentInfo.value.PageSize) + 1;
        componentInfo.value.LastPage = componentInfo.value.FirstPage + (componentInfo.value.PageSize - 1);
        if (componentInfo.value.LastPage > componentInfo.value.EndPage) {
            componentInfo.value.LastPage = componentInfo.value.EndPage;
        }
        componentInfo.value.items = [];
        for(let i = componentInfo.value.FirstPage; i <= componentInfo.value.LastPage; i++) {
            componentInfo.value.items.push({ page: i });
        }
    } else {
        componentInfo.value.EndPage = 1;
        componentInfo.value.FirstPage = 1;
        componentInfo.value.LastPage = 1;
        componentInfo.value.items = [ { page: 1 } ];
    }

    componentInfo.value.key += 1;
};

const fnPageMove = (num:number) => {
    if (componentInfo.value.Callback !== null && componentInfo.value.Callback !== undefined && typeof componentInfo.value.Callback === "function") {
        componentInfo.value.Callback(num);
    }
};

const fnPrevious = () => {
    if (componentInfo.value.FirstPage > componentInfo.value.PageSize) {
        componentInfo.value.CurPage = componentInfo.value.FirstPage - 1;
        fnSet();
        componentInfo.value.key += 1;
        if (componentInfo.value.Callback !== null && componentInfo.value.Callback !== undefined && typeof componentInfo.value.Callback === "function") {
            componentInfo.value.Callback(componentInfo.value.CurPage);
        }
    }
};

const fnNext = () => {
    if (componentInfo.value.EndPage > componentInfo.value.LastPage) {
        componentInfo.value.CurPage = componentInfo.value.LastPage + 1;
        fnSet();
        componentInfo.value.key += 1;
        if (componentInfo.value.Callback !== null && componentInfo.value.Callback !== undefined && typeof componentInfo.value.Callback === "function") {
            componentInfo.value.Callback(componentInfo.value.CurPage);
        }
    }
};

const fnPreviousPage = () => {
    if (componentInfo.value.CurPage > 1) {
        componentInfo.value.CurPage = componentInfo.value.CurPage - 1;
        fnSet();
        componentInfo.value.key += 1;
        if (componentInfo.value.Callback !== null && componentInfo.value.Callback !== undefined && typeof componentInfo.value.Callback === "function") {
            componentInfo.value.Callback(componentInfo.value.CurPage);
        }
    }
};

const fnNextPage = () => {
    if (componentInfo.value.CurPage < componentInfo.value.EndPage) {
        componentInfo.value.CurPage = componentInfo.value.CurPage + 1;
        fnSet();
        componentInfo.value.key += 1;
        if (componentInfo.value.Callback !== null && componentInfo.value.Callback !== undefined && typeof componentInfo.value.Callback === "function") {
            componentInfo.value.Callback(componentInfo.value.CurPage);
        }
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["hidden lg:block py-2", _unref(componentInfo).class]),
      key: `Paging_lg_${_unref(componentInfo).key}`
    }, [
      _createElementVNode("ul", {
        class: _normalizeClass(["flex paging justify-start", _unref(componentInfo).ulClass])
      }, [
        _createElementVNode("li", {
          onClick: fnPrevious,
          class: _normalizeClass([_unref(componentInfo).itemClass, "text-center text-gray-400 border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative bg-white dark:bg-slate-800 cursor-pointer hover:bg-green-500 hover:text-white"])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1)
        ]), 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (pageno, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `pagination_${_unref(componentInfo).key}_${index}`,
            onClick: ($event: any) => (fnPageMove(pageno.page)),
            class: _normalizeClass([`${_unref(componentInfo).itemClass} ${(Number(_unref(componentInfo).CurPage) === (pageno.page)) ? 'bg-green-300 text-gray-600 dark:text-gray-300 font-semibold' : 'text-gray-400'}`, "text-center border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative cursor-pointer dark:bg-slate-800 hover:bg-green-500 hover:text-white"])
          }, _toDisplayString(pageno.page), 11, _hoisted_1))
        }), 128)),
        _createElementVNode("li", {
          onClick: fnNext,
          class: _normalizeClass([_unref(componentInfo).itemClass, "text-center text-gray-400 border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative bg-white dark:bg-slate-800 cursor-pointer hover:bg-green-500 hover:text-white"])
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1)
        ]), 2)
      ], 2)
    ], 2)),
    (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["block lg:hidden py-2", _unref(componentInfo).class]),
      key: `Paging_sm_${_unref(componentInfo).key}`
    }, [
      _createElementVNode("ul", {
        class: _normalizeClass(["flex paging justify-start", _unref(componentInfo).ulClass])
      }, [
        _createElementVNode("li", {
          onClick: fnPreviousPage,
          class: _normalizeClass([_unref(componentInfo).itemClass, "text-center text-gray-400 border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative bg-white dark:bg-slate-800 cursor-pointer hover:bg-green-500 hover:text-white"])
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1)
        ]), 2),
        _createElementVNode("li", _hoisted_2, _toDisplayString(_unref(componentInfo).CurPage), 1),
        _createElementVNode("li", {
          onClick: fnNextPage,
          class: _normalizeClass([_unref(componentInfo).itemClass, "text-center text-gray-400 border-gray-200 dark:border-gray-600 items-center justify-center leading-tight relative bg-white dark:bg-slate-800 cursor-pointer hover:bg-green-500 hover:text-white"])
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1)
        ]), 2)
      ], 2)
    ], 2))
  ], 64))
}
}

})