import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4" }
const _hoisted_2 = { class: "w-full py-4 dark:text-gray-200" }
const _hoisted_3 = { class: "flex space-x-2" }
const _hoisted_4 = { value: "" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "p-1" }
const _hoisted_7 = {
  key: 0,
  class: "w-full my-3 flex justify-center space-x-2"
}

import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GInput,DefaultButton } from '@/components';
import { BoardRepository, FileRepository, Member, ReturnValues, ValidateHelper, BoardCategory, BoardContent, BoardContentDTO } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import Editor from '@tinymce/tinymce-vue'
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionEditPage',
  props: {
    class: {}
  },
  setup(__props: any) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

;

const { t, locale } = useI18n();
const property = __props;
const route = useRoute();
const router = useRouter();
const contentId = computed(() => (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '');
const auth = new AuthService();
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);
var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    content: new BoardContentDTO(),
    origin:new BoardContent(),
    categories:[] as BoardCategory[],
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    } else {
        MessageBox.Alert(t('common.requireLogin'), () => {
            router.push(`/login/?backurl=${location.pathname}`);
        });

        return;
    }

    if (contentId.value !== null && contentId.value !== undefined && String(contentId.value).trim() !== '') {
        let rst = await boardRep.GetContent(contentId.value);
        if (rst.check && rst.data !== null && rst.data !== undefined) {
            componentInfo.value.content.title = rst.data.title;
            componentInfo.value.content.content = rst.data.content;
            componentInfo.value.content.category = rst.data.category;
            componentInfo.value.origin = rst.data;
        }
    }

    await getCategories();
    componentInfo.value.isLoaded = true;
});

const getCategories = async () => {
    let rst = await boardRep.GetBoardCategories('qna');
    if (rst.check) {
        componentInfo.value.categories = rst.data;
    }
}

const uploadHandler = (blobInfo: any): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let rst:any = await fileRep.FileUpload(blobInfo.blob());
            if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
                let newImages = rst.data[0].webURL;
                resolve(newImages);
            } else {
                if (rst !== null && rst !== undefined) {
                    if (rst.message !== null && rst.message !== undefined) {
                        console.debug(rst.message);
                        reject(rst.message);
                    } else if (rst.errors !== null && rst.errors !== undefined && rst.errors.upload !== null && rst.errors.upload !== undefined && rst.errors.upload.length > 0) {
                        console.debug(rst.errors);
                        reject(rst.errors.upload[0]);
                    } else if (rst.title !== null && rst.title !== undefined) {
                        console.debug(rst.title);
                        reject(rst.title);
                    } else {
                        console.debug('upload fail');
                        reject('upload fail');
                    }
                } else {
                    console.debug('response is null or empty');
                    reject('response is null or empty');
                }
            }
        } catch (e:any) {
            console.debug(e.message);
            reject(e.message);
        }
    });
};

const handleDropzoneSuccess = (images: any[]) => {
    try {
        const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
        componentInfo.value.content.content += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

const handleInit = (editor: any) => {
    componentInfo.value.isLoaded = true;
};

const onSave = async () => {
    if (validate.IsNullOrWhiteSpace(componentInfo.value.content.content)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.content')}`);
    } else if (validate.IsNullOrWhiteSpace(componentInfo.value.content.title)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.title')}`);
    } else if (validate.IsNullOrWhiteSpace(componentInfo.value.content.category)) {
        MessageBox.Alert(t('common.selectCategory'));
    } else {
        let rst = new ReturnValues();
        let jsonData = new BoardContentDTO();
        jsonData.title = componentInfo.value.content.title;
        jsonData.content = componentInfo.value.content.content;
        jsonData.category = componentInfo.value.content.category;
        jsonData.masterCode = "qna";
        if (contentId.value !== null && contentId.value !== undefined && String(contentId.value).trim() !== '') {
            rst = await boardRep.UpdateContent(contentId.value, jsonData);
        } else {
            rst = await boardRep.RegistContent(jsonData);
        }
        
        if (rst.check) {
            MessageBox.Success(t('system.Saved'), () => {
                router.push(`/customer/qna/${rst.data.id}`);
            });
        } else {
            MessageBox.Alert(rst.message);
        }
    }
};

const onCancel = () => {
    if (componentInfo.value.origin.title !== componentInfo.value.content.title
        || componentInfo.value.origin.content !== componentInfo.value.content.content
        || componentInfo.value.origin.category !== componentInfo.value.content.category
    ) {
        MessageBox.Confirm(`${t('common.requiredsave')} ${t('common.cancelWriting')}`, () => {
            history.back();
        });
    } else {
        history.back();
    }
};



return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(componentInfo).key}`,
        class: _normalizeClass([_unref(componentInfo).class, "w-full px-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createElementVNode("select", {
                class: "border border-gray-300 rounded-md text-gray-500 w-1/4 dark:bg-slate-800",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).content.category) = $event))
              }, [
                _createElementVNode("option", _hoisted_4, _toDisplayString(_unref(t)('common.selectCategory')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).categories, (category, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: category.title,
                    key: `category_${index}`
                  }, _toDisplayString(_unref(t)(`site.${category.title}`)), 9, _hoisted_5))
                }), 128))
              ], 512), [
                [_vModelSelect, _unref(componentInfo).content.category]
              ]),
              _createVNode(_unref(GInput), {
                class: "border border-gray-300 rounded-md text-gray-500 w-3/4",
                modelValue: _unref(componentInfo).content.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(componentInfo).content.title) = $event)),
                placeholder: _unref(t)('common.requiredtitlement')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "my-3 border-t dark:border-gray-800" }, null, -1)),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(), _createBlock(_unref(Editor), {
                apiKey: _unref(config).editor.key,
                key: `Editor-${_unref(componentInfo).key}`,
                init: {
                height: 500,
                menubar: false,
                plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
                toolbar:
                'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
                images_upload_handler: uploadHandler,
                dropzone: {
                uploadMultiple: false,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                success: handleDropzoneSuccess
                },
                relative_urls: false,
                document_base_url: _unref(config).apis.article
            },
                modelValue: _unref(componentInfo).content.content,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(componentInfo).content.content) = $event)),
                onInit: handleInit
              }, null, 8, ["apiKey", "init", "modelValue"]))
            ])
          ])
        ]),
        (_unref(componentInfo).isLoggedIn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_unref(DefaultButton), {
                class: "w-[120px]",
                type: "accept",
                size: "lg",
                icon: "fa-solid fa-floppy-disk",
                onClick: onSave
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("SAVE")
                ])),
                _: 1
              }),
              _createVNode(_unref(DefaultButton), {
                class: "w-[120px]",
                type: "cancel",
                size: "lg",
                icon: "fa-solid fa-ban",
                onClick: onCancel
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("CANCEL")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})