import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start space-x-2 lg:space-x-5 mt-5 h-[50px]" }
const _hoisted_2 = { class: "pt-2 block lg:hidden" }
const _hoisted_3 = {
  key: 1,
  class: "pt-5 pr-4 flex justify-end space-x-1"
}
const _hoisted_4 = { class: "flex justify-center space-x-5 bg-slate-900 rounded-lg m-auto p-5 w-[70%]" }
const _hoisted_5 = {
  href: "/content/list",
  class: "text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium"
}
const _hoisted_6 = {
  href: "/album/list",
  class: "text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium"
}
const _hoisted_7 = {
  href: "/customer",
  class: "text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium"
}

import { useI18n } from 'vue-i18n';
  import { GContainer,GThirdSide,ProfileButton,LogoutButton,RegistButton,LoginButton } from '@/components';
  import { useRouter } from 'vue-router';
  import { onMounted, ref, onUnmounted,watch } from 'vue';
  import { AuthService } from '@/services';
  import { useMemberStore } from '@/store';
  import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
  import config from '@/config';
  import { MessageBox } from '@/utility';
  import DefaultButton from '../molecules/DefaultButton.vue';
  
  interface headerParameter {
    menu?:number|null|undefined
  }
export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    menu: {}
  },
  setup(__props: any) {

  const { t, locale } = useI18n();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const currentPath = ref(location.pathname);
  const validate = new ValidateHelper();

  ;

  const property = __props;

  var componentInfo = ref({
    key:0,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false
  });

  onMounted(async () => {
    componentInfo.value.menutab = property.menu ?? 0;

    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
      componentInfo.value.member = memberStore.member
      if (componentInfo.value.member === null 
      || componentInfo.value.member === undefined 
      || componentInfo.value.member.id === null 
      || componentInfo.value.member.id === undefined 
      || String(componentInfo.value.member.id).trim() === '')
      {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
          componentInfo.value.member = rst.data;
          
        } else {
          await auth.refreshSilent();
        }
        memberStore.setMember(rst.data);
      }

      componentInfo.value.isLogin = true;
      componentInfo.value.key += 1;
    }

    urlChange(location.pathname);

    window.addEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  watch([currentPath, () => memberStore.member],
    ([newPath, newMember], [oldPath, oldMember]) => {
      if (newPath !== oldPath) {
        urlChange(newPath);
      }

      if (newMember !== oldMember) {
        componentInfo.value.member = memberStore.member;
        componentInfo.value.key += 1;
      }
    }
  );

  const fnLogin = () => {
    router.push(`/login?backurl=` + location.pathname);
  };
  
  const fnRegist = () => {
    router.push(`/regist`);
  };

  const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
      await auth.logout();
      memberStore.clearMember();
    });
  };

  const urlChange = (url:string) => {
    if (false === validate.IsNullOrWhiteSpace(url)) {
      if (url.indexOf('/') > -1) {
        if (url.split('/')[0].trim() === '') {
          url = url.split('/')[1].trim().toLowerCase();
        } else {
          url = url.split('/')[0].trim().toLowerCase();
        }
      }
    }

    switch (url) {
      case "content":
        componentInfo.value.menutab = 1;
        componentInfo.value.key += 1;
        break;
      case "album":
        componentInfo.value.menutab = 2;
        componentInfo.value.key += 1;
        break;
      case "customer":
        componentInfo.value.menutab = 3;
        componentInfo.value.key += 1;
        break;
    }
  };

  const fnSideMenu = () => {
    componentInfo.value.isShowSide = !componentInfo.value.isShowSide;
  };

  const fnProfile = () => {
    router.push(`/profile`);
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", {
    key: `Header_${_unref(componentInfo).key}`
  }, [
    _createVNode(_unref(GContainer), { class: "flex justify-between space-x-5" }, {
      default: _withCtx(() => [
        _createVNode(_unref(GThirdSide), null, {
          left: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "h-[60px] flex justify-center align-middle items-center p-1 lg:p-8 mt-1" }, [
              _createElementVNode("a", {
                class: "block dark:hidden",
                href: "/"
              }, [
                _createElementVNode("img", {
                  src: "/logo/logo.png",
                  class: "w-auto lg:w-[50%] h-auto",
                  alt: "MerryTokTok"
                })
              ]),
              _createElementVNode("a", {
                class: "hidden dark:block",
                href: "/"
              }, [
                _createElementVNode("img", {
                  src: "/logo/logo_white.png",
                  class: "w-auto lg:w-[50%] h-auto",
                  alt: "MerryTokTok"
                })
              ])
            ], -1)
          ])),
          center: _withCtx(() => [
            (_openBlock(), _createElementBlock("div", {
              class: "pt-2 hidden lg:block",
              key: `Header_left_${_unref(componentInfo).key}`
            }, [
              _createElementVNode("ul", _hoisted_1, [
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    href: "/content/list",
                    class: _normalizeClass(["text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium", (_unref(componentInfo).menutab === 1) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''])
                  }, _toDisplayString(_unref(t)('site.topics')), 3)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    href: "/album/list",
                    class: _normalizeClass(["text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium", (_unref(componentInfo).menutab === 2) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''])
                  }, _toDisplayString(_unref(t)('site.explore')), 3)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("a", {
                    href: "/customer",
                    class: _normalizeClass(["text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium", (_unref(componentInfo).menutab === 3) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''])
                  }, _toDisplayString(_unref(t)('site.customer')), 3)
                ])
              ])
            ])),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(DefaultButton, {
                icon: "fa-solid fa-bars",
                class: "mt-2 flex justify-center text-center pl-1 pr-0",
                size: "md",
                "click-event": fnSideMenu,
                type: "default"
              })
            ])
          ]),
          right: _withCtx(() => [
            (_unref(componentInfo).isLogin)
              ? (_openBlock(), _createElementBlock("div", {
                  class: "pt-5 pr-4 flex justify-end space-x-1",
                  key: `Header_right_${_unref(componentInfo).key}`
                }, [
                  _createVNode(_unref(ProfileButton), {
                    class: "mt-1",
                    size: "sm",
                    member: _unref(componentInfo).member,
                    "click-event": fnProfile
                  }, null, 8, ["member"]),
                  _createVNode(_unref(LogoutButton), {
                    class: "mt-1",
                    size: "sm",
                    clickEvent: fnLogout
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_unref(LoginButton), {
                    class: "mt-1",
                    size: "sm",
                    clickEvent: fnLogin
                  }),
                  _createVNode(_unref(RegistButton), {
                    class: "mt-1",
                    size: "sm",
                    clickEvent: fnRegist
                  })
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_unref(componentInfo).isShowSide)
      ? (_openBlock(), _createBlock(_unref(GContainer), {
          key: 0,
          class: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("a", _hoisted_5, _toDisplayString(_unref(t)('site.topics')), 1),
              _createElementVNode("a", _hoisted_6, _toDisplayString(_unref(t)('site.explore')), 1),
              _createElementVNode("a", _hoisted_7, _toDisplayString(_unref(t)('site.customer')), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})