import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mt-4 flex justify-between space-x-2" }
const _hoisted_2 = { class: "flex space-x-0 justify-start input-group" }
const _hoisted_3 = { class: "flex space-x-2" }
const _hoisted_4 = { class: "w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4" }
const _hoisted_5 = { class: "w-full mt-4 pb-8 flex justify-center border-t border-slate-600 pt-4" }

import { onMounted, ref, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GTable,GInput,Pagination,GButton,DefaultButton } from '@/components';
import { LinkItem, PagingParameter,Member } from '@gn2studio/gn2.library.common';
import { BoardContent, BoardRepository } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

interface optionParameter {
    class?:string|null|undefined,
    isEdit?:boolean|null|undefined,
    selectedContent?:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionListPage',
  props: {
    class: {},
    isEdit: { type: [Boolean, null] },
    selectedContent: { type: [Function, null] }
  },
  setup(__props: any) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

;

const property = __props;
const route = useRoute();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();

var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    accessToken:'',
    member:null as Member|null,
    isLogin:false,
    isLoaded:false,
    isEdit:false,
    item:{} as LinkItem,
    totalCount:0,
    items:[] as BoardContent[],
    paging: new PagingParameter(),
    currentId:'',
    columns:[{ name:'제목', id:'title' }]
});

onMounted(async () => {
    componentInfo.value.paging = { curPage : ((route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1), pageSize : 10, keyword : "" };
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.currentId = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id).trim() : '';
    componentInfo.value.isEdit = (property.isEdit !== null && property.isEdit !== undefined) ? property.isEdit : false;

    componentInfo.value.accessToken = await auth.getAccessToken();
    componentInfo.value.member = memberStore.member;
    if (componentInfo.value.accessToken !== null 
        && componentInfo.value.accessToken !== undefined 
        && String(componentInfo.value.accessToken).trim() !== ''
        && componentInfo.value.member !== null 
        && componentInfo.value.member !== undefined) 
    {
        componentInfo.value.isLogin = true;
        boardRep = new BoardRepository(config.apis.board, componentInfo.value.accessToken, config.global.slug);
    }

    await getQuestion();

    componentInfo.value.isLoaded = true;
});

watch(
  () => [route.params.id, route.query.curpage],
  ([newId, newPage]) => {
    componentInfo.value.currentId =
      newId !== null && newId !== undefined ? String(newId).trim() : '';

    componentInfo.value.paging.curPage =
      newPage !== null && newPage !== undefined ? Number(newPage) : 1;
  },
  { immediate: true }
);

const fnContentView = (content:BoardContent) => {
    if (property.selectedContent !== null && property.selectedContent !== undefined && typeof property.selectedContent === "function") {
        property.selectedContent(content);
    }
}

const getQuestion = async () => {
    let rst = await boardRep.GetUserBoards('qna', componentInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        componentInfo.value.totalCount = rst.code;
        componentInfo.value.items = rst.data;
    }
};

const fnPaging = (curpage:number) => {
    router.push(`${location.pathname}?curpage=${curpage}`)
    componentInfo.value.paging.curPage = curpage;
    getQuestion();
    componentInfo.value.key += 1;
};

const fnSubmit = () => {
    getQuestion();
    componentInfo.value.key += 1;
};

const fnWrite = () => {
    router.push(`/customer/qna/edit`);
}

const fnEdit = () => {
    router.push(`/customer/qna/edit/${componentInfo.value.currentId}`);
}

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `customer_${_unref(componentInfo).key}`,
        class: _normalizeClass([_unref(componentInfo).class, "w-full px-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(GInput), {
              type: "text",
              placeholder: "Search",
              size: "sm",
              class: "p-2 border w-[120px] outline-none",
              modelValue: _unref(componentInfo).paging.keyword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).paging.keyword) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(GButton), {
              onClick: fnSubmit,
              class: "py-1 px-3 ml-1 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400",
              size: "sm"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "fa-solid fa-magnifying-glass" }, null, -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_unref(componentInfo).isLogin && _unref(componentInfo).isEdit)
              ? (_openBlock(), _createBlock(_unref(DefaultButton), {
                  key: 0,
                  icon: "fa-solid fa-pen-to-square",
                  class: "",
                  type: "default",
                  size: "sm",
                  onClick: fnEdit
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("수정하기")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(componentInfo).isLogin)
              ? (_openBlock(), _createBlock(_unref(DefaultButton), {
                  key: 1,
                  icon: "fa-solid fa-pen-to-square",
                  class: "",
                  type: "default",
                  size: "sm",
                  onClick: fnWrite
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("문의하기")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(GTable), {
            items: _unref(componentInfo).items,
            "click-event": fnContentView
          }, {
            columns: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("th", null, "no", -1),
              _createElementVNode("th", null, "Title", -1),
              _createElementVNode("th", null, "RegistDate", -1)
            ])),
            items: _withCtx(({ item, index }) => [
              _createElementVNode("td", null, _toDisplayString(_unref(componentInfo).totalCount - index), 1),
              _createElementVNode("td", null, _toDisplayString(item.title), 1),
              _createElementVNode("td", null, _toDisplayString(_unref(dayjs).utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
            ]),
            _: 1
          }, 8, ["items"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Pagination), {
            totalCount: _unref(componentInfo).totalCount,
            curPage: _unref(componentInfo).paging.curPage,
            size: "md",
            callback: fnPaging
          }, null, 8, ["totalCount", "curPage"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})