import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative flex flex-col items-center pt-12 z-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-2xl font-semibold mt-4" }
const _hoisted_4 = { class: "py-5" }

import { onMounted, ref, watch } from 'vue';
import config from '@/config';
import { MemberRepository,Member,ValidateHelper, FileRepository } from '@gn2studio/gn2.library.common';
import { useMemberStore,useGlobalStore } from '@/store';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';

interface containerParameter {
    class?:string|null,
    member:Member
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileSection',
  props: {
    class: {},
    member: {}
  },
  setup(__props: any) {

;

const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();
const validate = new ValidateHelper();
const site = useGlobalStore();

var memberRep = new MemberRepository(config.apis.member, '');
var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    isLoggedIn:false,
    accessToken:'',
    member: new Member(),
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.member = property.member ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        componentInfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, token);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
    }

    componentInfo.value.isLoaded = true;
});

watch(() => memberStore.member,
  (newValue) => {
    componentInfo.value.member = memberStore.member;
    componentInfo.value.key += 1;
  }
);

const fnReplace = (str:string, replace:string):string => {
    return (validate.IsNullOrWhiteSpace(str)) ? replace : str.trim();
}

const fnProfilePhoto = () => {
  document.getElementById('profile_photo_upload')!.click();
}

const fnBackground = () => {
  document.getElementById('background_photo_upload')!.click();
}

const fnUploadProfilePhoto = async (event: Event) => {
  if (componentInfo.value.isLoggedIn) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      site.setValue('loading', true);
      let rst = await fileRep.FileUpload(file);
      if (rst.check) {
        if (rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
          let tempValue = componentInfo.value.member.photo;
          componentInfo.value.member.photo = rst.data[0].webURL;
          let rtn = await memberRep.UpdateMemberInfo(componentInfo.value.member);
          if (rtn.check) {
            memberStore.setMember(componentInfo.value.member);
          } else {
            componentInfo.value.member.photo = tempValue;
            MessageBox.Alert('File upload failed.');
          }
        } else {
          MessageBox.Alert('Not found upload file url');
        }
      } else {
        MessageBox.Alert(rst.message);
      }

      site.setValue('loading', false);
    }
  }
};

const fnUploadBackground = async (event: Event) => {
  if (componentInfo.value.isLoggedIn) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      site.setValue('loading', true);
      let rst = await fileRep.FileUpload(file);
      if (rst.check) {
        if (rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
          let tempValue = componentInfo.value.member.background;
          componentInfo.value.member.background = rst.data[0].webURL;
          let rtn = await memberRep.UpdateMemberInfo(componentInfo.value.member);
          if (rtn.check) {
            memberStore.setMember(componentInfo.value.member);
          } else {
            componentInfo.value.member.background = tempValue;
            MessageBox.Alert('File upload failed.');
          }
        } else {
          MessageBox.Alert('Not found upload file url');
        }
      } else {
        MessageBox.Alert(rst.message);
      }

      site.setValue('loading', false);
    }
  }
};


return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["relative bg-cover bg-center h-80", _unref(componentInfo).class]),
        key: _unref(componentInfo).key,
        style: _normalizeStyle(`background-image: url('${fnReplace(_unref(componentInfo).member.background, 'https://via.placeholder.com/1200x400')}');background-size:cover;`)
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute inset-0 bg-black bg-opacity-50" }, null, -1)),
        (_unref(componentInfo).isLoaded)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              onClick: fnBackground,
              class: "border border-gray-100 w-6 h-6 absolute cursor-pointer z-50",
              style: {"top":"10px","right":"10px"}
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "fa-solid fa-arrow-up-from-bracket" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: fnReplace(_unref(componentInfo).member.photo, 'https://via.placeholder.com/120'),
            alt: "Profile",
            class: _normalizeClass(["w-24 h-24 rounded-full border-4 border-white shadow-lg", (_unref(componentInfo).isLoggedIn) ? 'cursor-pointer' : '']),
            onClick: fnProfilePhoto
          }, null, 10, _hoisted_2),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref(componentInfo).member.nickName), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(componentInfo).member.bio), 1),
          _renderSlot(_ctx.$slots, "default"),
          _createElementVNode("input", {
            id: "profile_photo_upload",
            type: "file",
            accept: "image/*",
            style: {"display":"none"},
            onChange: fnUploadProfilePhoto
          }, null, 32),
          _createElementVNode("input", {
            id: "background_photo_upload",
            type: "file",
            accept: "image/*",
            style: {"display":"none"},
            onChange: fnUploadBackground
          }, null, 32)
        ])
      ], 6))
    : _createCommentVNode("", true)
}
}

})