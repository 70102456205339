import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { StringHelper } from '@gn2studio/gn2.library.common';
    import { GImage } from '../atoms';
    
    interface imgParameter {
        src:string,
        width:string,
        height:string,
        border:number,
        link?:string|null,
        class?:string|null
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CircleImage',
  props: {
    src: {},
    width: {},
    height: {},
    border: {},
    link: {},
    class: {}
  },
  setup(__props: any) {

    const property = __props;
    const router = useRouter();

    var componentInfo = ref({
        src:"",
        width:"",
        height:"",
        border:0,
        link:"",
        class:"",
        isLoaded:false,
        key:0
    });

    onMounted(() => {
        componentInfo.value.width = property.width;
        componentInfo.value.height = property.height;
        componentInfo.value.src = property.src;
        if (componentInfo.value.src === null || componentInfo.value.src === undefined || componentInfo.value.src.trim() === "") {
            componentInfo.value.src = `https://via.placeholder.com/${StringHelper.extractNumbersFromString(componentInfo.value.width)}x${StringHelper.extractNumbersFromString(componentInfo.value.height)}`
        }

        componentInfo.value.border = property.border;
        componentInfo.value.link = property.link ?? "";
        componentInfo.value.class = property.class ?? "";

        componentInfo.value.isLoaded = true;
    });


return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GImage), {
        key: 0,
        class: _normalizeClass(["imageCircle", property.class]),
        src: property.src,
        width: property.width,
        height: property.height,
        border: property.border
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class", "src", "width", "height", "border"]))
    : _createCommentVNode("", true)
}
}

})