import { LinkItem } from "@gn2studio/gn2.library.common";

const SiteData = {
    tags : [
        { title: '이슈', url: '/content/list/이슈', icon: 'fa-solid fa-fire', active: false, type: 'default' }, 
        { title: '경제', url: '/content/list/경제', icon: 'fa-solid fa-money-bill', active: false, type: 'default' }, 
        { title: '정치', url: '/content/list/정치', icon: 'fa-regular fa-handshake', active: false, type: 'default' }, 
        { title: '직장', url: '/content/list/직장', icon: 'fa-solid fa-briefcase', active: false, type: 'default' }, 
        { title: '포토', url: '/content/list/포토', icon: 'fa-solid fa-camera', active: false, type: 'default' }, 
        { title: '만화', url: '/content/list/만화', icon: 'fa-solid fa-dragon', active: false, type: 'default' }, 
        { title: '얼짱,몸짱', url: '/content/list/얼짱,몸짱', icon: 'fa-brands fa-instagram', active: false, type: 'default' }, 
        { title: '연예인', url: '/content/list/연예인', icon: 'fa-solid fa-star', active: false, type: 'default' }, 
        { title: '여행,장소', url: '/content/list/여행,장소', icon: 'fa-solid fa-location-dot', active: false, type: 'default' }, 
        { title: '영화,드라마', url: '/content/list/영화,드라마', icon: 'fa-solid fa-clapperboard', active: false, type: 'default' }, 
        { title: '추억', url: '/content/list/추억', icon: 'fa-solid fa-film', active: false, type: 'default' }, 
        { title: '영상', url: '/content/list/영상', icon: 'fa-solid fa-video', active: false, type: 'default' }
    ] as LinkItem[],
    admintag : [
        { title: '이슈', url: '/content/list/이슈', icon: 'fa-solid fa-fire', active: false, type: 'default' }, 
        { title: '경제', url: '/content/list/경제', icon: 'fa-solid fa-money-bill', active: false, type: 'default' }, 
        { title: '정치', url: '/content/list/정치', icon: 'fa-regular fa-handshake', active: false, type: 'default' }, 
        { title: '직장', url: '/content/list/직장', icon: 'fa-solid fa-briefcase', active: false, type: 'default' }, 
        { title: '포토', url: '/content/list/포토', icon: 'fa-solid fa-camera', active: false, type: 'default' }, 
        { title: '만화', url: '/content/list/만화', icon: 'fa-solid fa-dragon', active: false, type: 'default' }, 
        { title: '얼짱,몸짱', url: '/content/list/얼짱,몸짱', icon: 'fa-brands fa-instagram', active: false, type: 'default' }, 
        { title: '연예인', url: '/content/list/연예인', icon: 'fa-solid fa-star', active: false, type: 'default' }, 
        { title: '여행,장소', url: '/content/list/여행,장소', icon: 'fa-solid fa-location-dot', active: false, type: 'default' }, 
        { title: '영화,드라마', url: '/content/list/영화,드라마', icon: 'fa-solid fa-clapperboard', active: false, type: 'default' }, 
        { title: '추억', url: '/content/list/추억', icon: 'fa-solid fa-film', active: false, type: 'default' }, 
        { title: '영상', url: '/content/list/영상', icon: 'fa-solid fa-video', active: false, type: 'default' },
        { title: '성인', url: '/content/list/성인', icon: 'fa-solid fa-triangle-exclamation', active: false, type: 'default' }
    ] as LinkItem[]
};

export default SiteData;