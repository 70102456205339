import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "imgGroup mt-4 grid grid-cols-12 gap-4"
}
const _hoisted_2 = { class: "imgButton" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src", "alt"]

import { onMounted, ref } from 'vue';
  import { ReturnValues,FileRepository,Member,ResourceData } from '@gn2studio/gn2.library.common';
  import { MessageBox } from '@/utility';
  import config from '@/config';
  import { useMemberStore } from '@/store';
  import { AuthService } from '@/services';
  
  interface MultiFileUploadParameter {
    callback?:Function|null|undefined
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MultiFileUpload',
  props: {
    callback: { type: [Function, null] }
  },
  setup(__props: any) {

  const images = ref<any[]>([]);
  const isDragging = ref(false);
  
  const property = __props;
  const auth = new AuthService();
  const memberStore = useMemberStore();

  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
  });

  onMounted(async () => {
    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }
  });

  const onDragOver = () => {
    isDragging.value = true;
  };
  
  const onDragLeave = () => {
    isDragging.value = false;
  };
  
  const onDrop = (event: DragEvent) => {
    isDragging.value = false;
    if (event.dataTransfer !== null) {
      const acceptedFiles = Array.from(event.dataTransfer.files).filter((file: File) => file.type.startsWith('image/'));
      addImages(acceptedFiles);
    }
  };
  
  const openFilePicker = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.multiple = true;
    fileInput.addEventListener('change', (event) => {
      if (event.target !== null && event.target instanceof HTMLInputElement) {
        const selectedFiles = Array.from((event.target as HTMLInputElement).files || []).filter((file) => file.type.startsWith('image/'));
        addImages(selectedFiles);
      }
    });
    fileInput.click();
  };
  
  const addImages = (newImages: File[]) => {
    images.value = [...images.value, ...newImages.map((file) => ({ file, url: URL.createObjectURL(file) }))];
  };
  
  const handleDelete = (index: number) => {
    images.value = images.value.filter((_, i) => i !== index);
  };
  
  const handleUpload = async () => {
    const formData = new FormData();
    images.value.forEach((image) => {
      formData.append('upload', image.file);
    });
  
    try {
      let rst: ReturnValues = await fileRep.FileUploads(formData);
      if (rst.check) {
        images.value = [];
        uploadCallback(rst.data);
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (err: any) {
      MessageBox.Alert(err.message);
    }
  };
  
  // Callback event for successful upload
  const uploadCallback = (uploadedImages: ResourceData[]) => {
    // Handle the uploaded images here
    if (property.callback !== null && property.callback !== undefined && typeof property.callback === "function") {
        property.callback(uploadedImages);
    }
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["multiupload", _unref(componentInfo).isLoaded]),
    key: _unref(componentInfo).key
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["draglayer border-dashed border-2 border-gray-300 p-6 rounded-md cursor-pointer", { 'dragging': isDragging.value }]),
      onDragover: _withModifiers(onDragOver, ["prevent"]),
      onDrop: _withModifiers(onDrop, ["prevent"]),
      onDragleave: onDragLeave,
      onClick: openFilePicker
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("p", { class: "text-gray-500" }, "여러 이미지를 한번에 올리시려면 여기를 클릭하시거나, 드래그 하셔서 업로드하세요.", -1)
    ]), 34),
    (images.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "imgItem"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  onClick: ($event: any) => (handleDelete(index))
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "fas fa-times text-red-500" }, null, -1)
                ]), 8, _hoisted_3)
              ]),
              _createElementVNode("img", {
                src: image.url,
                alt: image.name,
                class: "object-cover rounded-md",
                style: {"width":"45px","height":"45px"}
              }, null, 8, _hoisted_4)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (images.value.length > 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "btn border px-3 py-1 mt-4 bg-green-500 text-white",
          onClick: handleUpload
        }, " All Upload "))
      : _createCommentVNode("", true)
  ], 2))
}
}

})