<template>
<div class="p-4">
    <PinterView v-model="componentInfo.items" :clickitem="fnMove"></PinterView>
    <div ref="observer" class="h-1"></div>
</div>
</template>

<script setup lang="ts">
import { Content, ContentRepository, PagingParameter } from '@gn2studio/gn2.library.common';
import { onMounted, ref, nextTick } from 'vue';
import { useGlobalStore } from '@/store';
import config from '@/config';
import router from '@/router';
import { HeadSet } from '@/utility';
import { useHead } from '@vueuse/head';
import { PinterView } from '@/components';

const site = useGlobalStore();
const contentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    items: [] as Content[],
    paging: new PagingParameter()
});

// Observer reference
const observer = ref<HTMLDivElement | null>(null);

// Load initial content on mount
onMounted(async () => {
    componentInfo.value.paging.pageSize = 30;

    const head = new HeadSet();
    head.title = `이슈 모아보기 - MerryTokTok`;
    head.url = `${location.origin}/album/list`;
    useHead(head.CreateJson());

    await onLoadContent();

    // Initialize IntersectionObserver
    nextTick(() => setupObserver());

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

// Load content function
const onLoadContent = async () => {
    let rst = await contentRep.GetContents(componentInfo.value.paging);
    if (rst.check) {
        componentInfo.value.items = [...componentInfo.value.items, ...rst.data];
    }
};

// Load next content
const NextContent = async () => {
    site.setValue('loading', true);
    componentInfo.value.paging.curPage += 1;
    await onLoadContent();
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
};

// Setup IntersectionObserver
const setupObserver = () => {
    if (observer.value) {
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    NextContent();
                }
            });
        });
        io.observe(observer.value);
    }
};

const fnMove = (item:Content) => {
    router.push(`/content/view/${item.id}`);
}
</script>
