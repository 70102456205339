import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { onMounted, ref } from 'vue';
    import GButton from '../atoms/GButton.vue';
    import { LinkItem } from '@gn2studio/gn2.library.common';
    import { useRouter } from 'vue-router';

    interface tabParameter {
        class?:string|null,
        items?:LinkItem[]
    }const onClass = "active border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200";
    const offClass = "border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400";

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GTab',
  props: {
    class: {},
    items: {}
  },
  setup(__props: any) {

    ;

    const property = __props;
    const router = useRouter();
    var componentInfo = ref({
        key:0,
        class:'',
        items:[] as LinkItem[]
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.items = property.items ?? [];
    });

    const fnClick = (item:LinkItem) => {
        if (item.type === 'blank') {
            const a = document.createElement("a");
            a.href = item.url;
            a.target = "_blank";
            a.click();
        } else {
            if (item.url.trim() !== '') {
                router.push(item.url);
            }
        }
    };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gtab flex justify-start space-x-1", _unref(componentInfo).class]),
    key: `tab_${_unref(componentInfo).key}`
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
      return (_openBlock(), _createBlock(GButton, {
        key: `tab_item_${index}`,
        clickEvent: () => fnClick(item),
        size: "xs",
        class: _normalizeClass((item.active) ? onClass : offClass)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.title), 1)
        ]),
        _: 2
      }, 1032, ["clickEvent", "class"]))
    }), 128))
  ], 2))
}
}

})