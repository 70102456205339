<template>
<button v-if="componentInfo.isLoaded" type="button" :class="componentInfo.class" @click="fnButtonClick" :key="`button_${componentInfo.key}`">
    <slot></slot>
</button>

</template>

<script setup lang="ts">
import { onMounted, ref,watch } from 'vue';

interface buttonParameter {
    class?:string|null,
    size?:string|null,
    clickEvent?:Function|null|undefined
};

const property = defineProps<buttonParameter>();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'md';

    switch (componentInfo.value.size) {
        case "xs":
            componentInfo.value.class += ` text-xs`
            break;
        case "sm":
            componentInfo.value.class += ` text-sm`
            break;
        case "md":
            componentInfo.value.class += ` text-md`
            break;
        case "lg":
            componentInfo.value.class += ` text-lg`
            break;
        case "xl":
            componentInfo.value.class += ` text-xl`
            break;
        case "2xl":
            componentInfo.value.class += ` text-2xl`
            break;
    }

    componentInfo.value.isLoaded = true;
});

watch(() => property,
    (newValue) => {
        componentInfo.value.size = (newValue.size !== null && newValue.size !== undefined) ? newValue.size : '';
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.key += 1;
    }
);

const fnButtonClick = () => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === 'function') {
        property.clickEvent();
    }
};
</script>