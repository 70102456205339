<template>
<GContainer>
<div class="w-full">
  <h2 class="text-2xl text-center my-8 dark:text-white font-semibold">이용약관</h2>
  <hr class="border-t border-gray-200 dark:border-gray-600 my-4" />
  <div class="text-md p-4 dark:text-gray-200">
    제1장 총칙
    <br />
    <br />
    <br />
    제1조 (목적)
    <br />
    본 약관은 메리톡톡(이하 '회사' )이 제공하는 인터넷 관련 서비스 "짱공유" (이하 서비스)를 이용함에 있어 '회사' 와 이용자(또는 회원)와의 권리, 이용조건 및 절차, 책임사항 등 기타 기본적인 사항을 구체적으로 규정함을 목적으로 합니다.
    <br />
    <br />
    <br />
    제2조 (이용약관의 효력 및 변경)
    <br />
    ① 이 약관은 '회사' 가 제공하는 서비스에서 이용자들이 쉽게 알 수 있도록 온라인으로 공시함으로써 효력을 발생합니다.
    <br />
    ② '회사' 는 합리적인 사유가 발생할 경우 대한민국 법령과 국제법에 위배하지 않는 범위 안에서 이 약관을 개정할 수 있습니다.
    <br />
    ③ 개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 개정 7일 전 온라인을 통하여 공지합니다.
    <br />
    ④ 이용자는 변경된 약관에 동의하지 않으면, 언제나 서비스 이용을 중단하고, 이용계약을 해지할 수 있습니다. 단, 약관의 효력발생일 이후에 계속된 서비스 이용은 약관의 변경사항에 대한 이용자의 동의로 간주합니다.
    <br />
    <br />
    <br />
    제3조 (약관 외 준칙)
    <br />
    ① 이 약관은 '회사' 가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 합니다)와 함께 적용합니다.
    <br />
    ② 이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 '회사' 가 정한 세부지침 사항을 적용할 수 있습니다.
    <br />
    <br />
    <br />
    제4조 (용어의 정의)
    <br />
    ① 회원: 서비스를 이용하기 위하여 '회사' 와 이용계약을 체결하고 아이디와 비밀번호를 부여받은 자를 말합니다.
    <br />
    ② 이용고객: 회원 및 회원이 아닌 서비스 이용자를 말합니다.
    <br />
    ③ 이용계약: 이용고객이 '회사' 가 정한 약관에 동의하고 온라인 신청양식을 작성하여 서비스 이용을 신청하면 '회사' 가 이용고객에게 이용자 아이디를 부여하여 서비스를 이용할 수 있도록 하는 것을 말합니다.
    <br />
    ④ 아이디(Email): 회원의 식별과 회원이 정하고 '회사' 가 승인한 이메일주소를 말합니다.
    <br />
    ⑤ 비밀번호: 회원의 권익보호를 위하여 회원이 선정한 문자 및 숫자의 조합을 말합니다.
    <br />
    ⑥ 회원정보: 성명, 연락처 등 '회사' 가 온라인 신청양식에 기재를 요청하는 회원의 개인정보를 말합니다.
    <br />
    ⑦ 게시물: '회사' 가 제공하는 서비스의 게시판에 회원이 게재 또는 등록하는 질문, 답변, 자료, 사진, 영상, 댓글 등을 의미합니다.
    <br />
    ⑧ 운영자: 서비스의 전반적인 관리 및 원활한 운영을 위하여 '회사' 가 고용한 자를 말합니다.
    <br />
    ⑨ 게시판 관리자: 회원 중 '회사' 가 정한 선발 정책을 통과하여 게시판의 관리를 맡아 하는 회원을 말합니다.
    <br />
    <br />
    <br />
    <br />
    <br />
    제2장 서비스 이용 및 계약 체결
    <br />
    <br />
    <br />
    제5조 (이용 계약의 성립)
    <br />
    ① 이용계약은 이용고객이 이용약관 및 개인정보취급방침 내용에 대한 “동의” 버튼을 누름으로써 동의한 것으로 간주합니다.
    <br />
    ② 이용계약은 이용고객의 이용신청에 대하여 '회사' 가 이를 승낙함으로써 성립합니다.
    <br />
    <br />
    <br />
    제6조 (서비스 이용 신청)
    <br />
    ① 회원가입은 별명으로만 가입하여야 합니다.
    <br />
    ② 회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 '회사' 에서 요청하는 제반 정보를 제공하여야 합니다.
    <br />
    1. 전자메일(E-mail)
    <br />
    2. 닉네임
    <br />
    3. 비밀번호
    <br />
    <br />
    ③ 모든 회원은 반드시 회원 본인의 이메일을 제공하여야만 서비스를 이용할 수 있습니다.
    <br />
    ④ 타인의 명의(개인정보) 도용하여 이용신청을 한 회원의 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
    <br />
    ⑤ '회사' 는 본 서비스를 이용하는 회원에 대하여 등급별로 구분하여 서비스 이용에 차등을 둘 수 있습니다.
    <br />
    <br />
    <br />
    제7조 (이용자의 개인 정보 보호)
    <br />
    '회사' 는 관련법령이 정하는 바에 따라서 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다. 이용자의 개인정보보호에 관해서는 관련법령 및 "'회사' "가 정하는 "개인정보 취급방침"에 정한 바에 의합니다.
    <br />
    <br />
    <br />
    제8조 (이용 신청의 승낙)
    <br />
    ① 이용자가 되고자 하는 자는 제6조에서 정한 가입 양식에 따라 회원정보를 기입하고 "등록하기" 단추를 누른 회원을 대상으로 서비스 이용 승낙을 합니다.
    <br />
    <br />
    <br />
    제9조 (이용 신청의 제한)
    <br />
    ① '회사' 는 다음 각호에 해당하는 경우에 대하여서는 서비스 이용 승낙을 하지 않을 수 있습니다.
    <br />
    1. 타인의 명의를 도용하여 허위로 신청하는 경우
    <br />
    2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
    <br />
    3. 가입신청자가 본 약관 의거하여 이전에 회원 자격을 상실한 적이 있는 경우
    <br />
    4. 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우
    <br />
    5. 버그 및 악성 프로그램을 사용하여 시스템의 취약점을 이용하여 가입 신청을 한 경우
    <br />
    6. 이용자의 귀책사유로 인하여 사이트 운영정책에 따라 회원 자격 상실을 한 적이 있는 경우
    <br />
    7. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
    <br />
    8. 기타 '회사' 가 정한 이용신청 요건이 만족하지 않았을 경우
    <br />
    ② 서비스 이용신청이 다음 각호에 해당하는 경우에는 '회사' 는 그 신청에 대하여 승낙 제한 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
    <br />
    1. 설비의 여유가 없는 경우
    <br />
    2. 기술상 지장이 있는 경우
    <br />
    3. 기타 귀책사유로 이용승낙이 곤란한 경우
    <br />
    ③ 이용신청고객이 관계법령에서 규정하는 미성년자일 경우에 '회사' 는 서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.
    <br />
    <br />
    <br />
    제10조 (이용자ID 부여 및 변경 등)
    <br />
    ① 이용고객에 대하여 약관에 정하는 바에 따라 '회사' 는 이용자 ID를 부여합니다.
    <br />
    ② 이용자 ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.
    <br />
    ③ 서비스의 이용자 ID는 이용자 본인의 동의로 자사 사이트의 회원 ID와 연결될 수 있습니다.
    <br />
    ④ 이용자 ID는 다음 각호에 해당하는 경우에는 이용고객 또는 '회사' 의 요청으로 변경할 수 있습니다.
    <br />
    1. 이용자 ID가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 심각한 사생활침해가 우려되는 경우
    <br />
    2. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
    <br />
    3. 기타 합리적인 사유가 있는 경우
    <br />
    ⑤ 서비스 이용자 ID 및 비밀번호의 관리책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 이용자에게 있으며 '회사' 는 그에 대한 책임을 일절 지지 않습니다.
    <br />
    ⑥ 기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 서비스별 안내에 정하는 바에 의합니다.
    <br />
    <br />
    <br />
    제11조 (서비스 이용 시간 및 중지)
    <br />
    ① 서비스 이용은 '회사' 의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
    <br />
    ② '회사' 는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 '회사' 가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다. 이러한 경우 '회사' 는 사전 또는 사후에 이를 이용자들이 쉽게 알 수 있도록 공지합니다.
    <br />
    ③ '회사' 는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
    <br />
    ④ '회사' 는 통제 불가능의 사유로 인한 서비스가 중단된 경우 사전통지가 불가능하며 타인의 고의, 과실로 인한 시스템 중단 등의 경우에는 통보하지 아니합니다.
    <br />
    ⑤ '회사' 는 서비스를 특정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다.
    <br />
    <br />
    <br />
    제12조 (이용자ID 관리)
    <br />
    ① 이용자 ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다.
    <br />
    ② '회사' 는 이용자 ID에 의하여 제반 이용자 관리업무를 수행하므로 회원이 이용자 ID를 변경하고자 하는 경우 '회사' 가 인정할 만한 사유가 없는 한 이용자 ID의 변경을 제한할 수 있습니다.
    <br />
    ③ 이용고객이 등록한 이용자 ID 및 비밀번호에 의하여 발생하는 사용상의 과실 또는 제삼자에 의한 부정사용 등에 대한 모든 책임은 해당 이용고객에게 있습니다.
    <br />
    <br />
    <br />
    제13조 (게시물의 관리)
    <br />
    '회사' 는 다음 각호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있습니다.
    <br />
    1. 다른 회원 또는 제삼자에게 심한 모욕을 주거나 명예를 손상하는 내용인 경우
    <br />
    2. 타인의 초상권, 사생활, 영업상 비밀 등을 유포하는 경우
    <br />
    3. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
    <br />
    4. 불법복제 또는 해킹을 조장하는 내용인 경우
    <br />
    5. 영리를 목적으로 하는 광고일 경우
    <br />
    6. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
    <br />
    7. 다른 이용자 또는 제삼자의 저작권 등 기타 권리를 침해하는 내용인 경우
    <br />
    8. '회사' 에서 규정한 운영정책에 어긋나거나, 게시판 성격에 부합하지 않는 거나 운영상 악영향을 주거나 혼란을 주는 경우
    <br />
    9. 동일한 내용을 중복하여 다수의 게시판에 작성하여 게시의 목적에 어긋나는 경우
    <br />
    10. 기타 관계법령에 위배된다고 판단되는 경우
    <br />
    <br />
    <br />
    제14조 (게시물에 대한 저작권)
    <br />
    ① 회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 '회사' 는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다. 다만 비영리 목적인 경우는 그러하지 아니하며, 또한 서비스 내의 게재권을 갖습니다.
    <br />
    ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
    <br />
    ③ '회사' 는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제 13조 각호에 해당한다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.
    <br />
    <br />
    <br />
    제 15 조 (정보의 제공)
    <br />
    ① '회사' 는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전자우편이나 서신 우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만 동의하지 않은 회원에게는 제공하지 아니합니다.
    <br />
    ② '회사' 는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의로 추가적인 개인 정보를 요구할 수 있습니다.
    <br />
    <br />
    <br />
    제16조 (광고게재 및 광고주와의 거래)
    <br />
    ① '회사' 가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고 게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용 시 노출되는 광고 게재에 대해 동의합니다.
    <br />
    ② '회사' 는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대한 책임을 지지 않습니다.
    <br />
    <br />
    <br />
    제17조 (포인트 또는 마일리지 제도 이용)
    <br />
    ① '회사' 는 회원에게 포인트 또는 마일리지('회사' 가 회원에게 일정한 조건에 따라서 부여하는 '회사' 에 대한 채권으로서 사이버 머니처럼 사용할 수 있는 것을 말합니다. 이하 "포인트"라 합니다)를 부여할 수 있습니다.
    <br />
    ② '회사' 는 포인트 획득, 사용 방법 등에 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라야 합니다. '회사' 가 상이한 시기와 발생 원인에 따라서 회원에게 포인트를 부여하여 회원이 합산된 포인트 중 일부를 사용할 경우 포인트의 차감 순서나 포인트의 소멸시효 기간 등은 '회사' 가 결정하여 공지합니다.
    <br />
    ③ '회사' 는 포인트 제휴업체와 회원의 포인트 적립 및 사용을 위하여 필요한 최소한의 정보(아이디, 주민등록번호, 기타 회원의 식별을 위하여 필요한 정보, 물품 배송의 경우 배송에 필요한 성명, 주소, 전화번호)를 제공하거나 받을 수 있습니다.
    <br />
    ④ 부정한 방법으로 포인트를 획득한 사실이 확인될 경우 '회사' 는 회원의 포인트 회수, ID(고유번호) 삭제 및 형사 고발 등 기타 조치를 할 수 있습니다.
    <br />
    <br />
    <br />
    <br />
    <br />
    제3장 계약 당사자의 의무
    <br />
    <br />
    <br />
    제18조 ('회사' 의 의무)
    <br />
    ① '회사' 는 법령과 본 약관이 금지하거나 公序良俗 (공서양속)에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
    <br />
    ② '회사' 는 회원의 개인정보를 안전하게 보호하며 회원의 승낙 없이 제삼자에게 제공하지 않습니다. 다만, 관계법령 및 수사상의 목적으로 관계기관으로부터 요청을 받았을 경우 이는 예외로 합니다.
    <br />
    ③ '회사' 는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 구축합니다.
    <br />
    ④ '회사' 는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리 일정을 통보하여야 합니다.
    <br />
    <br />
    <br />
    제 19 조 (이용자의 의무)
    <br />
    ① 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
    <br />
    ② 회원은 본 약관에서 규정하는 사항과 기타 '회사' 가 정한 제반 규정, 공지사항 등 '회사' 가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 '회사' 의 업무에 방해가 되는 행위, '회사' 의 명예를 손상하는 행위를 해서는 안 됩니다.
    <br />
    ③ '회사' 가 관계법령 및 '개인정보 취급방침'에 따라 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
    <br />
    ④ 회원은 '회사' 의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 '회사' 는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 '회사' 가 손해를 입은 경우, 회원은 '회사' 에 대해 손해배상의무를 지며, '회사' 는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
    <br />
    ⑤ 회원은 '회사' 의 명시적 동의가 없는 한 서비스의 이용 권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
    <br />
    ⑥ 회원은 '회사'  및 제삼자의 지적 재산권을 침해해서는 안 됩니다.
    <br />
    ⑦ 회원은 다음 각호에 해당하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 '회사' 는 회원의 서비스 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.
    <br />
    1. 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위
    <br />
    2. 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위
    <br />
    3. 다른 이용자의 ID, 비밀번호, 전자메일 등을 도용하는 행위
    <br />
    4. 이용자 ID를 타인과 거래하는 행위
    <br />
    5. '회사' 의 운영진, 직원 또는 관계자를 사칭하는 행위
    <br />
    6. '회사' 로부터 특별한 권리를 부여받지 않고 '회사' 의 클라이언트 프로그램을 변경하거나, '회사' 의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
    <br />
    7. 서비스에 위해를 가하거나 고의로 방해하는 행위
    <br />
    8. 본 서비스를 통해 얻은 정보를 '회사' 의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제삼자에게 제공하는 행위
    <br />
    9. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는 게시하는 행위
    <br />
    10. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
    <br />
    11. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 쪽지 또는 기타의 방법으로 타인에게 유포하는 행위
    <br />
    12. 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위
    <br />
    13. '회사' 의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위
    <br />
    14. 범죄와 결부된다고 객관적으로 판단되는 행위
    <br />
    15. 본 약관을 포함하여 기타 '회사' 가 정한 제반 규정 또는 이용 조건을 위반하는 및 기타 대한민국 관계법령 및 국제법에 위배되는 행위 등
    <br />
    <br />
    <br />
    <br />
    <br />
    제4장 계약 해지 및 이용 제한
    <br />
    <br />
    <br />
    제20조 (계약 변경 및 해지)
    <br />
    ① 회원이 이용계약을 해지하고자 하는 때에는 사이트상 '회사' 가 정한 별도의 이용방법에 따라 해지를 하여야 합니다.
    <br />
    ② 회원이 제 19조 사항을 위반하였을 경우 '회사' 는 사전 통보 없이 즉시 이용관계를 해지하거나 회원의 서비스 이용을 중지시킬 수 있습니다.
    <br />
    ③ '회사' 는 1년 동안 서비스를 사용하지 않은 회원에 대하여 서비스에 대한 사용 여부를 묻고 이에 대한 답변이 없을 경우 '회사' 는 이용계약을 해지할 수 있습니다.
    <br />
    ④ 제2항과 3항에 조치에 대하여 이의가 있을 경우 회원은 '회사' 가 정한 이용방법에 따라 이의 신청을 할 수 있습니다. 또한 이의신청이 타당하다고 판단될 시 '회사' 는 즉시 회원의 서비스 이용을 재개합니다.
    <br />
    ⑤ '회사' 는 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 휴면 계정으로 분류하고 서비스 이용을 제한할 수 있습니다.
    <br />
    <br />
    <br />
    제21조 (서비스 이용제한)
    <br />
    ① '회사' 는 회원이 서비스 이용내용에 있어서 본 약관 제 19조 7항의 내용을 위반하거나, 다음 각호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.
    <br />
    1. 미풍양속을 저해하는 비속한 ID 및 닉네임 사용
    <br />
    2. 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우
    <br />
    3. 저작권이 있는 자료의 글을 무단 게시한 경우
    <br />
    4. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
    <br />
    5. 불법 사이트를 홍보 광고한 경우
    <br />
    6. 타인의 허락 없이 사진, 게시물, 개인정보를 유포하는 경우
    <br />
    7. 정보통신윤리위원회의 심의 세칙 제 7조에 어긋나는 음란물을 게재한 경우
    <br />
    8. 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우
    <br />
    9. 사이트 운영에 심각한 영향을 끼치는 경우
    <br />
    ② 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 불량이용자 처리 규정에 따라 취할 수 있습니다.
    <br />
    <br />
    <br />
    <br />
    <br />
    제5장 손해배상 및 기타사항
    <br />
    <br />
    <br />
    제22조 (손해배상)
    <br />
    ① 본 약관을 위반하여 '회사' 에 손해가 발생 되었을 때는 회원은 '회사' 에 발생한 모든 손해를 배상하여야 합니다.
    <br />
    ② '회사' 는 서비스에서 제공하는 서비스의 이용과 관련하여 개인정보 취급방침에서 정하는 내용에 해당하지 않는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.
    <br />
    ③ '회사' 는 회원이 '회사'  서비스를 이용함으로써 직/간접적으로 행하는 모든 피해와 법적 책임에 대하여 어떠한 손해도 책임지지 않습니다.
    <br />
    <br />
    <br />
    제23조 (면책조항)
    <br />
    ① '회사' 는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
    <br />
    ② '회사' 는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
    <br />
    ③ '회사' 는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대하여 책임을 지지 않습니다.
    <br />
    ④ '회사' 는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
    <br />
    ⑤ '회사' 는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
    <br />
    ⑥ '회사' 는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
    <br />
    ⑦ '회사' 는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 '회사' 는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
    <br />
    ⑧ '회사' 는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
    <br />
    ⑨ '회사' 는 이용자 상호 간 및 이용자와 제삼자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
    <br />
    ⑩ '회사' 에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.
    <br />
    ⑪ '회사' 는 회원의 게시판 사용으로 발생하는 어떠한 손해에도 책임을 지지 않으며 모든 책임은 게시물을 작성한 본인에게 있습니다.
    <br />
    ⑫ '회사' 는 대한민국 법에서 정한 규율을 존중하며 정보통신법과 청소년보호법을 비롯한 모든 법률을 위반하는 게시물을 올리는 것을 절대 허용하지 않으며 이에 관하여 '회사' 는 일체의 책임을 지지 않습니다.
    <br />
    ⑬ '회사' 는 회원이 서비스를 이용함으로써 회원 본인 또는 제삼자에게 직/간접적으로 영향을 미치게 되는 모든 피해와 법적 문제에 대하여 책임을 지지 않습니다.
    <br />
    <br />
    <br />
    제24조 (재판권 및 준거법)
    <br />
    ① 이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.
    <br />
    ② '회사' 와 회원 간에 분쟁이 발생한 경우 이를 원만히 해결하기 위하여서 '회사' 와 회원은 긴밀히 협의하여야 합니다.
    <br />
    ③ 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 '회사' 의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
    <br />
    <br />
    <br />
    <br />
    <br />
    부칙
    <br />
    (시행일)
    <br />
    1.  본 약관 변경공고일자 2022년 11월 30일
    <br />
    2.  본 약관 시행일자 2022년 11월 30일부터 시행합니다.
    <br />
  </div>
</div>
</GContainer>
</template>

<script setup lang="ts">
  import GContainer from '@/components/atoms/GContainer.vue';
  import { useGlobalStore } from '@/store';
  import { onMounted } from 'vue';

  const site = useGlobalStore();

  onMounted(() => {
      setTimeout(() => {
          site.setValue('loading', false);
      }, 100);
  });
</script>
