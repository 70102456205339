import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rightsection w-[212px] space-y-2" }

import { BoxBanner,BestBooks,NoticeBox } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'RightSection',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_unref(BestBooks)),
    _createVNode(_unref(BoxBanner), { adid: "1663893743" }),
    _createVNode(_unref(NoticeBox))
  ]))
}
}

})