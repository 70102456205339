<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <WideBanner adid="2064279846"></WideBanner>
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <div class="w-full py-4 dark:text-gray-200">
        <div class="flex space-x-2">
            <select class="border border-gray-300 rounded-md text-gray-500 w-1/4 dark:bg-slate-800" v-model="componentInfo.tag">
                <option>{{ t('common.selectCategory') }}</option>
                <option :value="tag.title" v-for="(tag, index) in componentInfo.items" :key="`category_${index}`">{{ tag.title }}</option>
            </select>
            <GInput class="border border-gray-300 rounded-md text-gray-500 w-3/4" v-model="componentInfo.content.title" :placeholder="t('common.requiredtitlement')" />
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="p-1">
            <div class="hidden lg:block mb-3">
                <MultiFileUpload :callback="fnMultiUploadCallback" />
            </div>
            <Editor
            :apiKey="config.editor.key"
            :key="`Editor-${componentInfo.key}`"
            :init="{
                height: 500,
                menubar: false,
                plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
                toolbar:
                'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
                images_upload_handler: uploadHandler,
                dropzone: {
                uploadMultiple: false,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                success: handleDropzoneSuccess
                },
                relative_urls: false,
                document_base_url: config.apis.article
            }"
            v-model="componentInfo.content.body"
            :onInit="handleInit"
            ></Editor>
        </div>
        </div>
    </div>
    <div class="w-full my-3 flex justify-center space-x-2" v-if="componentInfo.isLoggedIn">
        <DefaultButton class="w-[120px]" type="accept" size="lg" icon="fa-solid fa-floppy-disk" :clickEvent="onSave">SAVE</DefaultButton>
        <DefaultButton class="w-[120px]" type="cancel" size="lg" icon="fa-solid fa-ban" :clickEvent="onCancel">CANCEL</DefaultButton>
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { WideBanner,GInput,DefaultButton,MultiFileUpload } from '@/components';
import SiteData from '../../../data';
import { Content, LinkItem, ContentRepository, FileRepository, Member, ReturnValues, ContentDTO, ValidateHelper,ResourceData } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import Editor from '@tinymce/tinymce-vue'
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';


dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface containerParameter {
    class?:string|null
};

const { t, locale } = useI18n();
const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const contentId = computed(() => (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '');
const auth = new AuthService();
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var contentRep = new ContentRepository(config.apis.article, '');
var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    content: new Content(),
    origin:'',
    firstImage:'',
    item:{} as LinkItem,
    items:[] as LinkItem[],
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
    componentInfo.value.items = SiteData.tags;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new ContentRepository(config.apis.article, token);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;

        if (componentInfo.value.member.type === 'Admin') {
            componentInfo.value.items = SiteData.admintag;
        }
    }

    if (contentId.value !== null && contentId.value !== undefined && String(contentId.value).trim() !== '') {
        let rst = await contentRep.GetContent(contentId.value);
        if (rst.check && rst.data !== null && rst.data !== undefined) {
            componentInfo.value.content = rst.data;
            componentInfo.value.origin = rst.data.body;
        }
    }

    componentInfo.value.isLoaded = true;
});

const uploadHandler = (blobInfo: any): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let rst:any = await fileRep.FileUpload(blobInfo.blob());
            console.log('rst : ', rst);
            if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
                let newImages = rst.data[0].webURL;
                if (componentInfo.value.firstImage === '') {
                    componentInfo.value.firstImage = newImages;
                }
                resolve(newImages);
            } else {
                if (rst !== null && rst !== undefined) {
                    if (rst.message !== null && rst.message !== undefined) {
                        console.debug(rst.message);
                        reject(rst.message);
                    } else if (rst.errors !== null && rst.errors !== undefined && rst.errors.upload !== null && rst.errors.upload !== undefined && rst.errors.upload.length > 0) {
                        console.debug(rst.errors);
                        reject(rst.errors.upload[0]);
                    } else if (rst.title !== null && rst.title !== undefined) {
                        console.debug(rst.title);
                        reject(rst.title);
                    } else {
                        console.debug('upload fail');
                        reject('upload fail');
                    }
                } else {
                    console.debug('response is null or empty');
                    reject('response is null or empty');
                }
            }
        } catch (e:any) {
            console.debug(e.message);
            reject(e.message);
        }
    });
};

const handleDropzoneSuccess = (images: any[]) => {
    try {
        const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
        componentInfo.value.content.body += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

const handleInit = (editor: any) => {
    componentInfo.value.isLoaded = true;
};

const onSave = async () => {
    let dto = new ContentDTO();
    dto.body = componentInfo.value.content.body;
    dto.tags = componentInfo.value.tag;
    dto.thumbnail = componentInfo.value.firstImage;
    dto.title = componentInfo.value.content.title;

    if (validate.IsNullOrWhiteSpace(dto.body)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.content')}`);
    } else if (validate.IsNullOrWhiteSpace(dto.title)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.title')}`);
    } else if (validate.IsNullOrWhiteSpace(dto.tags)) {
        MessageBox.Alert(`${t('system.required')} : Tag`);
    } else {
        let rst = new ReturnValues();
        if (componentInfo.value.content.id !== null && componentInfo.value.content.id !== undefined && String(componentInfo.value.content.id).trim() !== '') {
            rst = await contentRep.ContentModify(componentInfo.value.content.id, dto);
        } else {
            rst = await contentRep.ContentRegist(dto);
        }
        
        if (rst.check) {
            MessageBox.Success(t('system.Saved'), () => {
                router.push(`/content/view/${rst.data.id}`);
            });
        } else {
            MessageBox.Alert(rst.message);
        }
    }
};

const onCancel = () => {
    if (componentInfo.value.origin !== componentInfo.value.content.body) {
        MessageBox.Confirm(`${t('common.requiredsave')} ${t('common.cancelWriting')}`, () => {
            history.back();
        });
    } else {
        history.back();
    }
};

const fnMultiUploadCallback = (newImages:ResourceData[]) => {
    try {
        const imageTags = newImages.map((image) => `<img src="${image.webURL}" alt="${image.fileName}" />`);
        componentInfo.value.content.body += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

</script>
