<template>
<div class="gcontainer" :class="componentInfo.class">
    <slot></slot>
</div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
   class:'' 
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});
</script>