import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';

interface containerParameter {
    width?:string|null,
    height?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Skeleton',
  props: {
    width: {},
    height: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    size:'',
});

onMounted(() => {
    componentInfo.value.size = '';
    if (property.width !== null && property.width !== undefined && String(property.width).trim() !== '') {
        componentInfo.value.size += `width:${property.width};`;
    }
    if (property.height !== null && property.height !== undefined && String(property.height).trim() !== '') {
        componentInfo.value.size += `height:${property.height};`;
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative overflow-hidden bg-slate-400 rounded-lg animate-pulse",
    style: _normalizeStyle(`${_unref(componentInfo).size}`)
  }, null, 4))
}
}

})