<template>
  <div v-if="isLoading" class="wrap-gray">
    <div class="loading">
      <img src="/images/loading.gif" alt="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { useGlobalStore } from '@/store';

// 글로벌 스토어 인스턴스 가져오기
const globalStore = useGlobalStore();

// 로딩 상태 관리
const isLoading = ref(true); // 기본값을 true로 설정

// 초기 로드 시 loading 키 확인 및 기본값 설정
onMounted(() => {
  const initialLoading = globalStore.getValue('loading');
  if (initialLoading === null || initialLoading === undefined) {
    globalStore.setValue('loading', true); // 기본값 설정
  } else {
    isLoading.value = initialLoading;
  }
});

// loading 키 값이 변경될 때 반응하도록 watch 설정
watch(
  () => globalStore.getValue('loading'),
  (newValue) => {
    isLoading.value = newValue ?? true; // 기본값을 true로 유지
  }
);
</script>
