import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full mt-4 h-[40px] overflow-hidden" }
const _hoisted_2 = { class: "w-50 float-left" }
const _hoisted_3 = { class: "flex space-x-0 justify-start input-group" }
const _hoisted_4 = { class: "w-50 float-right" }
const _hoisted_5 = { class: "flex justify-between space-x-2 w-full" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = { class: "w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4" }
const _hoisted_9 = { class: "w-full lg:w-2/3 pt-4" }
const _hoisted_10 = { class: "w-1/3 hidden lg:block pt-8 pr-4" }
const _hoisted_11 = { class: "w-full mt-4 pb-8 flex justify-center border-t border-slate-600 pt-4" }

import { onMounted, ref, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GInput,ArticleItem,Pagination,GButton,ArticleStatus,DefaultButton } from '@/components';
import SiteData from '../../../data';
import { LinkItem, PagingParameter,Content, ContentRepository,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';

interface containerParameter {
    class?:string|null,
    selectedContent?:Function|null|undefined,
    tag?:string|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ContentListPage',
  props: {
    class: {},
    selectedContent: { type: [Function, null] },
    tag: {}
  },
  setup(__props: any) {

;

const property = __props;
const route = useRoute();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();
var repository = null as ContentRepository|null;

var componentInfo = ref({
    key:0,
    class:'',
    accessToken:'',
    member:null as Member|null,
    isLogin:false,
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem,
    categories:[] as LinkItem[],
    totalCount:0,
    items:[] as Content[],
    paging: new PagingParameter(),
    currentId:''
});

onMounted(async () => {
    componentInfo.value.paging = { curPage : ((route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1), pageSize : 10, keyword : "" };
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : (property.tag ?? '이슈');
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;
    componentInfo.value.categories = SiteData.tags;
    componentInfo.value.currentId = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id).trim() : '';

    componentInfo.value.accessToken = await auth.getAccessToken();
    componentInfo.value.member = memberStore.member;
    if (componentInfo.value.accessToken !== null 
        && componentInfo.value.accessToken !== undefined 
        && String(componentInfo.value.accessToken).trim() !== ''
        && componentInfo.value.member !== null 
        && componentInfo.value.member !== undefined) {
        componentInfo.value.isLogin = true;

        if (componentInfo.value.member.type === 'Admin') {
            componentInfo.value.categories = SiteData.admintag;
        }
    }

    repository = new ContentRepository(config.apis.article, componentInfo.value.accessToken);

    await getTagContent(componentInfo.value.tag);

    componentInfo.value.isLoaded = true;
});

watch(
  () => [route.params.id, route.query.curpage],
  ([newId, newPage]) => {
    componentInfo.value.currentId =
      newId !== null && newId !== undefined ? String(newId).trim() : '';

    componentInfo.value.paging.curPage =
      newPage !== null && newPage !== undefined ? Number(newPage) : 1;
  },
  { immediate: true }
);

const fnChangeIssue = () => {
    router.push(`/content/list/${componentInfo.value.tag}`);
    componentInfo.value.paging.curPage = 1;
    getTagContent(componentInfo.value.tag);
    componentInfo.value.key += 1;
};

const fnContentView = (content:Content) => {
    if (property.selectedContent !== null && property.selectedContent !== undefined && typeof property.selectedContent === "function") {
        property.selectedContent(content);
    }
}

const getTagContent = async (tag:string) => {
    let rst = await repository?.GetTagContents(tag, componentInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        componentInfo.value.totalCount = rst.code;
        componentInfo.value.items = rst.data;
    }
};

const fnPaging = (curpage:number) => {
    router.push(`${location.pathname}?curpage=${curpage}`)
    componentInfo.value.paging.curPage = curpage;
    getTagContent(componentInfo.value.item.title);
    componentInfo.value.key += 1;
};

const fnSubmit = () => {
    getTagContent(componentInfo.value.item.title);
    componentInfo.value.key += 1;
};

const fnWrite = () => {
    router.push(`/content/edit`);
}

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `content_list_${_unref(componentInfo).key}`,
        class: _normalizeClass([_unref(componentInfo).class, "w-full px-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(GInput), {
                type: "text",
                placeholder: "Search",
                size: "sm",
                class: "p-2 border w-[120px] outline-none",
                modelValue: _unref(componentInfo).paging.keyword,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).paging.keyword) = $event)),
                onKeyup: _withKeys(fnSubmit, ["enter"])
              }, null, 8, ["modelValue"]),
              _createVNode(_unref(GButton), {
                onClick: fnSubmit,
                class: "py-1 px-3 ml-1 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400",
                size: "sm"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "fa-solid fa-magnifying-glass" }, null, -1)
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_unref(componentInfo).isLogin)
                ? (_openBlock(), _createBlock(_unref(DefaultButton), {
                    key: 0,
                    icon: "fa-solid fa-pen-to-square",
                    class: "w-[50%]",
                    type: "default",
                    size: "sm",
                    "click-event": fnWrite
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("글쓰기")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, " ")),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(componentInfo).tag) = $event)),
                onChange: fnChangeIssue,
                class: "mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md w-[50%]"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).categories, (tag, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: tag.title,
                    key: `option_${index}`,
                    selected: (_unref(componentInfo).tag === tag.title),
                    class: "py-1 px-2 text-gray-600 dark:text-gray-400 bg-gray-200 dark:bg-slate-800"
                  }, _toDisplayString(tag.title), 9, _hoisted_7))
                }), 128))
              ], 544), [
                [_vModelSelect, _unref(componentInfo).tag]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(), _createElementBlock("ul", {
            class: "w-full articleList",
            key: `content_ul_${_unref(componentInfo).key}`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["w-full border-b border-dashed px-2 pb-4 border-slate-600 flex justify-between space-x-1", (item.id === _unref(componentInfo).currentId) ? 'bg-teal-100 dark:bg-gray-800' : '']),
                key: `item_${index}`
              }, [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(), _createBlock(_unref(ArticleItem), {
                    content: item,
                    callback: fnContentView,
                    key: `content_item_${item.id}`
                  }, null, 8, ["content"]))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(ArticleStatus), { item: item }, null, 8, ["item"])
                ])
              ], 2))
            }), 128))
          ]))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_unref(Pagination), {
            totalCount: _unref(componentInfo).totalCount,
            curPage: _unref(componentInfo).paging.curPage,
            size: "md",
            callback: fnPaging
          }, null, 8, ["totalCount", "curPage"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})