<template>
    <div class="w-full border-b border-t border-gray-400 pb-2" v-if="componentInfo.isLoaded" :key="`topWriters_${componentInfo.key}`" :class="componentInfo.class">
        <div class="w-full bg-slate-600 p-2 mb-2 text-white">Best Uploader</div>
        <ul class="topWriters px-2">
            <li v-for="(item,index) in componentInfo.items" :key="`writer_${index}`" class="mb-2 flex justify-between space-x-2">
                <CircleImage :src="item.photo" width="40px" height="40px" class="cursor-pointer" :border="1" @click="MoveUser(item.id)" />
                <div class="topWriter cursor-pointer" @click="MoveUser(item.id)">
                    <h3 class="text-sm text-gray-500 dark:text-gray-100">{{ item.nickName }}</h3>
                    <hr class="border-t border-gray-600 border-dashed" />
                    <div class="text-gray-500 dark:text-gray-400 text-sm mt-2">
                        {{ StringHelper.truncateText(item.bio, 15) }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BookCover, CircleImage } from '../molecules';
import { useRouter } from 'vue-router';
import { Member, ContentRepository,StringHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';

interface bestBookParameter {
    class?:string|null,
    clickEvent?:Function|null|undefined
}

const property = defineProps<bestBookParameter>();
const router = useRouter();
const ContentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    class:"",
    items:[] as Member[],
    isLoaded:false,
    key:0
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    await GetBestContent();

    componentInfo.value.isLoaded = true;
});

const GetBestContent = async () => {
    let rst = await ContentRep.GetBestUsers({ curPage : 1, pageSize : 3, keyword : ''});
    if (rst.check) {
        componentInfo.value.items = rst.data;
    }
};

const MoveUser = (id:string) => {
    router.push(`/profile/${id}`);
}

</script>

<style scoped>
ul.topWriters li:last-child { margin-bottom: 0px; }
.topWriter { width:calc(100% - 40px); }
</style>