<template>
<main :key="`customer_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4 flex justify-between space-x-2">
        <div class="flex space-x-0 justify-start input-group">
            <GInput type="text" placeholder="Search" size="sm" class="p-2 border w-[120px] outline-none" v-model="componentInfo.paging.keyword" />
            <GButton @click="fnSubmit" class="py-1 px-3 ml-1 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400" size="sm"><i class="fa-solid fa-magnifying-glass"></i></GButton>
        </div>
        <div class="flex space-x-2">
            <DefaultButton icon="fa-solid fa-pen-to-square" class="" type="default" size="sm" v-if="componentInfo.isLogin && componentInfo.isEdit" @click="fnEdit">수정하기</DefaultButton>
            <DefaultButton icon="fa-solid fa-pen-to-square" class="" type="default" size="sm" v-if="componentInfo.isLogin" @click="fnWrite">문의하기</DefaultButton>
        </div>
    </div>
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <GTable :items="componentInfo.items" :click-event="fnContentView">
            <template #columns>
                <th>no</th>
                <th>Title</th>
                <th>RegistDate</th>
            </template>
            <template #items="{ item, index }">
            <td>{{ componentInfo.totalCount - index }}</td>
            <td>{{ item.title }}</td>
            <td>{{ dayjs.utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</td>
            </template>
        </GTable>
    </div>
    <div class="w-full mt-4 pb-8 flex justify-center border-t border-slate-600 pt-4">
        <Pagination :totalCount="componentInfo.totalCount" :curPage="componentInfo.paging.curPage" size="md" :callback="fnPaging" />
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GTable,GInput,Pagination,GButton,DefaultButton } from '@/components';
import { LinkItem, PagingParameter,Member } from '@gn2studio/gn2.library.common';
import { BoardContent, BoardRepository } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface optionParameter {
    class?:string|null|undefined,
    isEdit?:boolean|null|undefined,
    selectedContent?:Function|null|undefined
};

const property = defineProps<optionParameter>();
const route = useRoute();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();

var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    accessToken:'',
    member:null as Member|null,
    isLogin:false,
    isLoaded:false,
    isEdit:false,
    item:{} as LinkItem,
    totalCount:0,
    items:[] as BoardContent[],
    paging: new PagingParameter(),
    currentId:'',
    columns:[{ name:'제목', id:'title' }]
});

onMounted(async () => {
    componentInfo.value.paging = { curPage : ((route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1), pageSize : 10, keyword : "" };
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.currentId = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id).trim() : '';
    componentInfo.value.isEdit = (property.isEdit !== null && property.isEdit !== undefined) ? property.isEdit : false;

    componentInfo.value.accessToken = await auth.getAccessToken();
    componentInfo.value.member = memberStore.member;
    if (componentInfo.value.accessToken !== null 
        && componentInfo.value.accessToken !== undefined 
        && String(componentInfo.value.accessToken).trim() !== ''
        && componentInfo.value.member !== null 
        && componentInfo.value.member !== undefined) 
    {
        componentInfo.value.isLogin = true;
        boardRep = new BoardRepository(config.apis.board, componentInfo.value.accessToken, config.global.slug);
    }

    await getQuestion();

    componentInfo.value.isLoaded = true;
});

watch(
  () => [route.params.id, route.query.curpage],
  ([newId, newPage]) => {
    componentInfo.value.currentId =
      newId !== null && newId !== undefined ? String(newId).trim() : '';

    componentInfo.value.paging.curPage =
      newPage !== null && newPage !== undefined ? Number(newPage) : 1;
  },
  { immediate: true }
);

const fnContentView = (content:BoardContent) => {
    if (property.selectedContent !== null && property.selectedContent !== undefined && typeof property.selectedContent === "function") {
        property.selectedContent(content);
    }
}

const getQuestion = async () => {
    let rst = await boardRep.GetUserBoards('qna', componentInfo.value.paging);
    if (rst !== null && rst !== undefined && rst.check) {
        componentInfo.value.totalCount = rst.code;
        componentInfo.value.items = rst.data;
    }
};

const fnPaging = (curpage:number) => {
    router.push(`${location.pathname}?curpage=${curpage}`)
    componentInfo.value.paging.curPage = curpage;
    getQuestion();
    componentInfo.value.key += 1;
};

const fnSubmit = () => {
    getQuestion();
    componentInfo.value.key += 1;
};

const fnWrite = () => {
    router.push(`/customer/qna/edit`);
}

const fnEdit = () => {
    router.push(`/customer/qna/edit/${componentInfo.value.currentId}`);
}
</script>

<style scoped>
ul.articleList li:last-child { border-bottom:none; }
</style>