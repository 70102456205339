<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <div class="w-full py-4 dark:text-gray-200">
        <div class="">
            <GInput class="border border-gray-300 rounded-md text-gray-500 w-full" v-model="componentInfo.data.title" :placeholder="t('common.requiredtitlement')" />
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="p-1">
            <Editor
            :apiKey="config.editor.key"
            :key="`Editor-${componentInfo.key}`"
            :init="{
                height: 500,
                menubar: false,
                plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
                toolbar:
                'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
                images_upload_handler: uploadHandler,
                dropzone: {
                uploadMultiple: false,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                success: handleDropzoneSuccess
                },
                relative_urls: false,
                document_base_url: config.apis.article
            }"
            v-model="componentInfo.data.body"
            :onInit="handleInit"
            ></Editor>
        </div>
        </div>
    </div>
    <div class="w-full my-3 flex justify-center space-x-2" v-if="componentInfo.isLoggedIn">
        <DefaultButton class="w-[120px]" type="accept" size="lg" icon="fa-solid fa-floppy-disk" @click="onSave">SAVE</DefaultButton>
        <DefaultButton class="w-[120px]" type="cancel" size="lg" icon="fa-solid fa-ban" @click="onCancel">CANCEL</DefaultButton>
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { GInput,DefaultButton } from '@/components';
import { NotifyRepository, FileRepository, Member, ReturnValues, ValidateHelper, BoardCategory, BoardContent, BoardContentDTO } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { useMemberStore } from '@/store';
import { AuthService } from '@/services';
import Editor from '@tinymce/tinymce-vue'
import { MessageBox } from '@/utility';
import { useI18n } from 'vue-i18n';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface containerParameter {
    class?:string|null
};

const { t, locale } = useI18n();
const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var notifyRep = new NotifyRepository(config.apis.notification, '', config.global.slug);
var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    data: {
        title : '',
        body : ''
    },
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        notifyRep = new NotifyRepository(config.apis.notification, token, config.global.slug);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    } else {
        MessageBox.Alert(t('common.requireLogin'), () => {
            router.push(`/login/?backurl=${location.pathname}`);
        });

        return;
    }

    componentInfo.value.isLoaded = true;
});

const uploadHandler = (blobInfo: any): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let rst:any = await fileRep.FileUpload(blobInfo.blob());
            if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
                let newImages = rst.data[0].webURL;
                resolve(newImages);
            } else {
                if (rst !== null && rst !== undefined) {
                    if (rst.message !== null && rst.message !== undefined) {
                        console.debug(rst.message);
                        reject(rst.message);
                    } else if (rst.errors !== null && rst.errors !== undefined && rst.errors.upload !== null && rst.errors.upload !== undefined && rst.errors.upload.length > 0) {
                        console.debug(rst.errors);
                        reject(rst.errors.upload[0]);
                    } else if (rst.title !== null && rst.title !== undefined) {
                        console.debug(rst.title);
                        reject(rst.title);
                    } else {
                        console.debug('upload fail');
                        reject('upload fail');
                    }
                } else {
                    console.debug('response is null or empty');
                    reject('response is null or empty');
                }
            }
        } catch (e:any) {
            console.debug(e.message);
            reject(e.message);
        }
    });
};

const handleDropzoneSuccess = (images: any[]) => {
    try {
        const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
        componentInfo.value.data.body += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

const handleInit = (editor: any) => {
    componentInfo.value.isLoaded = true;
};

const onClear = () => {
    componentInfo.value.data.title = '';
    componentInfo.value.data.body = '';
}

const onSave = async () => {
    if (validate.IsNullOrWhiteSpace(componentInfo.value.data.body)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.content')}`);
    } else if (validate.IsNullOrWhiteSpace(componentInfo.value.data.title)) {
        MessageBox.Alert(`${t('system.required')} : ${t('common.title')}`);
    } else {
        let rst = await notifyRep.PostGithubIssue(componentInfo.value.data.title, encodeURIComponent(componentInfo.value.data.body), 'bug');
        if (rst.check) {
            MessageBox.Success('등록되었습니다.', () => {
                onClear();
            });
        } else {
            MessageBox.Alert(rst.message);
        }
    }
};

const onCancel = () => {
    document.location.reload();
};


</script>
