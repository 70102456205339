import { defineStore } from 'pinia';

export const useReadCheckStore = defineStore('readcheckstore', {
  actions: {
    /**
     * 특정 키의 배열에 값이 있는지 여부 확인
     * @param key 키 값
     * @param id 확인할 ID
     * @returns 값이 존재하면 true, 아니면 false
     */
    hasValue(key: string, id: string): boolean {
      const ids = this.getValues(key);
      return ids.includes(id);
    },

    /**
     * 특정 키의 배열에 값을 추가
     * @param key 키 값
     * @param id 추가할 ID
     */
    addValue(key: string, id: string) {
      const ids = this.getValues(key);
      if (!ids.includes(id)) {
        ids.push(id);
        this.saveValues(key, ids);
      }
    },

    /**
     * 특정 키의 배열에서 값을 삭제
     * @param key 키 값
     * @param id 삭제할 ID
     */
    removeValue(key: string, id: string) {
      const ids = this.getValues(key).filter((existingId) => existingId !== id);
      this.saveValues(key, ids);
    },

    /**
     * 특정 키의 배열 초기화
     * @param key 키 값
     */
    clearValues(key: string) {
      this.saveValues(key, []);
    },

    /**
     * 특정 키 자체 삭제
     * @param key 키 값
     */
    removeKey(key: string) {
      localStorage.removeItem(key);
    },

    /**
     * 전체 초기화 (localStorage에서 모든 데이터 제거)
     */
    clearAll() {
      localStorage.clear();
    },

    /**
     * 특정 키의 배열 가져오기
     * @param key 키 값
     * @returns 키에 해당하는 ID 배열
     */
    getValues(key: string): string[] {
      const storedData = localStorage.getItem(key);
      return storedData ? JSON.parse(storedData) : [];
    },

    /**
     * 특정 키에 배열 저장
     * @param key 키 값
     * @param values 저장할 ID 배열
     */
    saveValues(key: string, values: string[]) {
      localStorage.setItem(key, JSON.stringify(values));
    },
  },
});
