<template>
<div class="columns-2 md:columns-3 lg:columns-4 gap-4" :class="componentInfo.class">
    <div 
        v-for="(item, index) in componentInfo.items" 
        :key="index" 
        @click="fnClickEvent(item)"
        class="break-inside-avoid mb-4 bg-white rounded-lg shadow-md overflow-hidden">
        <CardImage :item="item"></CardImage>
    </div>
</div>
</template>

<script setup lang="ts">
import { Content } from '@gn2studio/gn2.library.common';
import { onMounted, ref, watch } from 'vue';
import { CardImage } from '@/components';

interface containerParameter {
    class?: string|null|undefined,
    clickitem?:Function|null|undefined,
    modelValue?: Content[], 
};

const property = defineProps<containerParameter>();

const emit = defineEmits(['update:modelValue']);

// 컴포넌트 내부 데이터
var componentInfo = ref({
    class: '',
    items: [] as Content[]
});

// v-model 데이터와 동기화
watch(
  () => property.modelValue,
  (newValue) => {
    componentInfo.value.items = newValue as Content[];
  },
  { immediate: true }
);

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});

const fnClickEvent = (item: Content) => {
    if (property.clickitem !== null && property.clickitem !== undefined && typeof property.clickitem === "function") {
        property.clickitem(item);
    }
};
</script>
