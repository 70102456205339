<template>
<div class="gtab flex justify-start space-x-1" :class="componentInfo.class" :key="`tab_${componentInfo.key}`">
    <GButton v-for="(item,index) in componentInfo.items" :key="`tab_item_${index}`" :clickEvent="() => fnClick(item)" size="xs" :class="(item.active) ? onClass : offClass">
        {{ item.title }}
    </GButton>
</div>

</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import GButton from '../atoms/GButton.vue';
    import { LinkItem } from '@gn2studio/gn2.library.common';
    import { useRouter } from 'vue-router';

    interface tabParameter {
        class?:string|null,
        items?:LinkItem[]
    };

    const property = defineProps<tabParameter>();
    const router = useRouter();
    const onClass = "active border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200";
    const offClass = "border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-400";

    var componentInfo = ref({
        key:0,
        class:'',
        items:[] as LinkItem[]
    });

    onMounted(() => {
        componentInfo.value.class = property.class ?? '';
        componentInfo.value.items = property.items ?? [];
    });

    const fnClick = (item:LinkItem) => {
        if (item.type === 'blank') {
            const a = document.createElement("a");
            a.href = item.url;
            a.target = "_blank";
            a.click();
        } else {
            if (item.url.trim() !== '') {
                router.push(item.url);
            }
        }
    };
</script>