import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "banner-box-home-top"
}
const _hoisted_2 = ["data-ad-slot"]
const _hoisted_3 = {
  key: 1,
  class: "banner-box-home-top"
}

import { onMounted, ref } from 'vue';
import config from '@/config';

interface BannerParameter {
  adid:string,
  class?:string|null,
}

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VerticalBanner',
  props: {
    adid: {},
    class: {}
  },
  setup(__props: any) {

const property = __props;

var componentInfo = ref({
    key:0,
    class:'' 
});

const initAds = () => {
  if (config.mode !== "development") {
    (window.adsbygoogle = window.adsbygoogle || []).push({ params: { google_ad_slot: `${property.adid}` }});
  }
};

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    try {
      initAds();
    } catch (e:any) {
      console.log(e.message);
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full bannerLayer rounded-lg bg-slate-100 dark:bg-slate-500", _unref(componentInfo).class]),
    key: _unref(componentInfo).key
  }, [
    (_unref(config).mode !== 'development')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("ins", {
            class: _normalizeClass(`adsbygoogle ${property.adid}`),
            style: {"display":"block"},
            "data-ad-client": "ca-pub-9127406210246186",
            "data-ad-slot": property.adid,
            "data-ad-format": "auto",
            "data-full-width-responsive": "true"
          }, null, 10, _hoisted_2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ], 2))
}
}

})