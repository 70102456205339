import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3" }
const _hoisted_2 = { class: "hidden lg:block w-1/6 mt-5" }
const _hoisted_3 = { class: "w-full lg:w-5/6" }
const _hoisted_4 = { class: "w-full mt-5 text-xl text-gray-500 dark:text-gray-200" }
const _hoisted_5 = { class: "text-gray-700 dark:text-white" }
const _hoisted_6 = { class: "w-full mt-8" }
const _hoisted_7 = { class: "text-lg font-semibold text-gray-600 dark:text-gray-200" }
const _hoisted_8 = { class: "w-full h-24 flex items-center align-middle bg-slate-200 dark:bg-slate-600 justify-center mt-1 rounded-lg p-4 lg:p-8 space-x-2" }
const _hoisted_9 = { class: "w-full mt-2 rounded-md bg-slate-200 dark:bg-slate-600 p-6" }
const _hoisted_10 = { class: "lg:grid lg:grid-cols-4 lg:space-x-2 text-center p-2 text-gray-600 dark:text-gray-200" }
const _hoisted_11 = { class: "w-full mt-8" }
const _hoisted_12 = { class: "text-lg font-semibold text-gray-600 dark:text-gray-200" }
const _hoisted_13 = { class: "w-full lg:flex lg:justify-between lg:space-x-5 mt-2" }
const _hoisted_14 = { class: "flex lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 space-x-8 justify-center mt-1" }
const _hoisted_15 = { class: "mt-1 text-gray-600 dark:text-gray-200" }
const _hoisted_16 = { class: "mt-1 text-gray-600 dark:text-gray-200" }
const _hoisted_17 = { class: "lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 mt-2" }
const _hoisted_18 = { class: "text-lg text-gray-600 dark:text-gray-200" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "text-xs mt-3 text-gray-600 dark:text-gray-400" }
const _hoisted_21 = { class: "text-xs mt-1 text-gray-600 dark:text-gray-400" }
const _hoisted_22 = { class: "lg:w-1/3 bg-gray-200 dark:bg-gray-600 rounded-lg shadow-lg p-6 mt-2" }
const _hoisted_23 = { style: {"overflow":"hidden"} }
const _hoisted_24 = { class: "flex" }
const _hoisted_25 = { class: "text-lg text-gray-600 dark:text-gray-200 mt-0.5 ml-1" }
const _hoisted_26 = { class: "text-xs mt-3 text-gray-600 dark:text-gray-400" }
const _hoisted_27 = { class: "w-full mt-8" }

import { onMounted, ref } from 'vue';
    import { useRoute,useRouter } from 'vue-router';
    import { LinkItem, NotifyRepository, Member, BoardRepository,BoardCategory } from '@gn2studio/gn2.library.common';
    import config from '@/config';
    import { HeadSet, MessageBox } from '@/utility';
    import { useHead } from '@vueuse/head';
    import { useI18n } from 'vue-i18n';
    import { GContainer,GInput,DefaultButton,NoticeBox,GButton,CustomerMenu } from '@/components';
    import { useMemberStore,useGlobalStore } from '@/store';
    import { AuthService } from '@/services';

    interface containerParameter {
        class?:string|null
    }
export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  props: {
    class: {}
  },
  setup(__props: any) {

    ;

    const { t, locale } = useI18n();
    const property = __props;
    const route = useRoute();
    const router = useRouter();
    const site = useGlobalStore();
    const auth = new AuthService();
    const memberStore = useMemberStore();

    var notifyRep = new NotifyRepository(config.apis.notification, '', config.global.slug);
    var boardRep = new BoardRepository(config.apis.board, '', config.global.slug);

    var pageInfo = ref({
        key:0,
        class:'',
        isLoggedIn:false,
        isLoaded:false,
        item:{} as LinkItem,
        accessToken:'',
        member:new Member(),
        categories:[] as BoardCategory[]
    });

    onMounted(async () => {
        pageInfo.value.class = property.class ?? '';
        pageInfo.value.isLoaded = true;

        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            pageInfo.value.accessToken = token;
            pageInfo.value.member = memberStore.member;
            notifyRep = new NotifyRepository(config.apis.notification, token, config.global.slug);
            pageInfo.value.isLoggedIn = true;
        }

        const head = new HeadSet();
        head.title = `${ t('site.customer') } - MerryTokTok`;
        head.url = `${location.origin}/customer`;
        useHead(head.CreateJson());

        await getCategory();

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const fnPopupShow = () => {
        if (pageInfo.value.isLoggedIn) {
            router.push(`/customer/bug`);
        } else {
            MessageBox.Confirm(t('common.requireLogin'), () => {
                router.push(`/login?backurl=/customer/bug`);
            });
        }
    }

    const getCategory = async () => {
        let rst = await boardRep.GetBoardCategories('faq');
        if (rst !== null && rst !== undefined && rst.check) {
            pageInfo.value.categories = rst.data;
        }
    };

    const fnMoveInquery = () => {
        router.push('/customer/qna/edit');
    }

    const fnMoveInqueryList = () => {
        router.push('/customer/qna');
    }

return (_ctx: any,_cache: any) => {
  return (_unref(pageInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(GContainer), {
        key: `content_list_${_unref(pageInfo).key}`,
        class: _normalizeClass(_unref(pageInfo).class)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(CustomerMenu), { position: 0 })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h3", null, _toDisplayString(_unref(t)('site.helpmessage')), 1),
                _createElementVNode("h3", null, [
                  _createElementVNode("b", _hoisted_5, _toDisplayString(_unref(t)('site.merrytoktok')) + " " + _toDisplayString(_unref(t)('site.customer')), 1),
                  _createTextVNode(_toDisplayString(_unref(t)('common.mentend')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, _toDisplayString(_unref(t)('common.faq')), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(GInput), {
                    placeholder: _unref(t)('common.InputSearchPlaceHolder'),
                    class: "border border-gray-200 dark:border-gray-500 rounded-lg w-5/6 shadow-md",
                    size: "lg",
                    type: "default"
                  }, null, 8, ["placeholder"]),
                  _createVNode(_unref(DefaultButton), {
                    class: "w-1/6 h-[45px]",
                    size: "sm",
                    type: "default"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('common.ButtonSearch')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageInfo).categories, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `${item.id}_${index}`
                      }, _toDisplayString(_unref(t)(`site.${item.title}`)), 1))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("h3", _hoisted_12, _toDisplayString(_unref(t)('common.InquiryTitle')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", {
                      class: "flex flex-col justify-center cursor-pointer",
                      onClick: fnMoveInquery
                    }, [
                      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-brands fa-rocketchat text-2xl rounded-full bg-gray-600 dark:bg-gray-200 w-16 h-16 flex items-center justify-center m-auto text-gray-100 dark:text-gray-800" }, null, -1)),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(t)('site.Inquiry')), 1)
                    ]),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "border-l border-gray-500 w-1 h-18" }, null, -1)),
                    _createElementVNode("div", {
                      class: "flex flex-col justify-center cursor-pointer",
                      onClick: fnMoveInqueryList
                    }, [
                      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-scroll text-2xl rounded-full bg-gray-600 dark:bg-gray-200 w-16 h-16 flex items-center justify-center m-auto text-gray-100 dark:text-gray-800" }, null, -1)),
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)('site.InquiryHistory')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("h3", _hoisted_18, _toDisplayString(_unref(t)('common.emailRequest')), 1),
                    _createElementVNode("a", {
                      class: "text-violet-400",
                      href: `mailto:${_unref(config).info.email}`,
                      target: "_blank"
                    }, _toDisplayString(_unref(config).info.email), 9, _hoisted_19),
                    _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)('site.worktime')), 1),
                    _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)('site.worktime2')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-brands fa-github text-gray-600 dark:text-white text-lg" }, null, -1)),
                        _createElementVNode("h3", _hoisted_25, _toDisplayString(_unref(t)('site.Bug')), 1)
                      ]),
                      _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(t)('site.bugment')), 1),
                      _createVNode(_unref(GButton), {
                        class: "float-right rounded-full w-16 h-16 overflow-hidden bg-white inline-block text-center",
                        onClick: fnPopupShow
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("i", { class: "fa-solid fa-bug text-slate-600 text-2xl" }, null, -1)
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_unref(NoticeBox), { class: "" })
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}
}

})