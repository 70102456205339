<template>
<div 
    class="relative overflow-hidden bg-slate-400 rounded-lg animate-pulse"
    :style="`${componentInfo.size}`">
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

interface containerParameter {
    width?:string|null,
    height?:string|null
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    size:'',
});

onMounted(() => {
    componentInfo.value.size = '';
    if (property.width !== null && property.width !== undefined && String(property.width).trim() !== '') {
        componentInfo.value.size += `width:${property.width};`;
    }
    if (property.height !== null && property.height !== undefined && String(property.height).trim() !== '') {
        componentInfo.value.size += `height:${property.height};`;
    }
});
</script>