import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 lg:gap-5 mt-4" }
const _hoisted_2 = { class: "grid grid-cols-1 lg:grid-cols-2 lg:gap-5 mt-4" }

import { onMounted, ref } from 'vue';
import { Articles,WideBanner } from '@/components';
import config from '@/config';

interface containerParameter {
    class?:string|null,
}
export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.isLoaded = true;

    console.log(config.mode);
});

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("main", {
        key: `homepage_${_unref(componentInfo).key}`,
        class: _normalizeClass(_unref(componentInfo).class)
      }, [
        (_openBlock(), _createBlock(_unref(WideBanner), {
          adid: "2064279846",
          key: `homepage_banner1_${_unref(componentInfo).key}`
        })),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(Articles), {
            tag: "이슈",
            icon: "fa-solid fa-fire"
          }),
          _createVNode(_unref(Articles), {
            tag: "경제",
            icon: "fa-solid fa-money-bill"
          }),
          _createVNode(_unref(Articles), {
            tag: "직장",
            icon: "fa-solid fa-briefcase"
          }),
          _createVNode(_unref(Articles), {
            tag: "정치",
            icon: "fa-regular fa-handshake"
          })
        ]),
        (_openBlock(), _createBlock(_unref(WideBanner), {
          class: "my-4",
          adid: "2064279846",
          key: `homepage_banner2_${_unref(componentInfo).key}`
        })),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(Articles), {
            tag: "포토",
            icon: "fa-solid fa-camera"
          }),
          _createVNode(_unref(Articles), {
            tag: "추억",
            icon: "fa-solid fa-film"
          }),
          _createVNode(_unref(Articles), {
            tag: "얼짱,몸짱",
            icon: "fa-brands fa-instagram"
          }),
          _createVNode(_unref(Articles), {
            tag: "연예인",
            icon: "fa-solid fa-star"
          })
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})