<template>
  <footer class="border-t dark:border-gray-600 bg-slate-700" :class="componentInfo.class">
    <GContainer>
        <GBetweenSide class="p-2">
            <template #left>
                <div class="text-gray-300 mt-2">©{{ componentInfo.year }} <span>MerryTokTok.com</span>. All rights reserved.</div>
            </template>
            <template #right>
                <div class="flex justify-start lg:justify-end space-x-1 mt-2">
                    <select class="mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md text-sm" v-model="componentInfo.documentUrl" @change="fnInnerUrl">
                        <option value="">문서열람</option>
                        <option value="/document/privacy">개인정보보호정책</option>
                        <option value="/document/term">이용약관</option>
                    </select>
                    <select class="mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md text-sm" v-model="componentInfo.linkUrl" @change="fnOuterUrl">
                        <option value="">Family Site</option>
                        <option value="https://www.facebook.com/merrytoktok">Facebook</option>
                    </select>
                </div>
            </template>
        </GBetweenSide>
    </GContainer>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';

interface containerParameter {
    class?:string|null
};

const property = defineProps<containerParameter>();
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});

const fnInnerUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.documentUrl)) {
        router.push(componentInfo.value.documentUrl);
    }
};

const fnOuterUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.linkUrl)) {
        window.open(componentInfo.value.linkUrl);
    }
};
</script>