<template>
<div :key="`abs_${componentInfo.key}`" class="w-full flex justify-between" :class="componentInfo.class" v-if="componentInfo.isAbsolute">
    <div class="flex justify-start text-left">
        <slot name="left"></slot>
    </div>
    <div class="flex justify-end text-right">
        <slot name="right"></slot>
    </div>
</div>
<div :key="`res_${componentInfo.key}`" class="w-full lg:flex lg:justify-between" :class="componentInfo.class" v-else>
    <div class="flex justify-start text-left">
        <slot name="left"></slot>
    </div>
    <div class="lg:flex justify-end text-right">
        <slot name="right"></slot>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

interface betweenSideParameter {
    class?:string|null,
    isAbsolute?:boolean|null|undefined
}

const property = defineProps<betweenSideParameter>();

var componentInfo = ref({
    key:0,
    class:'',
    isAbsolute:false
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    if (property.isAbsolute !== null && property.isAbsolute !== undefined) {
        componentInfo.value.isAbsolute = property.isAbsolute;
    }
});

watch(() => property,
    (newValue) => {
        componentInfo.value.isAbsolute = (newValue.isAbsolute !== null && newValue.isAbsolute !== undefined) ? newValue.isAbsolute : false;
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.key += 1;
    }
);
</script>