<template>
<main :key="`content_list_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class" class="w-full px-2">
    <div class="w-full mt-4 flex justify-between space-x-2 border-t border-slate-600 pt-4">
        <div class="w-full py-4 dark:text-gray-200">
        <div class="lg:flex lg:space-x-2">
            <h3 class="text-2xl">{{ componentInfo.content.title }}</h3>
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="flex w-full justify-between dark:text-gray-400">
            <div class="hidden lg:block text-gray-500">조회: {{ componentInfo.content.readCount }}</div>
            <div class="hidden lg:block text-gray-500">작성일시:{{ dayjs.utc(componentInfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</div>
            <div class="block lg:hidden">{{ componentInfo.content.readCount }}</div>
            <div class="block lg:hidden">{{ dayjs.utc(componentInfo.content.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</div>
        </div>
        <hr class="my-3 border-t dark:border-gray-800" />
        <div class="p-0 lg:p-3 text-gray-600 dark:text-gray-200" v-html="sanitizeHtml(componentInfo.content.content)"></div>
        </div>
    </div>
    <div class="w-full border-t border-gray-100 dark:border-gray-500 mt-3">
        <QuestionListPage class="" :selected-content="setContent" :is-edit="componentInfo.isEdit" />
    </div>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { QuestionListPage } from '@/components';
import SiteData from '../../../data';
import { LinkItem, BoardRepository, Member, BoardContent } from '@gn2studio/gn2.library.common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { useReadCheckStore,useMemberStore } from '@/store';
import { AuthService } from '@/services';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface containerParameter {
    class?:string|null,
    contentChangeEvent?:Function|null|undefined
};

const property = defineProps<containerParameter>();
const route = useRoute();
const router = useRouter();
const contentId = computed(() => String(route.params.id));
const auth = new AuthService();
const readCheck = useReadCheckStore();
const memberStore = useMemberStore();

var contentRep = new BoardRepository(config.apis.board, '', config.global.slug);
var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    isEdit:false,
    tag:'' as string,
    content: new BoardContent(),
    item:{} as LinkItem,
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    componentInfo.value.item = SiteData.tags.find((x => x.title === componentInfo.value.tag)) ?? {} as LinkItem;

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        contentRep = new BoardRepository(config.apis.board, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }

    let rst = await contentRep.GetContent(contentId.value);
    if (rst.check && rst.data !== null && rst.data !== undefined) {
        componentInfo.value.content = rst.data;
        if (componentInfo.value.content.ownerId === componentInfo.value.member?.id) {
            componentInfo.value.isEdit = true;
        }
    }

    componentInfo.value.isLoaded = true;
});


const setContent = async (content:BoardContent) => {
    let curpage = (route.query.curpage !== null && route.query.curpage !== undefined) ? Number(route.query.curpage) : 1;
    router.push(`/customer/qna/${content.id}?curpage=${curpage}`);
    componentInfo.value.content = content;

    if (property.contentChangeEvent !== null && property.contentChangeEvent !== undefined && typeof property.contentChangeEvent === "function") {
        property.contentChangeEvent(content);
    }

    componentInfo.value.key += 1;
}

const sanitizeHtml = (htmlString:string):string => {
    htmlString = htmlString.replace(/<script[\s\S]*?<\/script>/gi, "");
    htmlString = htmlString.replace(/style\s*=\s*(['"])[\s\S]*?\1/gi, "");
    return htmlString;
}

</script>
