<template>
    <GContainer :key="`qna_list_${pageinfo.key}`" v-if="pageinfo.isLoaded" :class="pageinfo.class">
    <div class="w-full flex justify-start p-6 lg:p-1 lg:space-x-8 pt-3">
        <div class="hidden lg:block w-1/6">
            <CustomerMenu :position="6"></CustomerMenu>
        </div>
        <div class="w-full lg:w-5/6">
            <WideBanner adid="2064279846" :key="`content_banner_${pageinfo.key}`"></WideBanner>
            <div class="w-full mt-4">
                <TitleBox :title="t(`common.Partnership`)" icon="fa-solid fa-bell"></TitleBox>
            </div>
            <PartnerShipPage class="" />
        </div>
    </div>
  </GContainer>
  </template>
  
    <script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { GContainer,CustomerMenu,TitleBox,WideBanner,PartnerShipPage } from '@/components';
    import SiteData from '../../../data';
    import { LinkItem } from '@gn2studio/gn2.library.common';
    import { useGlobalStore } from '@/store';
    import { useI18n } from 'vue-i18n';
    
    interface containerParameter {
        class?:string|null
    };
    
    const property = defineProps<containerParameter>();
    const route = useRoute();
    const site = useGlobalStore();
    const { t, locale } = useI18n();
    
    var pageinfo = ref({
        key:0,
        class:'',
        isLoaded:false,
        tag:'' as string,
        item:{} as LinkItem
    });
    
    onMounted(async () => {
        pageinfo.value.class = property.class ?? '';
        pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
        pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
        pageinfo.value.isLoaded = true;
    
        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });
    
    const goContent = () => {
  
    }
    </script>
    