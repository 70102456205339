import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "font-medium dark:text-gray-100" }

import { onMounted, ref, nextTick } from "vue";
    import { useGlobalStore } from '@/store';

    interface ContainerParameter {
        class?: string | null | undefined;
        title: string;
        body:string;
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GAccordion',
  props: {
    class: {},
    title: {},
    body: {}
  },
  setup(__props: any) {

    const property = __props;
    const site = useGlobalStore();

    const componentInfo = ref({
        key: 0,
        class: "",
        isOpen: false,
        title:'',
        body:''
    });

    const contentHeight = ref(0);
    const contentBody = ref<HTMLElement | null>(null);

    onMounted(() => {
        componentInfo.value.title = property.title;
        componentInfo.value.body = property.body;
        componentInfo.value.class = property.class ?? "";
        updateContentHeight();

        setTimeout(() => {
            site.setValue('loading', false);
        }, 100);
    });

    const toggle = async () => {
    componentInfo.value.isOpen = !componentInfo.value.isOpen;

    // 다음 틱에서 콘텐츠 높이를 다시 계산
    await nextTick();
        updateContentHeight();
    };

    const updateContentHeight = () => {
        if (contentBody.value) {
            contentHeight.value = componentInfo.value.isOpen
            ? contentBody.value.scrollHeight
            : 0;
        }
    };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`border border-gray-200 dark:border-slate-600 rounded-lg shadow-sm overflow-hidden ${componentInfo.value.class}`)
  }, [
    _createElementVNode("button", {
      onClick: toggle,
      class: "w-full px-4 py-2 text-left bg-gray-300 dark:bg-slate-600 dark:hover:bg-slate-500 transition flex items-center justify-between"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(componentInfo.value.title), 1),
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass([{ 'rotate-180': componentInfo.value.isOpen }, "w-5 h-5 transition-transform dark:text-gray-100"]),
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M19.5 8.25l-7.5 7.5-7.5-7.5"
        }, null, -1)
      ]), 2))
    ]),
    _createElementVNode("div", {
      ref: "content",
      style: _normalizeStyle({ maxHeight: componentInfo.value.isOpen ? `${contentHeight.value}px` : '0px' }),
      class: "overflow-hidden transition-all duration-300 ease-in-out px-4 dark:bg-gray-200"
    }, [
      _createElementVNode("div", {
        ref_key: "contentBody",
        ref: contentBody,
        class: "py-2"
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(componentInfo.value.body), 1)
        ])
      ], 512)
    ], 4)
  ], 2))
}
}

})