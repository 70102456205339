<template>
    <div class="w-full border-b border-t border-gray-400 pb-2" :key="`bestbook_${componentInfo.key}`" :class="componentInfo.class">
        <div class="w-full bg-slate-600 p-2 mb-2 text-white">추천웹소설</div>
        <ul class="bestbooks" v-if="componentInfo.isLoaded">
            <li v-for="(item,index) in componentInfo.items" :key="`book_${index}`" class="mb-2 flex justify-between space-x-2">
                <BookCover :src="item.cover" width="88px" height="125px" :border="2" :click-event="() => {}" />
                <div class="bestbook">
                    <h3 class="text-md font-bold text-gray-600 dark:text-gray-200">{{ item.title }}</h3>
                    <hr class="border-t border-gray-600 border-dashed" />
                    <div class="text-gray-400 text-xs mt-2">
                        {{ StringHelper.truncateText(item.description,30) }}
                    </div>
                </div>
            </li>
        </ul>
        <div class="mb-2 flex flex-col space-y-2 pl-3" v-else>
            <div class="mb-2 flex justify-start space-x-2">
                <Skeleton class="w-[85px] h-[125px]" />
                <div class="pt-1 flex flex-col space-y-2">
                    <Skeleton class="w-[90px] h-[24px]" />
                    <Skeleton class="w-[90px] h-[24px]" />
                    <Skeleton class="w-[90px] h-[24px]" />
                </div>
            </div>
            <div class="mb-2 flex justify-start space-x-2">
                <Skeleton class="w-[85px] h-[125px]" />
                <div class="pt-1 flex flex-col space-y-2">
                    <Skeleton class="w-[90px] h-[24px]" />
                    <Skeleton class="w-[90px] h-[24px]" />
                    <Skeleton class="w-[90px] h-[24px]" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BookCover } from '../molecules';
import { useRouter } from 'vue-router';
import { Book,ArticleRepository,StringHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { Skeleton } from '../atoms';

interface bestBookParameter {
    class?:string|null,
    clickEvent?:Function|null|undefined
}

const property = defineProps<bestBookParameter>();
const router = useRouter();
const articleRep = new ArticleRepository(config.apis.article, '');

var componentInfo = ref({
    class:"",
    items:[] as Book[],
    isLoaded:false,
    key:0
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    await getNews();

    componentInfo.value.isLoaded = true;
});

const getNews = async () => {
  let rst = await articleRep.GetBests({ curPage : 1, pageSize : 5, keyword:'' });
  if (rst.check) {
    componentInfo.value.items = rst.data;
    componentInfo.value.key += 1;
  } else {
    console.log('getNews error : ', rst.message);
  }
};
</script>

<style scoped>
ul.bestbooks li:last-child { margin-bottom: 0px; }
.bestbook { width:calc(100% - 88px); }
</style>