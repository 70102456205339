<template>
    <main :key="`homepage_${componentInfo.key}`" v-if="componentInfo.isLoaded" :class="componentInfo.class">
        <WideBanner adid="2064279846" :key="`homepage_banner1_${componentInfo.key}`"></WideBanner>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 mt-4">
            <Articles tag="이슈" icon="fa-solid fa-fire" />
            <Articles tag="경제" icon="fa-solid fa-money-bill" />
            <Articles tag="직장" icon="fa-solid fa-briefcase" />
            <Articles tag="정치" icon="fa-regular fa-handshake" />
        </div>
        <WideBanner class="my-4" adid="2064279846" :key="`homepage_banner2_${componentInfo.key}`"></WideBanner>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-5 mt-4">
            <Articles tag="포토" icon="fa-solid fa-camera" />
            <Articles tag="추억" icon="fa-solid fa-film" />
            <Articles tag="얼짱,몸짱" icon="fa-brands fa-instagram" />
            <Articles tag="연예인" icon="fa-solid fa-star" />
        </div>
    </main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Articles,WideBanner } from '@/components';
import config from '@/config';

interface containerParameter {
    class?:string|null,
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.isLoaded = true;

    console.log(config.mode);
});
</script>