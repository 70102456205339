import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from 'vue';
import { DefaultButton } from '../molecules';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    clickEvent?:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutButton',
  props: {
    class: {},
    size: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

;

const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();

var componentInfo = ref({
    class:'',
    size:'sm'
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
});

const fnClick = () => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === 'function') {
        property.clickEvent();
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DefaultButton), {
    class: _normalizeClass(_unref(componentInfo).class),
    type: "default",
    size: _unref(componentInfo).size,
    icon: "fa-solid fa-right-from-bracket",
    clickEvent: fnClick
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("span", { class: "hidden lg:block" }, "SIGN OUT", -1)
    ])),
    _: 1
  }, 8, ["class", "size"]))
}
}

})