<template>
  <header :key="`Header_${componentInfo.key}`">
    <GContainer class="flex justify-between space-x-5">
      <GThirdSide>
        <template #left>
          <div class="h-[60px] flex justify-center align-middle items-center p-1 lg:p-8 mt-1">
            <a class="block dark:hidden" href="/"><img src="/logo/logo.png" class="w-auto lg:w-[50%] h-auto" alt="MerryTokTok" /></a>
            <a class="hidden dark:block" href="/"><img src="/logo/logo_white.png" class="w-auto lg:w-[50%] h-auto" alt="MerryTokTok" /></a>
          </div>
        </template>
        <template #center>
          <div class="pt-2 hidden lg:block" :key="`Header_left_${componentInfo.key}`">
            <ul class="flex justify-start space-x-2 lg:space-x-5 mt-5 h-[50px]">
              <li><a href="/content/list" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium" :class="(componentInfo.menutab === 1) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''">{{ t('site.topics') }}</a></li>
              <li><a href="/album/list" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium" :class="(componentInfo.menutab === 2) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''">{{ t('site.explore') }}</a></li>
              <li><a href="/customer" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium" :class="(componentInfo.menutab === 3) ? 'bg-slate-100 dark:bg-slate-900 p-3 rounded-2xl' : ''">{{ t('site.customer') }}</a></li>
            </ul>
          </div>
          <div class="pt-2 block lg:hidden">
            <DefaultButton icon="fa-solid fa-bars" class="mt-2 flex justify-center text-center pl-1 pr-0" size="md" :click-event="fnSideMenu" type="default"></DefaultButton>
          </div>
        </template>
        <template #right>
          <div class="pt-5 pr-4 flex justify-end space-x-1" v-if="componentInfo.isLogin" :key="`Header_right_${componentInfo.key}`">
            <ProfileButton class="mt-1" size="sm" :member="componentInfo.member" :click-event="fnProfile"></ProfileButton>
            <LogoutButton class="mt-1" size=sm :clickEvent="fnLogout"></LogoutButton>
          </div>
          <div class="pt-5 pr-4 flex justify-end space-x-1" v-else>
            <LoginButton class="mt-1" size="sm" :clickEvent="fnLogin"></LoginButton>
            <RegistButton class="mt-1" size="sm" :clickEvent="fnRegist"></RegistButton>
          </div>
        </template>
      </GThirdSide>
    </GContainer>
    <GContainer class="" v-if="componentInfo.isShowSide">
      <div class="flex justify-center space-x-5 bg-slate-900 rounded-lg m-auto p-5 w-[70%]">
        <a href="/content/list" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium">{{ t('site.topics') }}</a>
        <a href="/album/list" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium">{{ t('site.explore') }}</a>
        <a href="/customer" class="text-gray-800 dark:text-gray-200 text-sm lg:text-xl font-medium">{{ t('site.customer') }}</a>
      </div>
    </GContainer>
  </header>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { GContainer,GThirdSide,ProfileButton,LogoutButton,RegistButton,LoginButton } from '@/components';
  import { useRouter } from 'vue-router';
  import { onMounted, ref, onUnmounted,watch } from 'vue';
  import { AuthService } from '@/services';
  import { useMemberStore } from '@/store';
  import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
  import config from '@/config';
  import { MessageBox } from '@/utility';
  import DefaultButton from '../molecules/DefaultButton.vue';
  
  const { t, locale } = useI18n();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const currentPath = ref(location.pathname);
  const validate = new ValidateHelper();

  interface headerParameter {
    menu?:number|null|undefined
  };

  const property = defineProps<headerParameter>();

  var componentInfo = ref({
    key:0,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false
  });

  onMounted(async () => {
    componentInfo.value.menutab = property.menu ?? 0;

    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
      componentInfo.value.member = memberStore.member
      if (componentInfo.value.member === null 
      || componentInfo.value.member === undefined 
      || componentInfo.value.member.id === null 
      || componentInfo.value.member.id === undefined 
      || String(componentInfo.value.member.id).trim() === '')
      {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
          componentInfo.value.member = rst.data;
          
        } else {
          await auth.refreshSilent();
        }
        memberStore.setMember(rst.data);
      }

      componentInfo.value.isLogin = true;
      componentInfo.value.key += 1;
    }

    urlChange(location.pathname);

    window.addEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', () => {
      currentPath.value = location.pathname;
    });
  });

  watch([currentPath, () => memberStore.member],
    ([newPath, newMember], [oldPath, oldMember]) => {
      if (newPath !== oldPath) {
        urlChange(newPath);
      }

      if (newMember !== oldMember) {
        componentInfo.value.member = memberStore.member;
        componentInfo.value.key += 1;
      }
    }
  );

  const fnLogin = () => {
    router.push(`/login?backurl=` + location.pathname);
  };
  
  const fnRegist = () => {
    router.push(`/regist`);
  };

  const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
      await auth.logout();
      memberStore.clearMember();
    });
  };

  const urlChange = (url:string) => {
    if (false === validate.IsNullOrWhiteSpace(url)) {
      if (url.indexOf('/') > -1) {
        if (url.split('/')[0].trim() === '') {
          url = url.split('/')[1].trim().toLowerCase();
        } else {
          url = url.split('/')[0].trim().toLowerCase();
        }
      }
    }

    switch (url) {
      case "content":
        componentInfo.value.menutab = 1;
        componentInfo.value.key += 1;
        break;
      case "album":
        componentInfo.value.menutab = 2;
        componentInfo.value.key += 1;
        break;
      case "customer":
        componentInfo.value.menutab = 3;
        componentInfo.value.key += 1;
        break;
    }
  };

  const fnSideMenu = () => {
    componentInfo.value.isShowSide = !componentInfo.value.isShowSide;
  };

  const fnProfile = () => {
    router.push(`/profile`);
  };
</script>
