import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex cursor-pointer" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src", "alt"]

import { LinkItem } from '@gn2studio/gn2.library.common';
    import { onMounted, ref, watch } from 'vue';


    // Props 정의
    interface containerParameter {
        modelValue: LinkItem | null;
        width?: string | null;
        size?: string | null;
        class?: string | null;
        itemClass?: string | null;
        items: LinkItem[];
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'GDropdown',
  props: {
    modelValue: {},
    width: {},
    size: {},
    class: {},
    itemClass: {},
    items: {}
  },
  emits: ['update:modelValue', 'change'],
  setup(__props: any, { emit: __emit }) {

    const property = __props;
    const emit = __emit;

    const componentInfo = ref({
        key: 0,
        size: '',
        width: '',
        class: '',
        ulClass: '',
        itemClass: '',
        items: [] as LinkItem[],
        selectedItem: property.modelValue ?? ({} as LinkItem), // 초기 선택값
    });

    const selectedValue = ref(property.modelValue); // 현재 선택된 값
    const isOpen = ref(false);

    // 컴포넌트 초기화
    onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.itemClass = property.itemClass ?? '';
    componentInfo.value.width = property.width ?? '';
    componentInfo.value.items = property.items ?? [];
    componentInfo.value.size = property.size ?? 'md';

    // 크기에 따른 클래스 추가
    switch (componentInfo.value.size) {
        case 'xs':
            componentInfo.value.class += ` text-xs h-[24px]`;
            componentInfo.value.ulClass += ` t-30`;
            break;
        case 'sm':
            componentInfo.value.class += ` text-sm h-[32px]`;
            componentInfo.value.ulClass += ` t-35`;
            break;
        case 'md':
            componentInfo.value.class += ` text-md h-[34px]`;
            componentInfo.value.ulClass += ` t-40`;
            break;
        case 'lg':
            componentInfo.value.class += ` text-lg h-[36px]`;
            componentInfo.value.ulClass += ` t-45`;
            break;
        case 'xl':
            componentInfo.value.class += ` text-xl h-[38px]`;
            componentInfo.value.ulClass += ` t-50`;
            break;
        case '2xl':
            componentInfo.value.class += ` text-2xl h-[40px]`;
            componentInfo.value.ulClass += ` t-55`;
            break;
    }
    });

    watch(() => property.modelValue,
        (newValue) => {
            selectedValue.value = newValue;
            componentInfo.value.selectedItem = newValue ?? ({} as LinkItem);
        }
    );

    const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
    };

    const selectItem = (item: LinkItem) => {
        componentInfo.value.selectedItem = item;
        selectedValue.value = item;
        isOpen.value = false;
        emit('update:modelValue', selectedValue.value);
        emit('change', { item, value: item.url });
    };



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown relative text-left", componentInfo.value.class]),
    key: `Dropdown_${componentInfo.value.key}`,
    style: _normalizeStyle((componentInfo.value.width !== null && componentInfo.value.width !== undefined && String(componentInfo.value.width).trim() !== '') ? `width:${componentInfo.value.width}` : ''),
    onClick: toggleDropdown
  }, [
    _createElementVNode("div", _hoisted_1, [
      (componentInfo.value.selectedItem.image !== null && componentInfo.value.selectedItem.image !== undefined && String(componentInfo.value.selectedItem.image).trim() !== '')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: componentInfo.value.selectedItem.image,
            class: "localeimage",
            alt: componentInfo.value.selectedItem.title
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(componentInfo.value.selectedItem.title ?? '선택하세요'), 1)
    ]),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(["flex flex-col w-full bg-white border rounded shadow dark:border-gray-800", componentInfo.value.ulClass]),
          style: _normalizeStyle((componentInfo.value.width !== null && componentInfo.value.width !== undefined && String(componentInfo.value.width).trim() !== '') ? `width:${componentInfo.value.width}` : '')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(componentInfo.value.items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `Dropdown_item_${index}`,
              class: _normalizeClass(["hover:bg-teal-200", componentInfo.value.itemClass]),
              style: _normalizeStyle((componentInfo.value.width !== null && componentInfo.value.width !== undefined && String(componentInfo.value.width).trim() !== '') ? `width:${componentInfo.value.width}` : '')
            }, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass([{ 'dropdown-item-selected': item.url === componentInfo.value.selectedItem.url }, "flex w-full text-left"]),
                onClick: _withModifiers(($event: any) => (selectItem(item)), ["stop"])
              }, [
                (item.image !== null && item.image !== undefined && String(item.image).trim() !== '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: item.image,
                      class: "localeimage",
                      alt: item.title
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 10, _hoisted_3)
            ], 6))
          }), 128))
        ], 6))
      : _createCommentVNode("", true)
  ], 6))
}
}

})