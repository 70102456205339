import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-gray-500 dark:text-gray-200 border-b border-gray-200 dark:border-gray-600" }
const _hoisted_2 = ["onClick"]

import { BoardContent } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';

interface tableParameter {
    items:BoardContent[],
    class?:string|null|undefined,
    clickEvent?:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'GTable',
  props: {
    items: {},
    class: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    class:'',
    items:[] as BoardContent[]
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.items = property.items ?? [];
});

const fnLink = (item:BoardContent) => {
    if (property.clickEvent !== null && property.clickEvent !== undefined && typeof property.clickEvent === "function") {
        property.clickEvent(item);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["w-full gtable", _unref(componentInfo).class]),
    key: `table_${_unref(componentInfo).key}`
  }, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", _hoisted_1, [
        _renderSlot(_ctx.$slots, "columns")
      ])
    ]),
    (_openBlock(), _createElementBlock("tbody", {
      key: `customer_table_${_unref(componentInfo).key}`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: `item_${index}`,
          onClick: ($event: any) => (fnLink(item)),
          class: "text-gray-500 dark:text-gray-200 border-b border-gray-200 dark:border-gray-600 hover:bg-green-300 hover:text-gray-700 dark:hover:bg-slate-800"
        }, [
          _renderSlot(_ctx.$slots, "items", {
            item: item,
            index: index
          })
        ], 8, _hoisted_2))
      }), 128))
    ]))
  ], 2))
}
}

})