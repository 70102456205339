import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-300 mt-2" }
const _hoisted_2 = { class: "flex justify-start lg:justify-end space-x-1 mt-2" }

import { onMounted, ref } from 'vue';
import { GContainer,GBetweenSide } from '../atoms';
import { ValidateHelper } from '@gn2studio/gn2.library.common';
import router from '@/router';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;
const validate = new ValidateHelper();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:''
});

onMounted(() => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';
});

const fnInnerUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.documentUrl)) {
        router.push(componentInfo.value.documentUrl);
    }
};

const fnOuterUrl = () => {
    if (false === validate.IsNullOrWhiteSpace(componentInfo.value.linkUrl)) {
        window.open(componentInfo.value.linkUrl);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["border-t dark:border-gray-600 bg-slate-700", _unref(componentInfo).class])
  }, [
    _createVNode(_unref(GContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(GBetweenSide), { class: "p-2" }, {
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createTextVNode("©" + _toDisplayString(_unref(componentInfo).year) + " ", 1),
              _cache[2] || (_cache[2] = _createElementVNode("span", null, "MerryTokTok.com", -1)),
              _cache[3] || (_cache[3] = _createTextVNode(". All rights reserved."))
            ])
          ]),
          right: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("select", {
                class: "mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md text-sm",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).documentUrl) = $event)),
                onChange: fnInnerUrl
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("option", { value: "" }, "문서열람", -1),
                _createElementVNode("option", { value: "/document/privacy" }, "개인정보보호정책", -1),
                _createElementVNode("option", { value: "/document/term" }, "이용약관", -1)
              ]), 544), [
                [_vModelSelect, _unref(componentInfo).documentUrl]
              ]),
              _withDirectives(_createElementVNode("select", {
                class: "mr-2 py-1 px-2 text-gray-700 dark:text-gray-400 bg-gray-200 dark:bg-slate-800 rounded-md text-sm",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(componentInfo).linkUrl) = $event)),
                onChange: fnOuterUrl
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("option", { value: "" }, "Family Site", -1),
                _createElementVNode("option", { value: "https://www.facebook.com/merrytoktok" }, "Facebook", -1)
              ]), 544), [
                [_vModelSelect, _unref(componentInfo).linkUrl]
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}
}

})