<template>
    <div class="w-full border-b border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900 sky">
        <GContainer>
            <GBetweenSide :isAbsolute="true">
                <template #left>
                    <GTab :items="componentInfo.tabitems" class="pl-2" />
                </template>
                <template #right>
                    <div class="flex space-x-2 pr-1">
                    <DarkMode />
                    <LanguageSelector />
                    </div>
                </template>
            </GBetweenSide>
        </GContainer>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { GContainer,GBetweenSide } from '../atoms';
    import { GTab,DarkMode,LanguageSelector } from '../molecules';
    import { LinkItem } from '@gn2studio/gn2.library.common';

    var componentInfo = ref({
        tabitems:[
            { url:'https://www.gn2studio.com', type:'blank', title:'GN2Studio',active:false },
            { url:'', type:'default', title:'Merrytoktok',active:true }
        ] as LinkItem[]
    });
</script>