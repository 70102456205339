import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

import { Content } from '@gn2studio/gn2.library.common';
import { onMounted, ref, watch } from 'vue';
import { CardImage } from '@/components';

interface containerParameter {
    class?: string|null|undefined,
    clickitem?:Function|null|undefined,
    modelValue?: Content[], 
}
export default /*@__PURE__*/_defineComponent({
  __name: 'PinterView',
  props: {
    class: {},
    clickitem: { type: [Function, null] },
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

;

const property = __props;

const emit = __emit;

// 컴포넌트 내부 데이터
var componentInfo = ref({
    class: '',
    items: [] as Content[]
});

// v-model 데이터와 동기화
watch(
  () => property.modelValue,
  (newValue) => {
    componentInfo.value.items = newValue as Content[];
  },
  { immediate: true }
);

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});

const fnClickEvent = (item: Content) => {
    if (property.clickitem !== null && property.clickitem !== undefined && typeof property.clickitem === "function") {
        property.clickitem(item);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["columns-2 md:columns-3 lg:columns-4 gap-4", _unref(componentInfo).class])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (fnClickEvent(item)),
        class: "break-inside-avoid mb-4 bg-white rounded-lg shadow-md overflow-hidden"
      }, [
        _createVNode(_unref(CardImage), { item: item }, null, 8, ["item"])
      ], 8, _hoisted_1))
    }), 128))
  ], 2))
}
}

})