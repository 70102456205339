<template>
  <div class="min-h-screen h-full text-gray-800 dark:text-gray-200" :key="pageinfo.key" v-if="pageinfo.isLoaded">
    <ProfileSection :member="pageinfo.member">
      <DefaultButton @click="editProfile" class="mt-2 px-4 py-1" size="lg" type="default" v-if="pageinfo.isLoggedIn && pageinfo.member.id === pageinfo.user.id">
        Edit Profile
      </DefaultButton>
    </ProfileSection>

    <div class="max-w-4xl mx-auto mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h2 class="text-xl font-semibold mb-4">Recent Posts</h2>
      <PinterView v-model="pageinfo.contents" :clickitem="fnMove"></PinterView>
    </div>
  </div>
</template>

<script setup lang="ts">
    import { Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper } from '@gn2studio/gn2.library.common';
    import { onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useMemberStore,useGlobalStore } from '@/store';
    import { AuthService } from '@/services';
    import { DefaultButton,PinterView,ProfileSection } from '@/components';
    import config from '@/config';
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    import localizedFormat from 'dayjs/plugin/localizedFormat'; 

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);

    const route = useRoute();
    const router = useRouter();
    const auth = new AuthService();
    const memberStore = useMemberStore();
    const site = useGlobalStore();
    const validate = new ValidateHelper();

    var memberRep = new MemberRepository(config.apis.member, '');
    var contentRep = new ContentRepository(config.apis.content, '');
    var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
    var pageinfo = ref({
        key:0,
        isLoggedIn:false,
        isLoaded:false,
        accessToken:'',
        id:'',
        member: new Member(),
        user: new Member(),
        contents:[] as Content[],
        files:[] as Resource[]
    });


    onMounted(async () => {
        memberStore.loadFromLocalStorage();
        let token = await auth.getAccessToken();
        if (token !== null && token !== undefined && String(token).trim() !== '') {
            pageinfo.value.accessToken = token;
            pageinfo.value.user = memberStore.member;
            pageinfo.value.isLoggedIn = true;
            memberRep = new MemberRepository(config.apis.member, token);
            contentRep = new ContentRepository(config.apis.article, token);
            fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        }

        if (route.params?.id !== null && route.params?.id !== undefined && String(route.params.id).trim() !== '') {
            pageinfo.value.id = String(route.params.id).trim();
            let rst = await memberRep.GetUserInfo(pageinfo.value.id);
            if (rst.check) {
                pageinfo.value.member = rst.data;
            } else {
                router.push(`/error/404`);
            }
        } else {
            if (pageinfo.value.isLoggedIn) {
                pageinfo.value.member = pageinfo.value.user;
            } else {
                router.push(`/error/404`);
            }
        }

        await getContent();

        setTimeout(() => {
          pageinfo.value.isLoaded = true;
          site.setValue('loading', false);
        }, 100);
    });

    const getContent = async () => {
      let rst = await contentRep.GetUserContents(pageinfo.value.member.id, { curPage : 1, pageSize : 30, keyword : '' });
      if (rst.check) {
        pageinfo.value.contents = rst.data;
      }
    };

    const editProfile = () => {
        router.push(`/profile/edit`);
    }

    const fnMove = (item:Content) => {
        router.push(`/content/view/${item.id}`);
    }
</script>
