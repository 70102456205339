import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bestbooks"
}
const _hoisted_2 = { class: "bestbook" }
const _hoisted_3 = { class: "text-md font-bold text-gray-600 dark:text-gray-200" }
const _hoisted_4 = { class: "text-gray-400 text-xs mt-2" }
const _hoisted_5 = {
  key: 1,
  class: "mb-2 flex flex-col space-y-2 pl-3"
}
const _hoisted_6 = { class: "mb-2 flex justify-start space-x-2" }
const _hoisted_7 = { class: "pt-1 flex flex-col space-y-2" }
const _hoisted_8 = { class: "mb-2 flex justify-start space-x-2" }
const _hoisted_9 = { class: "pt-1 flex flex-col space-y-2" }

import { onMounted, ref } from 'vue';
import { BookCover } from '../molecules';
import { useRouter } from 'vue-router';
import { Book,ArticleRepository,StringHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { Skeleton } from '../atoms';

interface bestBookParameter {
    class?:string|null,
    clickEvent?:Function|null|undefined
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BestBooks',
  props: {
    class: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

const property = __props;
const router = useRouter();
const articleRep = new ArticleRepository(config.apis.article, '');

var componentInfo = ref({
    class:"",
    items:[] as Book[],
    isLoaded:false,
    key:0
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    await getNews();

    componentInfo.value.isLoaded = true;
});

const getNews = async () => {
  let rst = await articleRep.GetBests({ curPage : 1, pageSize : 5, keyword:'' });
  if (rst.check) {
    componentInfo.value.items = rst.data;
    componentInfo.value.key += 1;
  } else {
    console.log('getNews error : ', rst.message);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full border-b border-t border-gray-400 pb-2", _unref(componentInfo).class]),
    key: `bestbook_${_unref(componentInfo).key}`
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full bg-slate-600 p-2 mb-2 text-white" }, "추천웹소설", -1)),
    (_unref(componentInfo).isLoaded)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `book_${index}`,
              class: "mb-2 flex justify-between space-x-2"
            }, [
              _createVNode(_unref(BookCover), {
                src: item.cover,
                width: "88px",
                height: "125px",
                border: 2,
                "click-event": () => {}
              }, null, 8, ["src"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(item.title), 1),
                _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "border-t border-gray-600 border-dashed" }, null, -1)),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(StringHelper).truncateText(item.description,30)), 1)
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(Skeleton), { class: "w-[85px] h-[125px]" }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" }),
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" }),
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_unref(Skeleton), { class: "w-[85px] h-[125px]" }),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" }),
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" }),
              _createVNode(_unref(Skeleton), { class: "w-[90px] h-[24px]" })
            ])
          ])
        ]))
  ], 2))
}
}

})