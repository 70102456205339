

<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
    <div class="text-center">
      <h1 class="text-9xl font-bold text-blue-500">{{ route.params.code || "404" }}</h1>
      <p class="mt-4 text-3xl font-semibold">{{ errorDetails.message }}</p>
      <p class="mt-2 text-lg text-gray-500 dark:text-gray-400">{{ errorDetails.description }}</p>
      <button
        @click="goHome"
        class="mt-8 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-md text-lg shadow-lg"
      >
        Back to Home
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const errorDetails = computed(() => {
  const code = String(route.params.code || "404"); // 문자열로 변환
  const errorMap: Record<string, { message: string; description: string }> = {
    "400": {
      message: "Bad Request",
      description: "The request could not be understood by the server.",
    },
    "401": {
      message: "Unauthorized",
      description: "You need to log in to access this page.",
    },
    "403": {
      message: "Forbidden",
      description: "You do not have permission to access this page.",
    },
    "404": {
      message: "Page Not Found",
      description: "The page you are looking for doesn't exist.",
    },
    "500": {
      message: "Internal Server Error",
      description: "Something went wrong on our end.",
    },
  };

  // 에러 코드가 정의되지 않은 경우 404 반환
  return errorMap[code] || errorMap["404"];
});

const goHome = () => {
  router.push("/");
};
</script>

