import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-start space-x-3" }
const _hoisted_2 = { class: "bg-gray-100 w-[50px] h-[50px] thumbnail-img" }
const _hoisted_3 = { class: "text-2xl mt-2 text-gray-100" }
const _hoisted_4 = { class: "" }

import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
    icon?:string|null|undefined,
    title:string
}
export default /*@__PURE__*/_defineComponent({
  __name: 'TitleBox',
  props: {
    class: {},
    icon: {},
    title: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
   class:'',
   title:'',
   icon:''
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.icon = property.icon ?? 'fa-solid fa-mug-hot';
    componentInfo.value.title = property.title;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-between border border-gray-300 bg-slate-600 rounded-lg p-4 text-xl", _unref(componentInfo).class])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass([_unref(componentInfo).icon, "mt-4 text-slate-600"])
        }, null, 2)
      ]),
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_unref(componentInfo).title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})