import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "leftsection w-[212px] space-y-2" }

import { BoxBanner,VerticalBanner,TopWriters } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftSection',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_unref(VerticalBanner), { adid: "8172670424" }),
    _createVNode(_unref(TopWriters)),
    _createVNode(_unref(BoxBanner), { adid: "1663893743" })
  ]))
}
}

})