<template>
<main :key="`content_list_${pageinfo.key}`" v-if="pageinfo.isLoaded" :class="pageinfo.class">
    <GContainer>
    <div class="w-full flex justify-between mt-5 p-2 lg:p-0">
        <LeftSection class="hidden lg:block" />
        <section class="w-full lg:w-[840px]">
        <ContentEditPage class="" />
        </section>
        <RightSection class="hidden lg:block" />
    </div>
    </GContainer>
</main>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { GContainer,LeftSection,RightSection,ContentEditPage } from '@/components';
import SiteData from '../../../data';
import { LinkItem } from '@gn2studio/gn2.library.common';
import { useGlobalStore } from '@/store';

interface containerParameter {
    class?:string|null
};

const property = defineProps<containerParameter>();
const route = useRoute();
const site = useGlobalStore();


var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    tag:'' as string,
    item:{} as LinkItem
});

onMounted(async () => {
    pageinfo.value.class = property.class ?? '';
    pageinfo.value.tag = (route.params.tag !== null && route.params.tag !== undefined && String(route.params.tag).trim()) ? String(route.params.tag).trim() : '이슈';
    pageinfo.value.item = SiteData.tags.find((x => x.title === pageinfo.value.tag)) ?? {} as LinkItem;
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

</script>
