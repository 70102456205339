import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }

import { Content, ContentRepository, PagingParameter } from '@gn2studio/gn2.library.common';
import { onMounted, ref, nextTick } from 'vue';
import { useGlobalStore } from '@/store';
import config from '@/config';
import router from '@/router';
import { HeadSet } from '@/utility';
import { useHead } from '@vueuse/head';
import { PinterView } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'List',
  setup(__props) {

const site = useGlobalStore();
const contentRep = new ContentRepository(config.apis.article, '');

var componentInfo = ref({
    items: [] as Content[],
    paging: new PagingParameter()
});

// Observer reference
const observer = ref<HTMLDivElement | null>(null);

// Load initial content on mount
onMounted(async () => {
    componentInfo.value.paging.pageSize = 30;

    const head = new HeadSet();
    head.title = `이슈 모아보기 - MerryTokTok`;
    head.url = `${location.origin}/album/list`;
    useHead(head.CreateJson());

    await onLoadContent();

    // Initialize IntersectionObserver
    nextTick(() => setupObserver());

    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
});

// Load content function
const onLoadContent = async () => {
    let rst = await contentRep.GetContents(componentInfo.value.paging);
    if (rst.check) {
        componentInfo.value.items = [...componentInfo.value.items, ...rst.data];
    }
};

// Load next content
const NextContent = async () => {
    site.setValue('loading', true);
    componentInfo.value.paging.curPage += 1;
    await onLoadContent();
    setTimeout(() => {
        site.setValue('loading', false);
    }, 100);
};

// Setup IntersectionObserver
const setupObserver = () => {
    if (observer.value) {
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    NextContent();
                }
            });
        });
        io.observe(observer.value);
    }
};

const fnMove = (item:Content) => {
    router.push(`/content/view/${item.id}`);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(PinterView), {
      modelValue: _unref(componentInfo).items,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(componentInfo).items) = $event)),
      clickitem: fnMove
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", {
      ref_key: "observer",
      ref: observer,
      class: "h-1"
    }, null, 512)
  ]))
}
}

})