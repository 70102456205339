<template>
<div
    class="dropdown relative text-left"
    :class="componentInfo.class"
    :key="`Dropdown_${componentInfo.key}`"
    :style="(componentInfo.width !== null && componentInfo.width !== undefined && String(componentInfo.width).trim() !== '') ? `width:${componentInfo.width}` : ''"
    @click="toggleDropdown"
>
    <!-- 선택된 값 표시 -->
    <div class="flex cursor-pointer">
        <img :src="componentInfo.selectedItem.image" class="localeimage" :alt="componentInfo.selectedItem.title" v-if="componentInfo.selectedItem.image !== null && componentInfo.selectedItem.image !== undefined && String(componentInfo.selectedItem.image).trim() !== ''" />
        <span>{{ componentInfo.selectedItem.title ?? '선택하세요' }}</span>
    </div>

    <!-- 옵션 목록 -->
    <ul
    v-if="isOpen" class="flex flex-col w-full bg-white border rounded shadow dark:border-gray-800" :class="componentInfo.ulClass"
    :style="(componentInfo.width !== null && componentInfo.width !== undefined && String(componentInfo.width).trim() !== '') ? `width:${componentInfo.width}` : ''">
    <li v-for="(item, index) in componentInfo.items" :key="`Dropdown_item_${index}`" class="hover:bg-teal-200" :class="componentInfo.itemClass"
        :style="(componentInfo.width !== null && componentInfo.width !== undefined && String(componentInfo.width).trim() !== '') ? `width:${componentInfo.width}` : ''">
        <button
        type="button"
        :class="{ 'dropdown-item-selected': item.url === componentInfo.selectedItem.url }"
        class="flex w-full text-left"
        @click.stop="selectItem(item)"
        >
        <img :src="item.image" class="localeimage" :alt="item.title" v-if="item.image !== null && item.image !== undefined && String(item.image).trim() !== ''" />
        <span>{{ item.title }}</span>
        </button>
    </li>
    </ul>
</div>
</template>

<script setup lang="ts">
    import { LinkItem } from '@gn2studio/gn2.library.common';
    import { onMounted, ref, watch } from 'vue';


    // Props 정의
    interface containerParameter {
        modelValue: LinkItem | null;
        width?: string | null;
        size?: string | null;
        class?: string | null;
        itemClass?: string | null;
        items: LinkItem[];
    }

    const property = defineProps<containerParameter>();
    const emit = defineEmits(['update:modelValue', 'change']);

    const componentInfo = ref({
        key: 0,
        size: '',
        width: '',
        class: '',
        ulClass: '',
        itemClass: '',
        items: [] as LinkItem[],
        selectedItem: property.modelValue ?? ({} as LinkItem), // 초기 선택값
    });

    const selectedValue = ref(property.modelValue); // 현재 선택된 값
    const isOpen = ref(false);

    // 컴포넌트 초기화
    onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.itemClass = property.itemClass ?? '';
    componentInfo.value.width = property.width ?? '';
    componentInfo.value.items = property.items ?? [];
    componentInfo.value.size = property.size ?? 'md';

    // 크기에 따른 클래스 추가
    switch (componentInfo.value.size) {
        case 'xs':
            componentInfo.value.class += ` text-xs h-[24px]`;
            componentInfo.value.ulClass += ` t-30`;
            break;
        case 'sm':
            componentInfo.value.class += ` text-sm h-[32px]`;
            componentInfo.value.ulClass += ` t-35`;
            break;
        case 'md':
            componentInfo.value.class += ` text-md h-[34px]`;
            componentInfo.value.ulClass += ` t-40`;
            break;
        case 'lg':
            componentInfo.value.class += ` text-lg h-[36px]`;
            componentInfo.value.ulClass += ` t-45`;
            break;
        case 'xl':
            componentInfo.value.class += ` text-xl h-[38px]`;
            componentInfo.value.ulClass += ` t-50`;
            break;
        case '2xl':
            componentInfo.value.class += ` text-2xl h-[40px]`;
            componentInfo.value.ulClass += ` t-55`;
            break;
    }
    });

    watch(() => property.modelValue,
        (newValue) => {
            selectedValue.value = newValue;
            componentInfo.value.selectedItem = newValue ?? ({} as LinkItem);
        }
    );

    const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
    };

    const selectItem = (item: LinkItem) => {
        componentInfo.value.selectedItem = item;
        selectedValue.value = item;
        isOpen.value = false;
        emit('update:modelValue', selectedValue.value);
        emit('change', { item, value: item.url });
    };


</script>

<style scoped>
.localeimage { width:16px;height:16px;border-radius: 50%;margin-right:5px; }
</style>