import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';

interface containerParameter {
    class?:string|null,
}
export default /*@__PURE__*/_defineComponent({
  __name: 'GContainer',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
   class:'' 
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gcontainer", _unref(componentInfo).class])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})