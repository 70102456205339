<template>
  <div class="multiupload" :class="componentInfo.isLoaded" :key="componentInfo.key">
    <div
      class="draglayer border-dashed border-2 border-gray-300 p-6 rounded-md cursor-pointer"
      @dragover.prevent="onDragOver"
      @drop.prevent="onDrop"
      @dragleave="onDragLeave"
      @click="openFilePicker"
      :class="{ 'dragging': isDragging }"
    >
      <p class="text-gray-500">여러 이미지를 한번에 올리시려면 여기를 클릭하시거나, 드래그 하셔서 업로드하세요.</p>
    </div>

    <div v-if="images.length > 0" class="imgGroup mt-4 grid grid-cols-12 gap-4">
      <div v-for="(image, index) in images" :key="index" class="imgItem">
        <div class="imgButton">
          <button @click="handleDelete(index)">
            <i class="fas fa-times text-red-500"></i>
          </button>
        </div>
        <img :src="image.url" :alt="image.name" class="object-cover rounded-md" style="width:45px;height: 45px;" />
      </div>
    </div>

    <button v-if="images.length > 0" type="button" class="btn border px-3 py-1 mt-4 bg-green-500 text-white" @click="handleUpload">
      All Upload
    </button>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { ReturnValues,FileRepository,Member,ResourceData } from '@gn2studio/gn2.library.common';
  import { MessageBox } from '@/utility';
  import config from '@/config';
  import { useMemberStore } from '@/store';
  import { AuthService } from '@/services';
  
  const images = ref<any[]>([]);
  const isDragging = ref(false);
  
  interface MultiFileUploadParameter {
    callback?:Function|null|undefined
  }

  const property = defineProps<MultiFileUploadParameter>();
  const auth = new AuthService();
  const memberStore = useMemberStore();

  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var componentInfo = ref({
    key:0,
    class:'',
    isLoaded:false,
    member:null as null|Member,
    accessToken:'',
    isLoggedIn:false,
  });

  onMounted(async () => {
    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
        componentInfo.value.isLoggedIn = true;
    }
  });

  const onDragOver = () => {
    isDragging.value = true;
  };
  
  const onDragLeave = () => {
    isDragging.value = false;
  };
  
  const onDrop = (event: DragEvent) => {
    isDragging.value = false;
    if (event.dataTransfer !== null) {
      const acceptedFiles = Array.from(event.dataTransfer.files).filter((file: File) => file.type.startsWith('image/'));
      addImages(acceptedFiles);
    }
  };
  
  const openFilePicker = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.multiple = true;
    fileInput.addEventListener('change', (event) => {
      if (event.target !== null && event.target instanceof HTMLInputElement) {
        const selectedFiles = Array.from((event.target as HTMLInputElement).files || []).filter((file) => file.type.startsWith('image/'));
        addImages(selectedFiles);
      }
    });
    fileInput.click();
  };
  
  const addImages = (newImages: File[]) => {
    images.value = [...images.value, ...newImages.map((file) => ({ file, url: URL.createObjectURL(file) }))];
  };
  
  const handleDelete = (index: number) => {
    images.value = images.value.filter((_, i) => i !== index);
  };
  
  const handleUpload = async () => {
    const formData = new FormData();
    images.value.forEach((image) => {
      formData.append('upload', image.file);
    });
  
    try {
      let rst: ReturnValues = await fileRep.FileUploads(formData);
      if (rst.check) {
        images.value = [];
        uploadCallback(rst.data);
      } else {
        MessageBox.Alert(rst.message);
      }
    } catch (err: any) {
      MessageBox.Alert(err.message);
    }
  };
  
  // Callback event for successful upload
  const uploadCallback = (uploadedImages: ResourceData[]) => {
    // Handle the uploaded images here
    if (property.callback !== null && property.callback !== undefined && typeof property.callback === "function") {
        property.callback(uploadedImages);
    }
  };
</script>
  
<style scoped>
.dragging {
  border-color: blue; /* Change border color when dragging */
}
</style>
